import { getAcrescimoCount } from "../../utils/formulas";
import { OrderActionTypes } from "./actionTypes";

const INIT_STATE = {
  orderActiveError: false,
  success: false,
  loading: false,
  orderActiveNum: "",
  orderActive: {},
  dataEmailExists: { emailExists: "false", cpfcnpj: "" },
  dataProspectClient: {},
  error: [],
  total_pages: 1,
  filters: {
    page: 1,
    qtd_per_page: 300000,
    filters: {
      vend_codigo: "",
      nome_cliente: "",
      dateI: "",
      dateF: "",
      fila: "",
      cli_cpfcnpj: "",
      num_pedido: "",
      nota_fisca: "",
      empresa: "",
      cod_filial: "",
    },
  },
  documentos: {
    success: false,
    loading: false,
    documentos: [],
  },
  pagamentos: {
    success: false,
    loading: false,
    pagamentos: [],
  },
  gfe: {
    success: false,
    loading: false,
    gfe: [],
  },
  boletos: {
    success: false,
    loading: false,
    boletos: [],
  },
  produtos: {
    success: false,
    loading: false,
    produtos: [],
  },
  itensTabela: [],
  newOrder: {
    success: false,
    loading: false,
    loadingPromotions: false,
    newOrder: {
      findedItems: {},
      arquitetos: [],
      addedItems: [],
      filters: {
        ocultarRt: false,
        pagtoSelected: [],
        filterPedido: {},
        parceiros: [],
        tabela: [],
      },
      discounts: [],
      frete: {},
      customer: {
        loading: false,
        searched: false,
      },
      saldo: {
        ativo: 0,
        utilizado: 0,
      },
      emissaoPedido: {
        loading: false,
      },
      cuponsDisponiveis: [],
    },
  },
};

const Order = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OrderActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case OrderActionTypes.RESET_ALL_DATA:
          return INIT_STATE;

        case OrderActionTypes.TABELA:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              newOrder: {
                ...state.newOrder.newOrder,
                filters: {
                  ...state.newOrder.newOrder.filters,
                  tabela: action.payload.data,
                },
              },
            },
          };

        case OrderActionTypes.PARCEIROS:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              newOrder: {
                ...state.newOrder.newOrder,
                filters: {
                  ...state.newOrder.newOrder.filters,
                  parceiros: action.payload.data,
                },
              },
            },
          };

        case OrderActionTypes.FILTER_PEDIDO:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              newOrder: {
                ...state.newOrder.newOrder,
                filters: {
                  ...state.newOrder.newOrder.filters,
                  filterPedido: action.payload.data,
                },
              },
            },
          };

        case OrderActionTypes.PAGTO_SELECTED:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              newOrder: {
                ...state.newOrder.newOrder,
                filters: {
                  ...state.newOrder.newOrder.filters,
                  pagtoSelected: action.payload.data,
                },
              },
            },
          };
        case OrderActionTypes.OCULTAR_RT:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              newOrder: {
                ...state.newOrder.newOrder,
                filters: {
                  ...state.newOrder.newOrder.filters,
                  ocultarRt: action.payload.data,
                },
              },
            },
          };
        case OrderActionTypes.GET_ORDER_ACTIVE:
          return {
            ...state,
            success: true,
            loading: false,
            orderActive: action.payload.data,
          };
        case OrderActionTypes.CANCEL_ORDER:
          return {
            ...state,
            success: true,
            loading: false,
          };
        case OrderActionTypes.VERIFY_EMAIL_EXISTS:
          return {
            ...state,
            success: true,
            loading: false,
            dataEmailExists: {
              emailExists: action.payload.data[0],
              cpfcnpj: action.payload.data[1],
            },
          };

        case OrderActionTypes.GET_DOCUMENTS:
          return {
            ...state,
            documentos: {
              success: true,
              loading: false,
              documentos: action.payload.data.documentos,
            },
          };
        case OrderActionTypes.GET_PAYMENT:
          return {
            ...state,
            pagamentos: {
              success: true,
              loading: false,
              pagamentos: action.payload.data.result.pagamento,
            },
          };
        case OrderActionTypes.GET_GFE:
          return {
            ...state,
            gfe: {
              success: true,
              loading: false,
              gfe: action.payload.data.arrGfe,
            },
          };
        case OrderActionTypes.GET_SLIPS:
          return {
            ...state,
            boletos: {
              success: true,
              loading: false,
              boletos: action.payload.data,
            },
          };
        case OrderActionTypes.GET_PRODUCTS:
          return {
            ...state,
            produtos: {
              success: true,
              loading: false,
              produtos: action.payload.data.pedidos,
            },
          };

        case OrderActionTypes.GET_NEW_ORDER:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                ...action.payload.data,
              },
            },
          };
          
        case OrderActionTypes.GET_FILTERS:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                filters: action.payload.data,
              },
            },
          };

        case OrderActionTypes.CHANGE_GROUP:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                categorias: action.payload.data.category,
                gruposLoading: false,
              },
            },
          };
        case OrderActionTypes.CHANGE_CATEGORY:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                capacidade: action.payload.data.capacidade,
                capacidadeLoading: false,
              },
            },
          };
        case OrderActionTypes.CHANGE_PAGTO:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                condPagto: action.payload.data,
              },
            },
          };

        case OrderActionTypes.GET_ORDER_PRODUCTS:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                orderProducts: {
                  isLoading: false,
                  ...action.payload.data,
                },
              },
            },
          };

        case OrderActionTypes.GET_PRODUCT_DETAILS:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                findedItems: {
                  ...state.newOrder.newOrder.findedItems,
                  [action.payload.data.sku]: action.payload.data.response,
                },
              },
            },
          };
        case OrderActionTypes.GET_FRETE_VALUE:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                frete: {
                  ...state.newOrder.newOrder.frete,
                  success: true,
                  loading: false,
                  entrega: action.payload.data.shippingMethods,
                  arrayShipping: action.payload.data.arrayShipping,
                },
              },
            },
          };

        case OrderActionTypes.GET_CUSTOMER:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                customer: {
                  ...state.newOrder.newOrder.customer,
                  loading: false,
                  searched: true,
                  data: action.payload.data.data[0],
                },
              },
            },
          };
        case OrderActionTypes.GET_SALDO:
          let ativo =
            action.payload.data.saldo > 0 ? action.payload.data.saldo : 0;
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                saldo: {
                  ...state.newOrder.newOrder.saldo,
                  ativo: ativo,
                },
              },
            },
          };
        case OrderActionTypes.EMITIR_PEDIDO:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                emissaoPedido: {
                  ...state.newOrder.newOrder.emissaoPedido,
                  loading: false,
                  data: action.payload.data,
                },
              },
            },
          };
        case OrderActionTypes.CART_RULE_UPDATE:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              success: true,
              loading: false,
              loadingPromotions: false,
              newOrder: {
                ...state.newOrder.newOrder,
                addedItems: action.payload.data.cartItems,
                discounts: action.payload.data.pedido.discounts,
                frete: {
                  ...state.newOrder.newOrder.frete,
                  shipping_discount: action.payload.data.pedido.shippingDiscount
                },
                subTotalDiscount: action.payload.data.pedido.sub_total_discount,
                fullDiscount: action.payload.data.pedido.full_discount
              },
            },
          };
        case OrderActionTypes.GET_ITEM_TABLE:
          return {
            ...state,
            itensTabela: {
              ...state.itensTabela,
              [action.payload.data.sku]: action.payload.data.response,
            },
          };

        case OrderActionTypes.GET_CUPONS_NOT_USED:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              newOrder: {
                ...state.newOrder.newOrder,
                cuponsDisponiveis: action.payload.data,
              },
            },
          };

        case OrderActionTypes.VERIFICAR_CUPOM:
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              newOrder: {
                ...state.newOrder.newOrder,
                cuponsDisponiveis: action.payload.data.coupons,
              },
            },
          };

        case OrderActionTypes.GET_ARQUITETOS:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                arquitetos: action.payload.data.partners,
              },
            },
          };

        case OrderActionTypes.ATUALIZA_CARRINHO:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                addedItems: action.payload.data,
                orderProducts: null,
              },
            },
          };

        case OrderActionTypes.GET_FRETE_VALUE_RESET:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                frete: {
                  success: false,
                  loading: false,
                  entrega: [],
                  arrayShipping: [],
                },
              },
            },
          };

        case OrderActionTypes.ADD_PRODUCT_BY_SKU:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                addedItems: [
                  ...state.newOrder.newOrder.addedItems,
                  action.payload.data,
                ],
              },
            },
          };

        default:
          return state;
      }

    case OrderActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case OrderActionTypes.GET_ORDER_ACTIVE:
          return {
            ...state,
            orderActiveError: true,
            loading: false,
          };
        case OrderActionTypes.GET_ORDER_PRODUCTS:
          return {
            ...state,
            newOrder: {
              success: false,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                orderProducts: {
                  isLoading: false,
                  ...action.payload.data,
                },
              },
            },
          };
        case OrderActionTypes.CANCEL_ORDER:
          return {
            ...state,
            success: false,
            loading: false,
          };
        case OrderActionTypes.VERIFY_EMAIL_EXISTS:
          return {
            ...state,
            success: true,
            loading: false,
          };

        case OrderActionTypes.EMITIR_PEDIDO:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                emissaoPedido: {
                  ...state.newOrder.newOrder.emissaoPedido,
                  loading: false,
                  data: {
                    ...state.newOrder.newOrder.emissaoPedido,
                    loading: false,
                    success: false,
                    msg: action?.payload?.error?.message
                      ? action.payload.error?.message
                      : "",
                  },
                },
              },
            },
          };
        case OrderActionTypes.GET_PAYMENT:
          return {
            ...state,
            pagamentos: {
              success: false,
              loading: false,
              pagamentos: [],
            },
          };
        case OrderActionTypes.EDITAR_PEDIDO:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                emissaoPedido: {
                  ...state.newOrder.newOrder.emissaoPedido,
                  loading: false,
                  data: {
                    ...state.newOrder.newOrder.emissaoPedido,
                    loading: false,
                    success: false,
                    msg: "Ocorreu um erro ao editar seu pedido, revise todos os campos e tente novamente.",
                  },
                },
              },
            },
          };

        case OrderActionTypes.GET_FRETE_VALUE:
          return {
            ...state,
            newOrder: {
              success: true,
              loading: false,
              newOrder: {
                ...state.newOrder.newOrder,
                frete: {
                  ...state.newOrder.newOrder.frete,
                  success: false,
                  loading: false,
                  entrega: [],
                  arrayShipping: [],
                },
              },
            },
          };

        case OrderActionTypes.CART_RULE_UPDATE:
          return {
             ...state,
            newOrder: {
              ...state.newOrder,
              loadingPromotions: false
            }
          };

        default:
          return state;
      }

    case OrderActionTypes.GET_ORDER_ACTIVE:
      return {
        ...state,
        loading: true,
        success: false,
        orderActiveNum: action.payload,
        orderActive: {},
        orderActiveError: false,
        newOrder: {
          ...state.newOrder,
          newOrder: {
            findedItems: {},
            arquitetos: [],
            addedItems: [],
            filters: {
              ocultarRt: false,
              pagtoSelected: [],
              filterPedido: {},
              parceiros: [],
              tabela: [],
            },
            frete: {},
            filters: {
              ocultarRt: false,
              pagtoSelected: [],
              filterPedido: {},
              parceiros: [],
              tabela: [],
            },
            customer: {
              loading: false,
              searched: false,
            },
            saldo: {
              ativo: 0,
              utilizado: 0,
            },
            emissaoPedido: {
              loading: false,
            },
            cuponsDisponiveis: [],
          },
        },
      };

    case OrderActionTypes.CLEAR_ORDER_ERROR:
      return {
        ...state,
        orderActiveError: false,
      };

    case OrderActionTypes.GET_DOCUMENTS:
      return {
        ...state,
        documentos: {
          success: false,
          loading: true,
          documentos: [],
        },
      };

    case OrderActionTypes.GET_PAYMENT:
      return {
        ...state,
        pagamentos: {
          success: false,
          loading: true,
          pagamentos: [],
        },
      };

    case OrderActionTypes.GET_GFE:
      return {
        ...state,
        gfe: {
          success: false,
          loading: true,
          gfe: [],
        },
      };

    case OrderActionTypes.GET_SLIPS:
      return {
        ...state,
        boletos: {
          success: false,
          loading: true,
          boletos: [],
        },
      };

    case OrderActionTypes.GET_PRODUCTS:
      return {
        ...state,
        produtos: {
          success: false,
          loading: true,
          produtos: [],
        },
      };

    case OrderActionTypes.GET_NEW_ORDER:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          success: false,
          loading: true,
        },
      };
    case OrderActionTypes.GET_FILTERS:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          success: false,
          loading: false,
        },
      };
    case OrderActionTypes.CHANGE_GROUP:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            gruposLoading: true,
          },
        },
      };
    case OrderActionTypes.CHANGE_CATEGORY:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            capacidadeLoading: true,
          },
        },
      };

    case OrderActionTypes.CHANGE_PAGTO:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          success: false,
          loading: true,
        },
      };

    case OrderActionTypes.GET_ORDER_PRODUCTS:
      return {
        ...state,
        newOrder: {
          success: false,
          loading: true,
          newOrder: {
            ...state.newOrder.newOrder,
            orderProducts: {
              success: false,
              isLoading: true,
              produtos: [],
            },
          },
        },
      };

      case OrderActionTypes.ADD_ITEM_TO_CART:
        const existingItemIndexSolar = state.newOrder.newOrder.addedItems.findIndex(
          (item) => item?.produto?.sku && item.produto.sku === action.payload.item.produto.sku 
        );
  
        let updatedAddedItems;
  
        if (existingItemIndexSolar >= 0) {
          updatedAddedItems = state.newOrder.newOrder.addedItems.map((item, index) => {
            if (index === existingItemIndexSolar) {
              const newQuantity = item.produto.qtd + action.payload.item.produto.qtd;
              const newSubTotal = newQuantity * item.produto.precoClienteAcrescimo;
              const newPrcrt = newQuantity * (item.produto.PRCRT / item.produto.qtd);
              return {
                ...item,
                ...item.produto.qtd = newQuantity,
                ...item.produto.subTotal = newSubTotal,
                ...item.produto.PRCRT = newPrcrt
              };
            }
            return item;
          });
        } else {
          updatedAddedItems = [
            ...state.newOrder.newOrder.addedItems,
            action.payload.item,
          ];
        }
        return {
          ...state,
          newOrder: {
            success: true,
            loading: false,
            newOrder: {
              ...state.newOrder.newOrder,
              addedItems: updatedAddedItems,
            },
          },
        };
  
      case OrderActionTypes.ADD_ITEMS_TO_CART:
        const newItems = action.payload.items;
        let updatedAddedItemsToCart = [...state.newOrder.newOrder.addedItems];
  
        newItems.forEach((newItem) => {
          const existingItemIndex = updatedAddedItemsToCart.findIndex(
            (item) => item.produto.sku === newItem.produto.sku
          );
  
          if (existingItemIndex >= 0) {
            const newQuantity = updatedAddedItemsToCart[existingItemIndex].produto.qtd + newItem.produto.qtd;
            const newSubTotal = updatedAddedItemsToCart[existingItemIndex].produto.subTotal + newItem.produto.subTotal;
            const newPrcrt = updatedAddedItemsToCart[existingItemIndex].produto.PRCRT + newItem.produto.PRCRT;
            updatedAddedItemsToCart[existingItemIndex].produto.qtd = newQuantity;
            updatedAddedItemsToCart[existingItemIndex].produto.subTotal = newSubTotal;
            updatedAddedItemsToCart[existingItemIndex].produto.PRCRT = newPrcrt;
          } else {
            updatedAddedItemsToCart.push(newItem);
          }
        });
  
        return {
          ...state,
          newOrder: {
            success: true,
            loading: false,
            newOrder: {
              ...state.newOrder.newOrder,
              addedItems: updatedAddedItemsToCart
            },
          },
        };

    case OrderActionTypes.ADD_ITEMS_TO_CART:
      const addedItems = [...state.newOrder.newOrder.addedItems];
      addedItems.push(...action.payload.items);
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems
          },
        },
      };

      case OrderActionTypes.ADD_ITEM_TO_CART_WITHOUT_KIT:
      const existingItem = state.newOrder.newOrder.addedItems.findIndex(
        (item) => item?.produto[0]?.sku && item.produto[0].sku === action.payload.item.produto[0].sku 
      );

      let updatedItems;

      if (existingItem >= 0) {
        updatedItems = state.newOrder.newOrder.addedItems.map((item, index) => {
          if (index === existingItem) {
            const newQuantity = item.produto[0].qtd + action.payload.item.produto[0].qtd;
            const newSubTotal = newQuantity * item.produto[0].precoClienteAcrescimo;
            const newPrcrt = newQuantity * (item.produto[0].PRCRT / item.produto[0].qtd);
            return {
              ...item,
              ...item.produto[0].qtd = newQuantity,
              ...item.produto[0].subTotal = newSubTotal,
              ...item.produto[0].PRCRT = newPrcrt
            };
          }
          return item;
        });
      } else {
        updatedItems = [
          ...state.newOrder.newOrder.addedItems,
          action.payload.item,
        ];
      }
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: updatedItems,
          },
        },
      };

    case OrderActionTypes.REMOVE_ITEM_TO_CART:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: [],
          },
        },
      };

    case OrderActionTypes.EDIT_QTD_KITS:
      var editProductQtd = state.newOrder.newOrder.addedItems;

      editProductQtd.forEach(product => {
        const precoCliente = product.produto?.precoClienteAcrescimo
          ? product.produto.precoClienteAcrescimo
          : product.produto.precoCliente;
        const qtd = Number(action.payload.qtd);
        const subTotal = precoCliente * qtd;
        if (product.produto.nome == action.payload.name) {
          product.produto.qtd = qtd;
          product.produto.qtdEstoque = action.payload.stock;
          product.produto.subTotal = subTotal;
          product.produto.PRCRT = subTotal;
        } else {
          product.produto;
        }
      });
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: editProductQtd,
          },
        },
      };

    case OrderActionTypes.EDIT_QTD_WITHOUT_KIT:
      var editProductQtd = state.newOrder.newOrder.addedItems;

      editProductQtd.forEach(product => {
        const precoCliente = product.produto[0]?.precoClienteAcrescimo
          ? product.produto[0].precoClienteAcrescimo
          : product.produto[0].precoCliente;
        const qtd = Number(action.payload.qtd);
        const subTotal = precoCliente * qtd;
        if (product.produto[0].nome == action.payload.name) {
          product.produto[0].qtd = qtd;
          product.produto[0].qtdEstoque = action.payload.stock;
          product.produto[0].subTotal = subTotal;
          product.produto[0].PRCRT = subTotal;
        } else {
          product.produto[0];
        }
      });

      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: editProductQtd,
          },
        },
      };

    case OrderActionTypes.EDIT_PRECOCLIENTE_KITS:
      var editPrecoCliente = state.newOrder.newOrder.addedItems;

      editPrecoCliente.forEach(product => {
        const precoCliente = product.produto.precoCliente;
        const qtd = product.produto?.qtd ? product.produto.qtd : 1;
        if (product.produto.nome == action.payload.name) {
          var resultAcrescimoPrecoCliente = getAcrescimoCount(
            action.payload.acrescimo,
            precoCliente
          );

          const subTotal = resultAcrescimoPrecoCliente * qtd;

          product.produto.precoClienteAcrescimo =
            resultAcrescimoPrecoCliente;
          product.produto.subTotal = subTotal;
          product.produto.PRCRT = subTotal;
        } else {
          product.produto;
        }
      });

      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: editPrecoCliente,
          },
        },
      };

    case OrderActionTypes.EDIT_PRECOCLIENTE_WITHOUT_KIT:
      var editPrecoCliente = state.newOrder.newOrder.addedItems;

      editPrecoCliente.forEach(product => {
        const precoCliente = product.produto[0].precoCliente;
        const qtd = product.produto[0]?.qtd ? product.produto[0].qtd : 1;
        if (product.produto[0].nome == action.payload.name) {
          var resultAcrescimoPrecoCliente = getAcrescimoCount(
            action.payload.acrescimo,
            precoCliente
          );

          const subTotal = resultAcrescimoPrecoCliente * qtd;

          product.produto[0].precoClienteAcrescimo =
            resultAcrescimoPrecoCliente;
          product.produto[0].subTotal = subTotal;
          product.produto[0].PRCRT = subTotal;
        } else {
          product.produto[0];
        }
      });

      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: editPrecoCliente,
          },
        },
      };

    case OrderActionTypes.ZERAR_CARRINHO:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: [],
            orderProducts: null,
          },
        },
      };

    case OrderActionTypes.CLEAR_CUSTOMER:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            customer: {},
          },
        },
      };

    case OrderActionTypes.REMOVE_ITEM_FROM_CART:
      const newAddedItems = state.newOrder.newOrder.addedItems.filter(item => item !== action.payload);

      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: newAddedItems,
          },
        },
      };

    case OrderActionTypes.REMOVE_ITEM_FROM_CART_WITHOUT_KIT:
      const itemsState = state.newOrder.newOrder.addedItems;
      const items = [...itemsState];

      const indexItems = items.findIndex(
        element => element.produto[0].nome == action.payload.produto.nome
      );

      if (indexItems > -1) {
        items.splice(indexItems, 1);
      }

      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: items,
          },
        },
      };

    case OrderActionTypes.REMOVE_ITEM_FROM_CART_KITS:
      const addedItemsState = state.newOrder.newOrder.addedItems;
      const item = [...addedItemsState];

      const indexItem = item.findIndex(
        element => element.produto.nome == action.payload.produto.nome
      );

      if (indexItem > -1) {
        item.splice(indexItem, 1);
      }


      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: item,
          },
        },
      };

    case OrderActionTypes.ADD_ENDERECO_VALIDO:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            frete: {
              ...state.newOrder.newOrder.frete,
              address: action.payload,
            },
          },
        },
      };

    case OrderActionTypes.GET_CUSTOMER:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            customer: {
              ...state.newOrder.newOrder.customer,
              loading: true,
              searched: false,
              data: null,
            },
          },
        },
      };

    case OrderActionTypes.EMITIR_PEDIDO:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            emissaoPedido: {
              ...state.newOrder.newOrder.customer.emissaoPedido,
              loading: true,
            },
          },
        },
      };

    case OrderActionTypes.VERIFY_EMAIL_EXISTS:
      return {
        ...state,
        success: true,
        loading: true,
        dataEmailExists: {
          emailExists: "false",
          cpfcnpj: "",
        },
      };

    case OrderActionTypes.EDITAR_PEDIDO:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            emissaoPedido: {
              ...state.newOrder.newOrder.customer.emissaoPedido,
              loading: true,
            },
          },
        },
      };

    case OrderActionTypes.CANCEL_ORDER:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case OrderActionTypes.ATUALIZA_CARRINHO:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          newOrder: {
            ...state.newOrder.newOrder,
            orderProducts: null,
          },
        },
      };

    case OrderActionTypes.CLEAR_CUSTOMER:
      return INIT_STATE;

    case OrderActionTypes.REMOVE_ORDER_ACTIVE:
      return INIT_STATE;

    case OrderActionTypes.GET_FRETE_VALUE:
      return {
        ...state,
        newOrder: {
          success: true,
          loading: false,
          newOrder: {
            ...state.newOrder.newOrder,
            frete: {
              ...state.newOrder.newOrder.frete,
              success: false,
              loading: true,
            },
          },
        },
      };

    case OrderActionTypes.CART_RULE_UPDATE:
    case OrderActionTypes.GET_COUPON_BY_CODE:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          loadingPromotions: true
        }
      };

    case OrderActionTypes.REMOVE_PROMOTIONS_AND_COUPONS:
      const addedItemsCopy = state.newOrder.newOrder.addedItems;
      addedItemsCopy.forEach(addedItem => {
        addedItem.promotionDiscount = 0
      });

      const opcoesEntrega = state.newOrder.newOrder?.frete?.entrega;
      opcoesEntrega?.map(item => {
        item.promotionDiscount = 0;
      });

      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          newOrder: {
            ...state.newOrder.newOrder,
            addedItems: addedItemsCopy,
            discounts: [],
            frete: {
              ...state.newOrder.newOrder.frete,
              entrega: opcoesEntrega,
              shipping_discount: 0
            },
            subTotalDiscount: 0,
            fullDiscount: 0
          },
        },
      };

    default:
      return state;
  }
};

export default Order;
