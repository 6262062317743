import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  Input,
  Collapse,
  Form,
  FormFeedback,
  InputGroupText,
  InputGroup,
  CardDeck,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/themes/material_blue.css";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import { toast } from "react-toastify";
import TelephoneRegister from "./TelephoneRegister";
//validation
import { useFormik } from "formik";
import * as Yup from "yup";
import { mask, onlyAcceptNumber, maskTelOrCell } from "../../../utils/otherUtils";
import InputMask from "react-input-mask";
// redux
import { useDispatch, useSelector } from "react-redux";

// actions
import {
  getCustomerDetails,
  saveCustomerDetails,
  getAddressByZipcode,
  resetCustomers,
} from "../../../store/actions";

// components
import Breadcrumb from "../../../components/Breadcrumb";
import Loader from "../../../components/Loader";

const Index = () => {
  const { customerId, action } = useParams();

  const editabled = action == "editar" ? true : false;

  const dispatch = useDispatch();

  const {
    customerDetails,
    getCustomerDetailsLoading,
    saveCustomerDetailsLoading,
    isDetailsSaved,
    addressesByZipCode,
    isAddressFetched,
  } = useSelector(state => ({
    customerDetails: state.Customers.customerDetails,
    getCustomerDetailsLoading: state.Customers.getCustomerDetailsLoading,
    saveCustomerDetailsLoading: state.Customers.saveCustomerDetailsLoading,
    isDetailsSaved: state.Customers.isDetailsSaved,
    addressesByZipCode: state.Customers.addressesByZipCode,
    isAddressFetched: state.Customers.isAddressFetched,
  }));

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerDetails(customerId));
    }
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customerId && isDetailsSaved) {
      dispatch(getCustomerDetails(customerId));
    }
  }, [dispatch, customerId, isDetailsSaved]);

  const [billingAddress, setBillingAddress] = useState({
    zipCode: "",
    state: "",
    city: "",
    address: "",
    number: "",
    complement: "",
  });

  const [deliveryAddress, setDeliveryAddress] = useState({
    zipCode: "",
    state: "",
    city: "",
    address: "",
    number: "",
    complement: "",
  });

  const [contactData, setContactData] = useState({
    EMAIL: "",
    CONTATO: "",
    CARGO: "",
    DDD: "",
    TEL: "",
    DDD_CEL: "",
    CELULAR: "",
  });

  const [creditAnalysis, setCreditAnalysis] = useState({
    Agencia: "",
    Conta: "",
    EmpTrab: "",
    CnpjTrab: "",
    TelRefBanc: "",
    DtAdmiss: "",
    CapSoc: "",
    FatMen: "",
    FuncEmp: "",
    Socio: "",
    CpfRef: "",
  });

  const [dateAORF, setDateAORF] = useState({
    FuncEmp: "",
    DtAdmiss: "",
  });

  const [cDetails, setCDetails] = useState({});
  useEffect(() => {
    if (customerDetails) {
      setCDetails(customerDetails);

      setContactData({
        EMAIL: customerDetails.EMAIL,
        CONTATO: customerDetails.CONTATO,
        CARGO: customerDetails.CARGO,
        DDD: customerDetails.DDD,
        TEL: customerDetails.TEL,
        DDD_CEL: customerDetails.DDD_CEL,
        CELULAR: customerDetails.CELULAR,
        DtNasc: customerDetails.DtNasc,
      });

      setBillingAddress({
        zipCode: customerDetails.CEP,
        state: customerDetails.UF,
        city: customerDetails.MUNICIPIO,
        address: customerDetails.ENDERECO,
        district: customerDetails.BAIRRO,
        number: customerDetails.NumEndCob,
        complement: customerDetails.COMPL,
      });

      setCreditAnalysis({
        Agencia: customerDetails.Agencia,
        Conta: customerDetails.Conta,
        EmpTrab: customerDetails.EmpTrab,
        CnpjTrab: customerDetails.CnpjTrab,
        TelRefBanc: customerDetails.TelRefBanc,
        DtAdmiss: customerDetails.DtAdmiss,
        CapSoc: customerDetails.CapSoc,
        FatMen: customerDetails.FatMen,
        FuncEmp: customerDetails.FuncEmp,
        Socio: customerDetails.Socio,
        CpfRef: customerDetails.CpfRef,
      });
      setDateAORF({
        FuncEmp: customerDetails.FuncEmp,
        DtAdmiss: customerDetails.DtAdmiss,
      });

      setDeliveryAddress({
        zipCode: customerDetails.CEP_ENT,
        state: customerDetails.UF_ENT,
        city: customerDetails.MunicipEnt,
        address: customerDetails.END_ENT,
        district: customerDetails.BAI_ENT,
        number: customerDetails.NumEndEnt,
        complement: customerDetails.ComplemEnt,
      });
    }
  }, [customerDetails]);

  useEffect(() => {}, []);

  let cpfcnpjJF = mask(cDetails.CPFCNPJ);
  const titleFisicaJuridica = cpfcnpjJF?.length <= 14 ? "Física" : "Jurídica";

  const [isOpen, setIsOpen] = useState(true);
  const toggle = selected => {
    setIsOpen(!selected);
  };

  const onChangeCEP = value => {
    dispatch(getAddressByZipcode(value));
  };

  useEffect(() => {
    if (isAddressFetched) {
      if (addressesByZipCode[deliveryAddress.zipCode]) {
        const address = addressesByZipCode[deliveryAddress.zipCode].address;
        setDeliveryAddress({
          ...deliveryAddress,
          state: address.state,
          city: address.city,
          district: address.district,
          address: address.address,
        });
      }

      if (addressesByZipCode[billingAddress.zipCode]) {
        const address = addressesByZipCode[billingAddress.zipCode].address;
        setBillingAddress({
          ...billingAddress,
          state: address.state,
          city: address.city,
          district: address.district,
          address: address.address,
        });
      }

      setTimeout(() => {
        dispatch(resetCustomers("isAddressFetched", false));
      }, 500);
    }
  }, [
    dispatch,
    cDetails,
    isAddressFetched,
    addressesByZipCode,
    billingAddress.zipCode,
    deliveryAddress.zipCode,
  ]);

  let validationObjJ = {
    EMAIL: Yup.string().required("Campo E-mail é obrigatório."),
    CONTATO: Yup.string().required("Campo Contato é obrigatório."),
    CARGO: Yup.string().required("Campo Cargo é obrigatório."),
    DDD: Yup.string().required("Campo DDD é obrigatório."),
    TEL: Yup.string().required("Campo Telefone é obrigatório."),
    DDD_CEL: Yup.string().required("Campo DDD é obrigatório."),
    CELULAR: Yup.string().required("Campo Celular é obrigatório."),
    CEP_ENT: Yup.string().required("Campo obrigatório."),
    UF_ENT: Yup.string().required("Campo obrigatório."),
    MunicipEnt: Yup.string().required("Campo obrigatório."),
    BAI_ENT: Yup.string().required("Campo obrigatório."),
    END_ENT: Yup.string().required("Campo obrigatório."),
    NumEndEnt: Yup.string().required("Campo obrigatório."),
    CapSoc: Yup.string().max(10, "O campo deve ter no máximo 8 caracteres."),
    Inscr: Yup.string().required("Campo obrigatório"),
    FatMen: Yup.string().max(10, "O campo deve ter no máximo 8 caracteres."),
  };
  let validationObjF = {
    N_COMPLETO: Yup.string().required("Campo obrigatório."),
    N_REDZ: Yup.string().required("Campo obrigatório."),
    EMAIL: Yup.string().required("Campo obrigatório."),
    CONTATO: Yup.string().required("Campo obrigatório."),
    CARGO: Yup.string().required("Campo obrigatório."),
    DDD: Yup.string().required("Campo obrigatório."),
    TEL: Yup.string().required("Campo obrigatório."),
    DDD_CEL: Yup.string().required("Campo obrigatório."),
    CELULAR: Yup.string().required("Campo obrigatório."),
    CEP_ENT: Yup.string().required("Campo obrigatório."),
    UF_ENT: Yup.string().required("Campo obrigatório."),
    MunicipEnt: Yup.string().required("Campo obrigatório."),
    BAI_ENT: Yup.string().required("Campo obrigatório."),
    END_ENT: Yup.string().required("Campo obrigatório."),
    NumEndEnt: Yup.string().required("Campo obrigatório."),
    Rg: Yup.string().required("Campo obrigatório"),
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      CPFCNPJ: cDetails.CPFCNPJ?.trim() || "",
      TP_PESSOA: cDetails.TP_PESSOA?.trim() || "",
      Contrib: cDetails.Contrib?.trim() || "",
      N_COMPLETO: cDetails.N_COMPLETO?.trim() || "",
      N_REDZ: cDetails.N_REDZ?.trim() || "",
      EMAIL: contactData.EMAIL?.trim() || "",
      CONTATO: contactData.CONTATO?.trim() || "",
      CARGO: contactData.CARGO?.trim() || "",
      DDD: contactData.DDD?.trim() || "",
      TEL: contactData.TEL?.trim() || "",
      DDD_CEL: contactData.DDD_CEL?.trim() || "",
      CELULAR: contactData.CELULAR?.trim() || "",
      CEP: billingAddress.zipCode?.trim() || "",
      UF: billingAddress.state?.trim() || "",
      MUNICIPIO: billingAddress.city?.trim() || "",
      BAIRRO: billingAddress.district?.trim() || "",
      ENDERECO: billingAddress.address?.trim() || "",
      NumEndCob: billingAddress.number?.trim() || "",
      COMPL: billingAddress.complement?.trim() || "",
      CEP_ENT: deliveryAddress.zipCode?.trim() || "",
      UF_ENT: deliveryAddress.state?.trim() || "",
      MunicipEnt: deliveryAddress.city?.trim() || "",
      BAI_ENT: deliveryAddress.district?.trim() || "",
      END_ENT: deliveryAddress.address?.trim() || "",
      NumEndEnt: deliveryAddress.number?.trim() || "",
      ComplemEnt: deliveryAddress.complement?.trim() || "",
      Agencia: cDetails.Agencia?.trim() || "",
      Conta: cDetails.Conta?.trim() || "",
      EmpTrab: cDetails.EmpTrab?.trim() || "",
      CnpjTrab: cDetails.CnpjTrab?.trim() || "",
      TelRefBanc: cDetails.TelRefBanc?.trim() || "",
      DtAdmiss: cDetails.DtAdmiss?.trim() || "",
      CapSoc: cDetails.CapSoc?.trim() || "",
      FatMen: cDetails.FatMen?.trim() || "",
      FuncEmp: cDetails.FuncEmp?.trim() || "",
      Socio: cDetails.Socio?.trim() || "",
      CpfRef: cDetails.CpfRef?.trim() || "",
      Rg: cDetails.Rg?.trim() || "",
      Inscr: cDetails.Inscr?.trim() || "",
      BANCO: cDetails.BANCO?.trim() || "",
      Tel2: cDetails.Tel2?.trim() || "",
    },
    validationSchema: () =>
      Yup.lazy(() => {
        return cDetails.TP_PESSOA === "J"
          ? Yup.object({ ...validationObjJ })
          : Yup.object({ ...validationObjF });
      }),
    onSubmit: values => {
      const params = {
        "client[cpfcnpj]": values.CPFCNPJ,
        "client[customer_type]": values.TP_PESSOA,
        "client[contribution]": values.Contrib,
        "client[social_number]":
          cDetails.TP_PESSOA === "J" ? values.Inscr : values.Rg,
        "client[fullname]": values.N_REDZ,
        "client[name_reduced]": values.N_REDZ,
        "client[email]": values.EMAIL,
        "client[contact]": values.CONTATO,
        "client[job_title]": values.CARGO,
        "client[dt_nasc]": contactData.DtNasc,
        "telephone[0][ddd]": values.DDD,
        "telephone[0][telephone]": values.TEL,
        "telephone[1][ddd]": values.DDD_CEL,
        "telephone[1][telephone]": values.CELULAR,
        "client_address[0][zipcode]": billingAddress.zipCode,
        "client_address[0][state]": billingAddress.state,
        "client_address[0][city]": billingAddress.city,
        "client_address[0][district]": billingAddress.district,
        "client_address[0][address]": billingAddress.address,
        "client_address[0][number]": billingAddress.number,
        "client_address[0][complement]": billingAddress.complement,
        "client_address[1][zipcode]": !isOpen
          ? billingAddress.zipCode
          : deliveryAddress.zipCode,
        "client_address[1][state]": !isOpen
          ? billingAddress.state
          : deliveryAddress.state,
        "client_address[1][city]": !isOpen
          ? billingAddress.city
          : deliveryAddress.city,
        "client_address[1][district]": !isOpen
          ? billingAddress.district
          : deliveryAddress.district,
        "client_address[1][address]": !isOpen
          ? billingAddress.address
          : deliveryAddress.address,
        "client_address[1][number]": !isOpen
          ? billingAddress.number
          : deliveryAddress.number,
        "client_address[1][complement]": !isOpen
          ? billingAddress.complement
          : deliveryAddress.complement,
        "customer_ficha[AGENCIA]": creditAnalysis.Agencia.trim(),
        "customer_ficha[CONTA]": creditAnalysis.Conta.trim(),
        "customer_ficha[EMPTRAB]": creditAnalysis.EmpTrab,
        "customer_ficha[CNPJTRAB]": creditAnalysis.CnpjTrab,
        "customer_ficha[TELREFBANC]": creditAnalysis.TelRefBanc,
        "customer_ficha[DTADMISS]": dateAORF.DtAdmiss,
        "customer_ficha[CAPSOC]": values.CapSoc,
        "customer_ficha[FATMEN]": creditAnalysis.FatMen,
        "customer_ficha[FUNCEMP]": dateAORF.FuncEmp,
        "customer_ficha[SOCIO]": creditAnalysis.Socio,
        "customer_ficha[CPFREF]": creditAnalysis.CpfRef,
      };

      dispatch(saveCustomerDetails(customerId, params));
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row>
            <Card>
              <CardBody>
                <div>
                  <i className="fa fa-book me-2 mb-3"></i>
                  Dados do Cliente
                </div>
                {/* Render Breadcrumb */}
                <Breadcrumb
                  title="Clientes"
                  breadcrumbItem={editabled ? "Editar " : "Ver"}
                />

                <Form
                  noValidate
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div
                    className={`${
                      !editabled ? "bg-secondary bg-opacity-10 border " : ""
                    } shadow-lg custom-border rounded-4 card-body position-relative`}
                  >
                    {(getCustomerDetailsLoading ||
                      saveCustomerDetailsLoading) && <Loader />}
                    <div className="mb-3">
                      <div className="mb-4">
                        <h5>
                          <i className="mdi mdi-menu h3 align-middle me-2"></i>
                          {editabled ? "Editando " : ""} Cliente{" "}
                          <strong
                            className="px-2 py-1 bg-info bg-opacity-25 rounded"
                            style={{ color: "#005696" }}
                          >
                            {cDetails?.CPFCNPJ ? mask(cDetails.CPFCNPJ) : "-"}
                          </strong>
                        </h5>
                        <hr />
                      </div>

                      <Row className="px-2">
                        <Col md={5}>
                          <div className="mb-3">
                            <Label>CPF/CNPJ</Label>
                            <Input
                              type="text"
                              className="form-control"
                              value={
                                cDetails?.CPFCNPJ ? mask(cDetails?.CPFCNPJ) : ""
                              }
                              readOnly
                              disabled
                            />
                          </div>
                        </Col>
                        <Col md={5} className="offset-1">
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              Tipo de Pessoa
                            </Label>
                            <div>
                              <label
                                className="visually-hidden"
                                htmlFor="autoSizingSelect"
                              >
                                Preference
                              </label>
                              <select
                                value={cDetails.TP_PESSOA}
                                className="form-select text-muted"
                                style={{
                                  cursor: "not-allowed",
                                  background: "#eee",
                                }}
                                readOnly
                                disabled
                              >
                                <optgroup label="CNPJ">
                                  <option value="J">Jurídica</option>
                                </optgroup>
                                <optgroup label="CPF">
                                  <option value="F">Física</option>
                                </optgroup>
                              </select>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row className="px-2">
                        <Col md={5}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              Inscrição Estadual
                            </Label>
                            <div>
                              <select
                                value={validation.values.Contrib}
                                name="Contrib"
                                id="Contrib"
                                readOnly
                                disabled
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                style={{
                                  cursor: "not-allowed",
                                  background: "#eee",
                                }}
                                className={classnames(
                                  "form-select text-muted",
                                  {
                                    "is-invalid":
                                      validation.touched.Contrib &&
                                      validation.errors.Contrib,
                                  }
                                )}
                              >
                                <option value="2">Isento</option>
                                <option value="1">Estadual</option>
                              </select>
                              {validation.touched.Contrib &&
                              validation.errors.Contrib ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Contrib}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                        <Col md={5} className="offset-1">
                          <div className="mb-3">
                            {cDetails.TP_PESSOA === "J" ? (
                              <>
                                <Label>Inscrição Estadual</Label>
                                <Input
                                  type="text"
                                  value={validation.values.Inscr}
                                  name="Inscr"
                                  id="Inscr"
                                  readOnly
                                  disabled
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.Inscr &&
                                      validation.errors.Inscr,
                                  })}
                                />
                                {validation.touched.Inscr &&
                                validation.errors.Inscr ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Inscr}
                                  </FormFeedback>
                                ) : null}
                              </>
                            ) : (
                              <>
                                <Label>RG</Label>
                                <Input
                                  type="text"
                                  value={validation.values.Rg}
                                  name="Rg"
                                  id="Rg"
                                  readOnly
                                  disabled
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.Rg &&
                                      validation.errors.Rg,
                                  })}
                                />
                                {validation.touched.Rg &&
                                validation.errors.Rg ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Rg}
                                  </FormFeedback>
                                ) : null}
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />

                    <div>
                      <div className="mb-4">
                        <h5 style={{ color: "#325a91" }}>Dados do Cliente</h5>
                      </div>

                      <Row className="px-2">
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Razão social</Label>
                            <Input
                              type="text"
                              value={validation.values.N_COMPLETO}
                              name="N_COMPLETO"
                              id="N_COMPLETO"
                              readOnly
                              disabled
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.N_COMPLETO &&
                                  validation.errors.N_COMPLETO,
                              })}
                            />
                            {validation.touched.N_COMPLETO &&
                            validation.errors.N_COMPLETO ? (
                              <FormFeedback type="invalid">
                                {validation.errors.N_COMPLETO}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Nome fantasia</Label>
                            <Input
                              type="text"
                              value={validation.values.N_REDZ || ""}
                              name="N_REDZ"
                              id="N_REDZ"
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              readOnly
                              disabled
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.N_REDZ &&
                                  validation.errors.N_REDZ,
                              })}
                            />
                            {validation.touched.N_REDZ &&
                            validation.errors.N_REDZ ? (
                              <FormFeedback type="invalid">
                                {validation.errors.N_REDZ}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row className="px-2">
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>E-mail:</Label>
                            <Input
                              type="text"
                              value={contactData.EMAIL || ""}
                              name="EMAIL"
                              id="EMAIL"
                              readOnly={!editabled}
                              disabled={!editabled}
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                validation.setFieldValue(
                                  "EMAIL",
                                  e.target.value
                                );
                                setContactData({
                                  ...contactData,
                                  EMAIL: e.target.value,
                                });
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.EMAIL &&
                                  validation.errors.EMAIL,
                              })}
                            />
                            {validation.touched.EMAIL &&
                            validation.errors.EMAIL ? (
                              <FormFeedback type="invalid">
                                {validation.errors.EMAIL}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Contato</Label>
                            <Input
                              type="text"
                              value={contactData.CONTATO || ""}
                              name="CONTATO"
                              id="CONTATO"
                              readOnly={!editabled}
                              disabled={!editabled}
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                validation.setFieldValue(
                                  "CONTATO",
                                  e.target.value
                                );
                                setContactData({
                                  ...contactData,
                                  CONTATO: e.target.value,
                                });
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.CONTATO &&
                                  validation.errors.CONTATO,
                              })}
                            />
                            {validation.touched.CONTATO &&
                            validation.errors.CONTATO ? (
                              <FormFeedback type="invalid">
                                {validation.errors.CONTATO}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Cargo</Label>
                            <Input
                              type="text"
                              value={contactData.CARGO || ""}
                              name="CARGO"
                              id="CARGO"
                              readOnly={!editabled}
                              disabled={!editabled}
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                validation.setFieldValue(
                                  "CARGO",
                                  e.target.value
                                );
                                setContactData({
                                  ...contactData,
                                  CARGO: e.target.value,
                                });
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.CARGO &&
                                  validation.errors.CARGO,
                              })}
                            />
                            {validation.touched.CARGO &&
                            validation.errors.CARGO ? (
                              <FormFeedback type="invalid">
                                {validation.errors.CARGO}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Row className="px-2">
                      <Col md={6}>
                        <div>
                          <label>Telefone Comercial (FIXO)</label>
                          <Row>
                            <Col md={2}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  name="DDD"
                                  id="DDD"
                                  maxLength={2}
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  value={contactData.DDD || ""}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "DDD",
                                      e.target.value
                                    );
                                    setContactData({
                                      ...contactData,
                                      DDD: e.target.value,
                                    });
                                  }}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.DDD &&
                                      validation.errors.DDD,
                                  })}
                                />
                                {validation.touched.DDD &&
                                validation.errors.DDD ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.DDD}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={8}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  name="TEL"
                                  id="TEL"
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  value={maskTelOrCell(validation.values.TEL) || ""}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
      
                                    validation.setFieldValue(
                                      "TEL",
                                      maskTelOrCell(e.target.value)
                                  )}}
                                  maxLength={10}
                                  minLength={9}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.TEL &&
                                      validation.errors.TEL,
                                  })}
                                />
                                {validation.touched.TEL &&
                                validation.errors.TEL ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.TEL}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div>
                          <label>Telefone Celular</label>
                          <Row>
                            <Col md={2}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  value={validation.values.DDD_CEL || ""}
                                  name="DDD_CEL"
                                  id="DDD_CEL"
                                  maxLength={2}
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "DDD_CEL",
                                      e.target.value
                                    );
                                    setContactData({
                                      ...contactData,
                                      DDD_CEL: e.target.value,
                                    });
                                  }}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.DDD_CEL &&
                                      validation.errors.DDD_CEL,
                                  })}
                                />
                                {validation.touched.DDD_CEL &&
                                validation.errors.DDD_CEL ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.DDD_CEL}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={8}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  value={contactData.CELULAR || ""}
                                  name="CELULAR"
                                  id="CELULAR"
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "CELULAR",
                                      e.target.value
                                    );
                                    setContactData({
                                      ...contactData,
                                      CELULAR: e.target.value,
                                    });
                                  }}
                                  tag={InputMask}
                                  mask="99999-9999"
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.CELULAR &&
                                      validation.errors.CELULAR,
                                  })}
                                />
                                {validation.touched.CELULAR &&
                                validation.errors.CELULAR ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.CELULAR}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {cpfcnpjJF?.length <= 14 && (
                      <Row>
                        <Col md={6}>
                          <Label>Data de Nascimento</Label>
                          <InputGroup>
                            <InputGroupText className="bg-dark-blue text-white border-0">
                              <i className="fa fa-calendar-days h5 p-0 m-0 text-white"></i>
                            </InputGroupText>
                            <Flatpickr
                              placeholder="dd/mm/aaaa"
                              options={{
                                altInput: true,
                                locale: Portuguese,
                                altFormat: "d F, Y",
                                dateFormat: "Ymd",
                              }}
                              name="DtAdmiss"
                              id="DtAdmiss"
                              value={contactData.DtNasc || ""}
                              onBlur={validation.handleBlur}
                              readOnly={!editabled}
                              disabled={!editabled}
                              onChange={([date]) =>
                                setContactData({
                                  ...contactData,
                                  DtNasc: date
                                    ? `${date.getFullYear()}${(
                                        date.getMonth() + 1
                                      )
                                        .toString()
                                        .padStart(2, "0")}${date
                                        .getDate()
                                        .toString()
                                        .padStart(2, "0")}`
                                    : "",
                                })
                              }
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.DtNasc &&
                                  validation.errors.DtNasc,
                              })}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    )}
                    <hr />
                    <div className="mb-3">
                      <div className="mb-4">
                        <h5 style={{ color: "#325a91" }}>
                          Endereço Faturamento & Cobrança
                        </h5>
                      </div>

                      <Row className="px-2">
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label>CEP</Label>
                            <Input
                              type="text"
                              value={validation.values.CEP || ""}
                              name="CEP"
                              id="CEP"
                              readOnly
                              disabled
                              onBlur={e => {
                                onChangeCEP(e.target.value);
                              }}
                              onChange={e => {
                                validation.setFieldValue("CEP", e.target.value);
                                setBillingAddress({
                                  ...billingAddress,
                                  zipCode: e.target.value,
                                });
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.CEP &&
                                  validation.errors.CEP,
                              })}
                            />
                            {validation.touched.CEP && validation.errors.CEP ? (
                              <FormFeedback type="invalid">
                                {validation.errors.CEP}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              Estado
                            </Label>
                            <div>
                              <label
                                className="visually-hidden"
                                htmlFor="autoSizingSelect"
                              >
                                Estado
                              </label>
                              <select
                                value={validation.values.UF || ""}
                                name="UF"
                                id="UF"
                                readOnly
                                disabled
                                style={{
                                  cursor: "not-allowed",
                                  background: "#eee",
                                }}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "UF",
                                    e.target.value
                                  );
                                  setBillingAddress({
                                    ...billingAddress,
                                    state: e.target.value,
                                  });
                                }}
                                className={classnames(
                                  "form-select text-muted",
                                  {
                                    "is-invalid":
                                      validation.touched.UF &&
                                      validation.errors.UF,
                                  }
                                )}
                              >
                                <option value="">Selecione Estado</option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AM">Amazonas</option>
                                <option value="AP">Amapá</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espirito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="PR">Paraná</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SE">Sergipe</option>
                                <option value="SP">São Paulo</option>
                                <option value="TO">Tocantins</option>
                              </select>

                              {validation.touched.UF && validation.errors.UF ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.UF}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label>Cidade</Label>
                            <Input
                              type="text"
                              value={validation.values.MUNICIPIO || ""}
                              name="MUNICIPIO"
                              id="MUNICIPIO"
                              readOnly
                              disabled
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                validation.setFieldValue(
                                  "MUNICIPIO",
                                  e.target.value
                                );
                                setBillingAddress({
                                  ...billingAddress,
                                  city: e.target.value,
                                });
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.MUNICIPIO &&
                                  validation.errors.MUNICIPIO,
                              })}
                            />
                            {validation.touched.MUNICIPIO &&
                            validation.errors.MUNICIPIO ? (
                              <FormFeedback type="invalid">
                                {validation.errors.MUNICIPIO}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label>Bairro</Label>
                            <Input
                              type="text"
                              value={validation.values.BAIRRO || ""}
                              name="BAIRRO"
                              id="BAIRRO"
                              readOnly
                              disabled
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.BAIRRO &&
                                  validation.errors.BAIRRO,
                              })}
                            />
                            {validation.touched.N_COMPLETO &&
                            validation.errors.N_COMPLETO ? (
                              <FormFeedback type="invalid">
                                {validation.errors.N_COMPLETO}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row className="px-2">
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Rua</Label>
                            <Input
                              type="text"
                              value={validation.values.ENDERECO || ""}
                              name="ENDERECO"
                              id="ENDERECO"
                              readOnly
                              disabled
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                validation.setFieldValue(
                                  "ENDERECO",
                                  e.target.value
                                );
                                setBillingAddress({
                                  ...billingAddress,
                                  address: e.target.value,
                                });
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.ENDERECO &&
                                  validation.errors.ENDERECO,
                              })}
                            />
                            {validation.touched.ENDERECO &&
                            validation.errors.ENDERECO ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ENDERECO}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>Número</Label>
                            <Input
                              type="text"
                              value={validation.values.NumEndCob.trim() || ""}
                              name="NumEndCob"
                              id="NumEndCob"
                              readOnly
                              disabled
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                setBillingAddress({
                                  ...billingAddress,
                                  number: e.target.value,
                                });
                                isOpen
                                  ? setDeliveryAddress({
                                      number: e.target.value,
                                    })
                                  : "";
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.NumEndCob &&
                                  validation.errors.NumEndCob,
                              })}
                            />
                            {validation.touched.NumEndCob &&
                            validation.errors.NumEndCob ? (
                              <FormFeedback type="invalid">
                                {validation.errors.NumEndCob}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label>Complemento</Label>
                            <Input
                              type="text"
                              value={validation.values.COMPL || ""}
                              name="COMPL"
                              id="COMPL"
                              readOnly
                              disabled
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                validation.setFieldValue(
                                  "COMPL",
                                  e.target.value
                                );
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.COMPL &&
                                  validation.errors.COMPL,
                              })}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-3">
                      <div className="mb-4">
                        <h5 style={{ color: "#325a91" }}>Endereço Entrega</h5>
                        {editabled ? (
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="autoSizingCheck"
                              onChange={e => {
                                toggle(e.target.checked);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="autoSizingCheck"
                            >
                              Usar o mesmo endereço para entrega
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <Collapse isOpen={isOpen}>
                        <Row className="px-2">
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>CEP</Label>
                              <Input
                                type="text"
                                value={
                                  isOpen
                                    ? validation.values.CEP_ENT
                                    : validation.values.CEP
                                }
                                name="CEP_ENT"
                                id="CEP_ENT"
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={e => {
                                  onChangeCEP(e.target.value);
                                }}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "CEP_ENT",
                                    e.target.value
                                  );
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    zipCode: e.target.value,
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.CEP_ENT &&
                                    validation.errors.CEP_ENT,
                                })}
                              />
                              {validation.touched.CEP_ENT &&
                              validation.errors.CEP_ENT ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.CEP_ENT}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-password-Input">
                                Estado
                              </Label>
                              <div>
                                <label
                                  className="visually-hidden"
                                  htmlFor="autoSizingSelect"
                                >
                                  Preference
                                </label>
                                <select
                                  value={validation.values.UF_ENT || ""}
                                  name="UF_ENT"
                                  id="UF_ENT"
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  style={{
                                    background: !editabled ? "#eee" : "",
                                  }}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "UF_ENT",
                                      e.target.value
                                    );
                                    setDeliveryAddress({
                                      ...deliveryAddress,
                                      state: e.target.value,
                                    });
                                  }}
                                  className={classnames(
                                    "form-select text-muted",
                                    {
                                      "is-invalid":
                                        validation.touched.UF_ENT &&
                                        validation.errors.UF_ENT,
                                    }
                                  )}
                                >
                                  <option value="">Selecione Estado</option>
                                  <option value="AC">Acre</option>
                                  <option value="AL">Alagoas</option>
                                  <option value="AM">Amazonas</option>
                                  <option value="AP">Amapá</option>
                                  <option value="BA">Bahia</option>
                                  <option value="CE">Ceará</option>
                                  <option value="DF">Distrito Federal</option>
                                  <option value="ES">Espirito Santo</option>
                                  <option value="GO">Goiás</option>
                                  <option value="MA">Maranhão</option>
                                  <option value="MG">Minas Gerais</option>
                                  <option value="MS">Mato Grosso do Sul</option>
                                  <option value="MT">Mato Grosso</option>
                                  <option value="PA">Pará</option>
                                  <option value="PB">Paraíba</option>
                                  <option value="PE">Pernambuco</option>
                                  <option value="PI">Piauí</option>
                                  <option value="PR">Paraná</option>
                                  <option value="RJ">Rio de Janeiro</option>
                                  <option value="RN">
                                    Rio Grande do Norte
                                  </option>
                                  <option value="RO">Rondônia</option>
                                  <option value="RR">Roraima</option>
                                  <option value="RS">Rio Grande do Sul</option>
                                  <option value="SC">Santa Catarina</option>
                                  <option value="SE">Sergipe</option>
                                  <option value="SP">São Paulo</option>
                                  <option value="TO">Tocantins</option>
                                </select>
                                {validation.touched.UF_ENT &&
                                validation.errors.UF_ENT ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.UF_ENT}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Cidade</Label>
                              <Input
                                type="text"
                                value={
                                  isOpen
                                    ? deliveryAddress.city
                                    : billingAddress.city
                                }
                                name="MunicipEnt"
                                id="MunicipEnt"
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "MunicipEnt",
                                    e.target.value
                                  );
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    city: e.target.value,
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.MunicipEnt &&
                                    validation.errors.MunicipEnt,
                                })}
                              />
                              {validation.touched.MunicipEnt &&
                              validation.errors.MunicipEnt ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.MunicipEnt}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Bairro</Label>
                              <Input
                                type="text"
                                value={validation.values.BAI_ENT}
                                name="BAI_ENT"
                                id="BAI_ENT"
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "BAI_ENT",
                                    e.target.value
                                  );
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    district: e.target.value,
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.BAI_ENT &&
                                    validation.errors.BAI_ENT,
                                })}
                              />
                              {validation.touched.BAI_ENT &&
                              validation.errors.BAI_ENT ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.BAI_ENT}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row className="px-2">
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>Rua</Label>
                              <Input
                                type="text"
                                value={
                                  isOpen
                                    ? validation.values.END_ENT
                                    : validation.values.ENDERECO
                                }
                                name="END_ENT"
                                id="END_ENT"
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "END_ENT",
                                    e.target.value
                                  );
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    address: e.target.value,
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.END_ENT &&
                                    validation.errors.END_ENT,
                                })}
                              />
                              {validation.touched.END_ENT &&
                              validation.errors.END_ENT ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.END_ENT}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="mb-3">
                              <Label>Número</Label>
                              <Input
                                value={validation.values.NumEndEnt}
                                name="NumEndEnt"
                                id="NumEndEnt"
                                maxLength={6}
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "NumEndEnt",
                                    e.target.value
                                  );
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    number: e.target.value,
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.NumEndEnt &&
                                    validation.errors.NumEndEnt,
                                })}
                              />
                              {validation.touched.NumEndEnt &&
                              validation.errors.NumEndEnt ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.NumEndEnt}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label>Complemento</Label>
                              <Input
                                type="text"
                                value={validation.values.ComplemEnt}
                                name="ComplemEnt"
                                id="ComplemEnt"
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "ComplemEnt",
                                    e.target.value
                                  );
                                  setDeliveryAddress({
                                    ...deliveryAddress,
                                    complement: e.target.value,
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.ComplemEnt &&
                                    validation.errors.ComplemEnt,
                                })}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Collapse>
                    </div>
                    <hr />

                    <div className="mb-3">
                      <div className="mb-4">
                        <h5 style={{ color: "#325a91" }}>
                          Análise de Crédito (Pessoa {titleFisicaJuridica})
                        </h5>
                      </div>

                      <Row className="px-2">
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Agência</Label>
                            <Input
                              type="text"
                              value={creditAnalysis.Agencia || ""}
                              name="Agencia"
                              id="Agencia"
                              maxLength={4}
                              readOnly={!editabled}
                              disabled={!editabled}
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                validation.setFieldValue(
                                  "AGENCIA",
                                  e.target.value.trimStart()
                                );
                                setCreditAnalysis({
                                  ...creditAnalysis,
                                  Agencia: e.target.value.trimStart(),
                                });
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.Agencia &&
                                  validation.errors.Agencia,
                              })}
                            />
                            {validation.touched.Agencia &&
                            validation.errors.Agencia ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Agencia}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Conta Corrente</Label>
                            <Input
                              type="text"
                              value={creditAnalysis.Conta || ""}
                              name="Conta"
                              id="Conta"
                              maxLength={10}
                              readOnly={!editabled}
                              disabled={!editabled}
                              onBlur={validation.handleBlur}
                              onChange={e => {
                                validation.setFieldValue(
                                  "CONTA",
                                  e.target.value.trim()
                                );
                                setCreditAnalysis({
                                  ...creditAnalysis,
                                  Conta: e.target.value.trim(),
                                });
                              }}
                              className={classnames("form-control", {
                                "is-invalid":
                                  validation.touched.Conta &&
                                  validation.errors.Conta,
                              })}
                            />
                            {validation.touched.Conta &&
                            validation.errors.Conta ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Conta}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      {cpfcnpjJF?.length > 14 ? (
                        <>
                          <Row className="px-2">
                            <Col md={4}>
                              <div className="mb-3">
                                <Label>Capital Social</Label>
                                <Input
                                  type="text"
                                  value={validation.values.CapSoc || ""}
                                  name="CapSoc"
                                  id="CapSoc"
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "CapSoc",
                                      e.target.value
                                    );
                                    setCreditAnalysis({
                                      ...creditAnalysis,
                                      CapSoc: e.target.value,
                                    });
                                  }}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.CapSoc &&
                                      validation.errors.CapSoc,
                                  })}
                                />
                                {validation.touched.CapSoc &&
                                validation.errors.CapSoc ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.CapSoc}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label>Faturamento Médio Mensal</Label>
                                <Input
                                  type="text"
                                  value={validation.values.FatMen || ""}
                                  name="FatMen"
                                  id="FatMen"
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "FatMen",
                                      e.target.value
                                    );
                                    setCreditAnalysis({
                                      ...creditAnalysis,
                                      FatMen: e.target.value,
                                    });
                                  }}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.FatMen &&
                                      validation.errors.FatMen,
                                  })}
                                />
                                {validation.touched.FatMen &&
                                validation.errors.FatMen ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.FatMen}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label>Data Fundação</Label>
                                <InputGroup>
                                  <InputGroupText className="bg-dark-blue text-white border-0">
                                    <i className="fa fa-calendar-days h5 p-0 m-0 text-white"></i>
                                  </InputGroupText>
                                  <Flatpickr
                                    placeholder="dd/mm/aaaa"
                                    options={{
                                      altInput: true,
                                      locale: Portuguese,
                                      altFormat: "d F, Y",
                                      dateFormat: "Ymd",
                                    }}
                                    name="FuncEmp"
                                    id="FuncEmp"
                                    readOnly={!editabled}
                                    disabled={!editabled}
                                    value={dateAORF.FuncEmp || ""}
                                    onBlur={validation.handleBlur}
                                    onChange={([date]) =>
                                      setDateAORF({
                                        FuncEmp: date
                                          ? `${date.getFullYear()}${(
                                              date.getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0")}${date
                                              .getDate()
                                              .toString()
                                              .padStart(2, "0")}`
                                          : "",
                                      })
                                    }
                                    className={classnames("form-control", {
                                      "is-invalid":
                                        validation.touched.FuncEmp &&
                                        validation.errors.FuncEmp,
                                    })}
                                  />
                                  {validation.touched.FuncEmp &&
                                  validation.errors.FuncEmp ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.FuncEmp}
                                    </FormFeedback>
                                  ) : null}
                                </InputGroup>
                              </div>
                            </Col>
                          </Row>

                          <Row className="px-2">
                            <Col md={6}>
                              <div className="mb-3">
                                <Label>Nome do Sócio</Label>
                                <Input
                                  type="text"
                                  value={creditAnalysis.Socio || ""}
                                  name="Socio"
                                  id="Socio"
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "SOCIO",
                                      e.target.value
                                    );
                                    setCreditAnalysis({
                                      ...creditAnalysis,
                                      Socio: e.target.value,
                                    });
                                  }}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.Socio &&
                                      validation.errors.Socio,
                                  })}
                                />
                                {validation.touched.Socio &&
                                validation.errors.Socio ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Socio}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label>CPF do Sócio</Label>
                                <Input
                                  type="text"
                                  value={creditAnalysis.CpfRef || ""}
                                  name="CpfRef"
                                  id="CpfRef"
                                  tag={InputMask}
                                  mask="999.999.999-99"
                                  readOnly={!editabled}
                                  disabled={!editabled}
                                  onBlur={validation.handleBlur}
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "CPFREF",
                                      e.target.value
                                    );
                                    setCreditAnalysis({
                                      ...creditAnalysis,
                                      CpfRef: e.target.value,
                                    });
                                  }}
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.CpfRef &&
                                      validation.errors.CpfRef,
                                  })}
                                />
                                {validation.touched.CpfRef &&
                                validation.errors.CpfRef ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.CpfRef}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row className="px-2 mb-4">
                            <Col md={6}>
                              <Label>Razão Social</Label>
                              <Input
                                type="text"
                                value={creditAnalysis.EmpTrab || ""}
                                name="EmpTrab"
                                id="EmpTrab"
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "EMPTRAB",
                                    e.target.value.trimStart()
                                  );
                                  setCreditAnalysis({
                                    ...creditAnalysis,
                                    EmpTrab: e.target.value.trimStart(),
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.EmpTrab &&
                                    validation.errors.EmpTrab,
                                })}
                              />
                              {validation.touched.EmpTrab &&
                              validation.errors.EmpTrab ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.EmpTrab}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col md={6}>
                              <Label>CNPJ</Label>
                              <Input
                                type="text"
                                value={mask(creditAnalysis.CnpjTrab) || ""}
                                name="CnpjTrab"
                                id="CnpjTrab"
                                maxLength="18"
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "CnpjTrab",
                                    e.target.value.trim()
                                  );
                                  setCreditAnalysis({
                                    ...creditAnalysis,
                                    CnpjTrab: e.target.value.trim(),
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.CnpjTrab &&
                                    validation.errors.CnpjTrab,
                                })}
                              />
                              {validation.touched.CnpjTrab &&
                              validation.errors.CnpjTrab ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.CnpjTrab}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="px-2 mb-4">
                            <Col md={6}>
                              <Label>Telefone da Empresa</Label>
                              <Input
                                type="text"
                                value={creditAnalysis.TelRefBanc || ""}
                                name="TelRefBanc"
                                id="TelRefBanc"
                                tag={InputMask}
                                mask="(99) 9999-9999"
                                readOnly={!editabled}
                                disabled={!editabled}
                                onBlur={validation.handleBlur}
                                onChange={e => {
                                  validation.setFieldValue(
                                    "TELREFBANC",
                                    e.target.value
                                  );
                                  setCreditAnalysis({
                                    ...creditAnalysis,
                                    TelRefBanc: e.target.value,
                                  });
                                }}
                                className={classnames("form-control", {
                                  "is-invalid":
                                    validation.touched.TelRefBanc &&
                                    validation.errors.TelRefBanc,
                                })}
                              />
                              {validation.touched.TelRefBanc &&
                              validation.errors.TelRefBanc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.TelRefBanc}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col md={6}>
                              <Label>Data Admissão</Label>
                              <InputGroup>
                                <InputGroupText className="bg-dark-blue text-white border-0">
                                  <i className="fa fa-calendar-days h5 p-0 m-0 text-white"></i>
                                </InputGroupText>
                                <Flatpickr
                                  placeholder="dd/mm/aaaa"
                                  options={{
                                    altInput: true,
                                    locale: Portuguese,
                                    altFormat: "d F, Y",
                                    dateFormat: "Ymd",
                                  }}
                                  name="DtAdmiss"
                                  id="DtAdmiss"
                                  readOnly
                                  disabled
                                  value={dateAORF.DtAdmiss || ""}
                                  onBlur={validation.handleBlur}
                                  onChange={([date]) =>
                                    setDateAORF({
                                      DtAdmiss: date
                                        ? `${date.getFullYear()}${(
                                            date.getMonth() + 1
                                          )
                                            .toString()
                                            .padStart(2, "0")}${date
                                            .getDate()
                                            .toString()
                                            .padStart(2, "0")}`
                                        : "",
                                    })
                                  }
                                  className={classnames("form-control", {
                                    "is-invalid":
                                      validation.touched.DtAdmiss &&
                                      validation.errors.DtAdmiss,
                                  })}
                                />
                                {validation.touched.DtAdmiss &&
                                validation.errors.DtAdmiss ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.DtAdmiss}
                                  </FormFeedback>
                                ) : null}
                              </InputGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                    {editabled && (
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-dark-blue btn-md px-3 rounded-pill text-uppercase"
                        >
                          <i className="fa fa-save me-2"></i> Salvar Dados
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
            {/* <hr />
            <Card>
              <CardBody>
              <TelephoneRegister customerId={customerId}/>
              </CardBody>
            </Card> */}

            {/* TODO : missing foield in response data */}
            {/* <Card>
              <CardBody> */}
            {/* <div className="mb-3">
                  <div className="mb-4">
                    <h5>
                      <i className="mdi mdi-phone h3 align-middle me-2"></i>
                      Telefones
                    </h5>
                  </div>

                  <Row className="px-2">
                    <Col md={1}>
                      <div className="mb-3">
                        <Input 
                          type="text" 
                          className="form-control" 
                          readOnly={!editabled}
                          disabled={!editabled}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Input 
                          type="text" 
                          className="form-control" 
                          readOnly={!editabled}
                          disabled={!editabled}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-success text-uppercase"
                          disabled={!editabled}
                        >
                          <i className="fa fa-check"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div> */}
            {/* </CardBody>
            </Card> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
