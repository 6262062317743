import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { Container, Row, Col, Toast } from "reactstrap";
import { Check } from "phosphor-react";
import { toast } from "react-toastify";

import "./promotionRules.css";
import Breadcrumb from "../../components/Breadcrumb";
import Loader from "../../components/Loader";
import PromotionDetails from "./details";
import Rules from "./rules/index.js";
import PromotionActions from "./actions";

import {
  createPromotionRules,
  getRules,
  getRulesColumnsFields,
  updatePromotionRules,
} from "../../store/promotionRules/action";

import { useCurrentCompany, useProfile } from "../../hooks";
import { formatDiscountAmount } from "./functions";
import { isInTheGroup } from "../../helpers/user_helper.js";

const PromotionRules = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  const [ruleName, setRuleName] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [needCoupon, setNeedCoupon] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [company, setCompany] = useState(useCurrentCompany());
  const [selectedRulesConditions, setSelectedRulesConditions] = useState([]);
  const [typeRuleDiscount, setTypeRuleDiscount] = useState(1);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [maximumProductsAllowed, setMaximumProductsAllowed] = useState(0);
  const [maximumUsesPerUserAllowed, setMaximumUsesPerUserAllowed] = useState(0);
  const [applyOnlyThisRule, setApplyOnlyThisRule] = useState(false);
  const [applyToZipcode, setApplyToZipcode] = useState(false);

  const {
    loadingCreate,
    loadingUpdate,
    loadingRules,
    dataRules,
    loadingRulesColumnsFields,
    user_restric_page
  } = useSelector(state => ({
    dataRules: state.PromotionRules.data,
    loadingCreate: state.PromotionRules.loadingCreate,
    loadingUpdate: state.PromotionRules.loadingUpdate,
    loadingRules: state.PromotionRules.loadingRules,
    loadingRulesColumnsFields: state.PromotionRules.loadingRulesColumnsFields,
    user_restric_page: state.FirebaseRemoteConfig.user_restric_page,
  }));

  var userCode = userProfile?.claims?.code;

  useEffect(() => {
    if (user_restric_page && userCode) {
      var user_restric_page_object = JSON.parse(user_restric_page);
      const adminGroup = 1;
      var isVisible =  findUser(user_restric_page_object, userCode) || isInTheGroup([adminGroup], userProfile);

      if (!isVisible) {
        toast.error('Você não tem permissão para acessar cupons e promoções');
        history.replace('/');
      }
    }
  }, [userCode, location.pathname, user_restric_page]);

  function findUser(users, find) {
    return users.indexOf(find) >= 0;
  }

  useEffect(() => {
    if (id) {
      dispatch(getRules(id));
    } else {
      initializeNewRule();
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (dataRules?.id && id) {
      setFormData(dataRules);
    }
  }, [dataRules]);

  useEffect(() => {
    dispatch(getRulesColumnsFields());
  }, [dispatch]);

  const initializeNewRule = () => {
    setRuleName("");
    setDescription("");
    setActive(true);
    setStartDate("");
    setEndDate("");
    setCompany(useCurrentCompany());
    setNeedCoupon(false);
    setGeneratedCode("");
    setTypeRuleDiscount(1); // Default to 1 or set to another initial value if needed
    setDiscountAmount(0);
    setMaximumProductsAllowed(0);
    setApplyOnlyThisRule(false);
    setApplyToZipcode(false);
    setSelectedRulesConditions([]);
  };

  const setFormData = data => {
    setRuleName(data.rule_name);
    setDescription(data.rule_description);
    setActive(data.active === 1);
    setStartDate(formatDate(data.start));
    setEndDate(formatDate(data.end));
    setCompany(data.company || useCurrentCompany());
    setNeedCoupon(data.need_coupom);
    setGeneratedCode(data.coupom_used || "");
    setTypeRuleDiscount(data.typerulediscount ? data.typerulediscount : 0);
    setDiscountAmount(data.valuediscount ? formatDiscountAmount(data.valuediscount, typeRuleDiscount) : 0);
    setMaximumProductsAllowed(data.maxproductsdiscount);
    setMaximumUsesPerUserAllowed(data.max_uses_per_user);
    setApplyToZipcode(data.applytoshipping === 1);
    setSelectedRulesConditions(data.rules_conditions);

    if (id && data.company !== useCurrentCompany()) {
      toast.error("Essa promoção não pertence a empresa atual");
      return (window.location.href = `${location.origin}/`);
    }
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = () => {
    if (!startDate || !endDate) {
      return toast.error("Preencha as datas da promoção");
    }

    if (new Date(startDate) > new Date(endDate)) {
      return toast.error("A data inicial não pode ser maior do que a final");
    }

    const valueDiscount = discountAmount ? discountAmount.replace(/[^0-9.,]/g, "").replace(/,/g, ".") : 0;

    if (valueDiscount < 1) {
      return toast.error("O valor da promoção precisa ser maior que 1");
    }

    if ((typeRuleDiscount == 1 && (valueDiscount > 100))) {
      return toast.error("O valor da promoção precisa estar entre  1 e 100");
    }

    const formattedData = {
      id: id,
      rule_name: ruleName,
      rule_description: description,
      active: active ? 1 : 0,
      start: startDate,
      end: endDate,
      company: company,
      need_coupom: needCoupon ? 1 : 0,
      coupom_used: generatedCode,
      typerulediscount: typeRuleDiscount,
      valuediscount: valueDiscount,
      maxproductsdiscount: maximumProductsAllowed,
      max_uses_per_user: maximumUsesPerUserAllowed,
      applytoshipping: applyToZipcode ? 1 : 0,
      applyonlythisrule: applyOnlyThisRule ? 1 : 0,
      rules_conditions: selectedRulesConditions,
      company: useCurrentCompany(),
    };

    if (id) {
      dispatch(updatePromotionRules(formattedData, history));
    } else {
      dispatch(createPromotionRules(formattedData, history));
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumb title="Promoções" breadcrumbItem="Cria promoção" />
          {(loadingRules || loadingUpdate || loadingCreate || loadingRulesColumnsFields) && <Loader />}
          <AvForm onValidSubmit={handleSubmit}>
            <PromotionDetails
              ruleName={ruleName}
              setRuleName={setRuleName}
              description={description}
              setDescription={setDescription}
              active={active}
              setActive={setActive}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              needCoupon={needCoupon}
              setNeedCoupon={setNeedCoupon}
              generatedCode={generatedCode}
              setGeneratedCode={setGeneratedCode}
              company={company}
              setCompany={setCompany}
            />
            <Rules
              cartRuleData={dataRules}
              selectedRulesConditions={selectedRulesConditions}
              setSelectedRulesConditions={setSelectedRulesConditions}
            />
            <PromotionActions
              typeRuleDiscount={typeRuleDiscount}
              setTypeRuleDiscount={setTypeRuleDiscount}
              discountAmount={discountAmount}
              setDiscountAmount={setDiscountAmount}
              maximumProductsAllowed={maximumProductsAllowed}
              setMaximumProductsAllowed={setMaximumProductsAllowed}
              maximumUsesPerUserAllowed={maximumUsesPerUserAllowed}
              setMaximumUsesPerUserAllowed={setMaximumUsesPerUserAllowed}
              applyOnlyThisRule={applyOnlyThisRule}
              setApplyOnlyThisRule={setApplyOnlyThisRule}
              applyToZipcode={applyToZipcode}
              setApplyToZipcode={setApplyToZipcode}
            />
            <Row className="mb-3">
              <Col md={12} className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-success d-flex align-items-center justify-content-center"
                  style={{ minWidth: 158, minHeight: 38 }}
                >
                  {(id ? loadingUpdate : loadingCreate) ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle" />
                  ) : (
                    <>
                      <p className="m-0">
                        {id ? "Atualizar Regra" : "Cadastrar Regra"}
                      </p>
                      <span style={{ marginTop: -2, marginLeft: 5 }}>
                        <Check size={22} color="#f0f0f0" weight="bold" />
                      </span>
                    </>
                  )}
                </button>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PromotionRules;
