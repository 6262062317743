import React, { useEffect, useState } from "react";
import { Col, Row, FormGroup, Label, Input } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "./style.scss";
import { numberOfModules } from "../../../../formulas/custom-kit/photovoltaic-module";
import { totalFootageOfMetalProfiles } from "../../../../formulas/custom-kit/metal-structure";
import { toast } from "react-toastify";
import { GET_FIREBASE_REMOTE_CONFIG_FABRICANTES_INVERSORES, GET_FIREBASE_REMOTE_CONFIG_CONDICAO_PAGAMENTO_SOLAR } from "../../../../store/firebaseRemoteConfig/actionTypes";

import {
  addItemsToCart,
  addStatus,
  addToCart,
  editPrecoCliente,
  getFireBaseRemoteConfig,
  removeStatus,
  resetFreteValue,
  zerarCarrinho,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAcrescimoCount, valueByQTD } from "../../../../utils/formulas";
import { apiClient } from "../../../../api/apiCore";
import SelectSearch from "../../../../components/SelectSerach";
import { dateNow } from "../../../../utils";
import { useProfile } from "../../../../hooks";
import { normalizeString } from "../../../../utils"

import { useDataLayer } from "../../../../hooks/DataLayerHooks";
import { useColors } from "../../../../hooks/useColors";
export const FormModule = ({
  moduleSelected,
  loadingSearModule,
  addValTot,
  dataLeed,
  setLoadingSearchModule,
  setProductsVisible,
  data,
  setData,
  setPercentCashBackAll,
  acrescimo,
  setAcrescimo,
  setRealPower,
  setValTotAndRt,
  setFilterPedido,
  setChangeFormOfPayment,
  setGetKitLoading,
  setWaitingToChangeCartItems,
  resetAllInfosCarrinho,
  setDataEstoque,
  isMicroInverterSelected,
  setPagtoSelected,
  addValoresTabela,
  largeAccountCode,
  setRemoveFromCartState,
}) => {
  const dispatch = useDispatch();
  const { newOrder, user, filters, condPagamentoSolar } = useSelector(state => ({
    newOrder: state.Order.newOrder.newOrder,
    user: state.Profile.profileDetails.user,
    filters: state.Order.newOrder.newOrder.filters,
    condPagamentoSolar: state.FirebaseRemoteConfig.condPagamentoSolar,
  }));
  const { pushToDataLayer } = useDataLayer();
  const { userProfile } = useProfile();
  const { colorLight } = useColors();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";
  const [roofs, setRoofs] = useState([]);
  const myDiv = document.getElementById("sentinel");
  const [Coordinates, setCoordinates] = useState(0);
  const [pagamento, setPagamento] = useState([]);
  const [filterPagamento, setFilterPagamento] = useState([]);
  const [loadingAcrescimo, setLoadingAcrescimo] = useState(false);
  const userCode = user?.code ? user?.code : "";

  useEffect(() => {
    let pagto = "";
    if (pagamento.length > 0) {
      pagto = pagamento[0]?.codigo || "";

      if (largeAccountCode.includes(pagto)) {
        dispatch(resetFreteValue());
      }

      setFilterPedido(state => {
        return {
          ...state,
          Nomecondpagto: pagamento[0]?.label || "",
          condpagto: pagamento[0]?.codigo || "",
        };
      });
    }
  }, [pagamento]);


  useEffect(() => {
    if (condPagamentoSolar) {

      const parsedPaymentData = typeof condPagamentoSolar === 'string' ? JSON.parse(condPagamentoSolar) : condPagamentoSolar;

      setFilterPagamento(formatOptions(parsedPaymentData))
    }
  }, [condPagamentoSolar]);



  useEffect(() => {
    const paymentConditions = filterPagamento.filter((item) => !item.isGroup);
    if (filterPagamento.filter((item) => !item.isGroup).length > 0) {
      setPagamento([paymentConditions[0]]);
      setPagtoSelected(paymentConditions[0]);
    }
  }, [filterPagamento.length > 0]);

  useEffect(() => {
    if (document) {
      setCoordinates(myDiv?.getBoundingClientRect());
    }
  }, [document, myDiv]);

  useEffect(() => {
    setData(state => {
      return {
        ...state,
        potenciaKwp: dataLeed?.calculations?.kwp
          ? dataLeed?.calculations?.kwp.replace(",", ".")
          : 0,
      };
    });
  }, [dataLeed]);

  // Função de comparação para ordenar por propriedade 'nome'
  function compararPorNome(a, b) {
    const nomeA = a.roof_name.toUpperCase(); // Ignorar maiúsculas e minúsculas
    const nomeB = b.roof_name.toUpperCase();

    if (nomeA < nomeB) {
      return -1;
    }
    if (nomeA > nomeB) {
      return 1;
    }
    return 0; // Nomes são iguais
  }


  const getRoofs = () => {
    apiClient.get(`/api/solar/roofs`).then(res => {
      let objFormat = [];
      let orderData = res.data.sort(compararPorNome);

      orderData.forEach(item => {
        objFormat.push({ id: item.index, name: item.roof_name });
      });
      setData(state => {
        return { ...state, tipoFix: [objFormat[0]] };
      });
      setRoofs(objFormat);
    });
  };

  useEffect(() => {
    getRoofs();
  }, [dataLeed?.lead?.roof_type, dataLeed?.lead?.kwh_price]);

  useEffect(() => {
    dispatch(getFireBaseRemoteConfig("CONDICAO_PAGAMENTO_SOLAR", "", GET_FIREBASE_REMOTE_CONFIG_CONDICAO_PAGAMENTO_SOLAR));
  }, []);

  useEffect(() => {
    if (filters[data.microOrInv.type]) {
      setData({
        ...data,
        fabInversor: filters[data.microOrInv.type][0].fabricante,
        fase: filters[data.microOrInv.type][0].fase,
        tensaoRede: filters[data.microOrInv.type][0].tensao,
      });
    }
  }, [filters]);

  const validations = () => {
    let erros = [];
    let miniTrilho = data.tipoFix[0].id == 4;
    let modPorLinha = data.modLinha == "";

    if (miniTrilho) {
      if (modPorLinha) {
        erros.push("Campo Módulos por linha é obrigatório");
      }
    }

    return erros;
  };

  const resetAllInfos = () => {
    dispatch(zerarCarrinho());
    dispatch(removeStatus());
    setPercentCashBackAll("0");
    setValTotAndRt({});
  };

  const handleSubmit = async () => {

    if (
      data?.microOrInv?.id === 4 &&
      (data?.fabInversor === "Enphase IQ7" || data?.fabInversor === "Enphase IQ8") &&
      data?.fase === "bifasico" &&
      data?.tensaoRede === "380"
    ) {
      return toast.warning("Não é possível criar o kit com essas configurações.", { theme: "colored" });
    }

    if (!moduleSelected?.potencia) {
      return toast.warning("Selecione um módulo", { theme: "colored" });
    }
    if (data?.potenciaKwp == 0) {
      return toast.warning("Informe uma potência", { theme: "colored" });
    }
    if (!data?.fabInversor || data.fabInversor.trim() == "") {
      return toast.warning("Informe um fabricante", { theme: "colored" });
    }
    setGetKitLoading(true);
    setProductsVisible(true);
    let erros = validations();

    if (erros.length > 0) {
      erros.map(value => {
        toast.warning(value, { theme: "colored" });
      });
      return;
    }
    setLoadingSearchModule(true);
    resetAllInfos();

    let resultNumberOfModules = numberOfModules(
      data.potenciaKwp * 1000,
      moduleSelected.potencia,
      data.consumoAtender
    );

    const moduleStock = (moduleSelected.qtdEstoque + 1) - resultNumberOfModules;

    if (moduleStock <= 0) {
      toast.error('Não há módulos suficientes no estoque para montagem desse kit!', { theme: "colored" });
      setGetKitLoading(false);
      setProductsVisible(false);
      setLoadingSearchModule(false);
      return;
    }

    moduleSelected.qtdEstoque = moduleStock

    let resultTotalFootageOfMetalProfiles = totalFootageOfMetalProfiles(
      resultNumberOfModules,
      moduleSelected.compimentoModulo
    );

    let miniTrilhoOrPerfil =
      data.tipoFix[0].id == 4
        ? {
          miniTrilho: {
            miniTrilho: data.tipoFix[0].name,
          },
        }
        : {
          perfil: {
            footage: resultTotalFootageOfMetalProfiles,
            ciclo: data.tipoFix[0].id,
          },
        };

    let obj = {
      numModules: resultNumberOfModules,
      sizeDefine: moduleSelected.compimentoModulo,
      modulesPerLine: Number(data.modLinha),
      formOfPayment: pagamento[0]?.label || "",
      fixation: {},
    };

    if (!data?.microOrInv.name.includes("MICRO")) {
      obj.inverters = {
        grid: normalizeString(data.fase),
        potency: Number(data.potenciaKwp) * 1000,
        module: moduleSelected.nome,
        module_potency: moduleSelected?.potencia,
        voltage: data.tensaoRede,
        transformer: data.transformer == "sim" ? true : false,
      };
      obj.manufacturer = data.fabInversor;
    } else {
      obj.type = data?.microOrInv.type;
      obj.module = moduleSelected.nome;
      obj.module_potency = moduleSelected?.potencia;
      obj.potency = Number(data.potenciaKwp) * 1000;
      obj.manufacturer = data.fabInversor;
      obj.grid = normalizeString(data.fase);
      obj.voltage = data.tensaoRede;
    }

    obj.fixation = miniTrilhoOrPerfil;

    try {
      const resp = await apiClient.create(
        `/api/person-kits/getKit/02/${isMicroInverterSelected}?type=${data?.microOrInv.type}`,
        obj
      );

      if (resp?.erro) {
        setProductsVisible(false);
        setLoadingSearchModule(false);
        return toast.info(resp.msg, { theme: "colored" });
      }

      pushToDataLayer(
        'select_item',
        {
          ecommerce: {
            currency: "BRL",
            items: [{
              item_inversor: data?.microOrInv.type,
              item_fabricante: data.fabInversor,
              item_fase: data.fase,
              tipo_telhado: data.tipoFix[0]?.name
            }]
          }
        },
        { clearEcommerce: true }
      );

      for (const items in resp) {
        if ('warning' in resp[items]) {
          toast.warning(resp[items].msg, { theme: "colored" });
          resp[items] = [];
        }
      }

      if (resp?.inverter[0]?.alert) {
        toast.info(resp?.inverter[0]?.alert, { theme: "colored" });
      }
      dispatch(resetFreteValue());

      addProductToCart(resp, resultNumberOfModules);
    } catch (error) {
      setProductsVisible(false);
      toast.error(error, { theme: "colored" });
    }

    setLoadingSearchModule(false);
  };

  const addProductToCart = (kit, resultNumberOfModules) => {
    let valTotal = [];
    let valTabela = [];
    let dataProduct;
  
    const items = [];

    var data = kit?.data;
  
    delete kit?.data;
  
    setRealPower(data?.potency);

    const itemsForDataLayer = [];

    for (const kits in kit) {
      // VALIDA SE O KIT É UM ARRAY
  
      if (Array.isArray(kit[kits])) {
        for (const subKits in kit[kits]) {
          // valida se o array está vazio
          if (kit[kits].length == 0) {
            return;
          }
          
          // adiciona o produto
          dataProduct = {
            produto: kit[kits][subKits],
          };
  
          // verifica se tem acréscimo baseado na escolha da forma de pagamento
          var resultAcrescimo = getAcrescimoCount(
            acrescimo,
            dataProduct.produto.precoCliente
          );
  
          dataProduct.produto.precoClienteAcrescimo = resultAcrescimo;
  
  
          dataProduct.produto.valorTabela = resultAcrescimo;

          // adiciona o valor total do produto baseando na quantidade
          dataProduct.produto.subTotal = valueByQTD(
            resultAcrescimo,
            dataProduct.produto.qtd ? dataProduct.produto.qtd : 1
          );
  
          dataProduct.produto.PRCRT = valueByQTD(
            resultAcrescimo,
            dataProduct.produto.qtd ? dataProduct.produto.qtd : 1
          );
  
          dataProduct.produto.initialQtd = kit[kits][subKits].qtd
            ? kit[kits][subKits].qtd
            : 1;
  
          dataProduct.promotionDiscount = 0;

          // add valor dos itens
          valTotal.push({
            [kit[kits][subKits]?.id + " " + kit[kits][subKits].nome]:
              valueByQTD(
                resultAcrescimo,
                kit[kits][subKits].qtd ? kit[kits][subKits].qtd : 1
              ),
          });
  
          items.push(dataProduct);

          itemsForDataLayer.push({
            item_id: dataProduct.produto.sku,
            item_name: dataProduct.produto.nome,
            price: dataProduct.produto.PRCRT,
            quantity: dataProduct.produto.qtd
          });
        }
      } else {
        // adiciona o produto
  
        dataProduct = {
          produto: kit[kits],
        };
  
        // verifica se tem acréscimo baseado na escolha da forma de pagamento
        var resultAcrescimo = getAcrescimoCount(
          acrescimo,
          dataProduct.produto.precoCliente
        );
  
        dataProduct.produto.precoClienteAcrescimo = resultAcrescimo;
  
  
        dataProduct.produto.valorTabela = resultAcrescimo;

        // adiciona o valor total do produto baseando na quantidade
        dataProduct.produto.subTotal = valueByQTD(
          resultAcrescimo,
          dataProduct.produto.qtd ? dataProduct.produto.qtd : 1
        );
  
        dataProduct.produto.PRCRT = valueByQTD(
          resultAcrescimo,
          dataProduct.produto.qtd ? dataProduct.produto.qtd : 1
        );
  
        dataProduct.promotionDiscount = 0;

        // add valor dos itens
        valTotal.push({
          [kit[kits].nome]: valueByQTD(
            resultAcrescimo,
            kit[kits].qtd ? kit[kits].qtd : 1
          ),
        });
  
        items.push(dataProduct);

        itemsForDataLayer.push({
          item_id: dataProduct.produto.sku,
          item_name: dataProduct.produto.nome,
          price: dataProduct.produto.PRCRT,
          quantity: dataProduct.produto.qtd
        });

      }
    }

    pushToDataLayer(
      'view_item_list',
      {
        ecommerce: {
          currency: 'BRL',
          items: itemsForDataLayer
        }
      },
      { clearEcommerce: true }
    );

    updateValues(valTotal);
    insertValoresTabela(valTotal);
    dispatch(addItemsToCart(items));
    setGetKitLoading(false);
    toast.success(`adicionado ao carrinho.`, {
      theme: "colored",
    });
  };

  const updateValues = (valTotal) => {
    let objValTotal = {};
    Object.keys(valTotal).map(item => {
      var key = Object.keys(valTotal[item]);
      var value = Object.values(valTotal[item]);

      objValTotal = {
        ...objValTotal,
        [key[0]]: value[0],
      };
    });
    addValTot(objValTotal);
  };

  const insertValoresTabela = (valoresTabela) => {
    let objValTotal = {};
    Object.keys(valoresTabela).map(item => {
      var key = Object.keys(valoresTabela[item]);
      var value = Object.values(valoresTabela[item]);

      objValTotal = {
        ...objValTotal,
        [key[0]]: value[0],
      };
    });

    addValoresTabela(objValTotal);
  };

  const getAcrescimo = async () => {
    var obj = { condpagamento: pagamento[0].codigo };
    setLoadingAcrescimo(true);
    try {
      setWaitingToChangeCartItems(true);

      const resp = await apiClient.create(`/api/acrescimoCondPag`, obj);
      let acrescimo = resp;
      var valTotal;
      var valoresTabela;
      var editProductQtd = newOrder.addedItems;
      if (editProductQtd.length > 0) {
        for await (const editProduct of editProductQtd) {
          var qtd = editProduct.produto[0].qtd ? editProduct.produto[0].qtd : 1;
          var resultAcrescimoPrecoCliente = getAcrescimoCount(
            acrescimo,
            editProduct.produto[0].precoCliente
          );
          dispatch(editPrecoCliente(editProduct.produto[0].nome, acrescimo));
          valTotal = {
            ...valTotal,
            [editProduct?.produto[0].nome]: resultAcrescimoPrecoCliente * qtd,
          };
          valoresTabela = {
            ...valoresTabela,
            [editProduct?.produto[0].nome]: resultAcrescimoPrecoCliente * qtd,
          };
        }

        addValTot(valTotal);
        addValoresTabela(valoresTabela);
      }
      /* addValTot(objValTotal); */
      setAcrescimo(resp);
      setWaitingToChangeCartItems(false);
    } catch (error) {
      toast.error(error, { theme: "colored" });
      setWaitingToChangeCartItems(false);
    }
    setLoadingAcrescimo(false);
  };

  useEffect(() => {
    if (pagamento[0]?.codigo) {
      getAcrescimo();
    }
  }, [pagamento]);

  const format = e => {
    var valor = e.target.value;

    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ".$1");

    if (valor == "NaN") {
      setData(state => {
        return {
          ...state,
          potenciaKwp: "",
        };
      });
    } else {
      setData(state => {
        return {
          ...state,
          potenciaKwp: valor,
        };
      });
    }
  };

  const handleChangeInvType = e => {
    const value = e.target.value;

    resetAllInfosCarrinho();

    const findMicroOrInv = {
      name: value,
      type: inversorType.find(inv => inv.name === value).type
    }

    setData(state => {
      return {
        ...state,
        microOrInv: findMicroOrInv,
        tensaoRede: filters[findMicroOrInv.type][0]?.tensao,
        fase: filters[findMicroOrInv.type][0]?.fase,
        fabInversor: filters[findMicroOrInv.type][0]?.fabricante
      };
    });

    if (findMicroOrInv.name == "MICRO INVERSOR") {
      setDataEstoque(state => {
        return { ...state, uf: "SP", estoque: "SÃO PAULO" };
      });
    } else {
      setDataEstoque(state => {
        return { ...state, uf: "SP", estoque: "CASCAVEL" };
      });
    }
  };

  const handleChangeInvManufacturer = e => {
    const value = e.target.value;

    const inversor = filters[data.microOrInv.type]
      .find(inv => inv.fabricante === value);

    setData(state => {
      return {
        ...state,
        fabInversor: value,
        fase: inversor.fase,
        tensaoRede: inversor.tensao
      };
    })
  }

  const handleChangeInvPhase = e => {
    const value = e.target.value;

    const inversor = filters[data.microOrInv.type]
      .find(inv => inv.fabricante === data.fabInversor && inv.fase === value);

    setData(state => {
      return {
        ...state,
        fase: value,
        tensaoRede: inversor.tensao
      };
    })
  }

  const handleSelectFormOfPayment = e => {
    setPagamento([e]);
    setChangeFormOfPayment(true);
    setTimeout(() => {
      setChangeFormOfPayment(false);
    }, 8000);
    toast.info(
      `Ao atualizar a condição de pagamento, os valores dos produtos são atualizados e podem sofrer alterações.`,
      { theme: "colored" }
    );
    addStatusFormOfPayment(e);
  };

  const addStatusFormOfPayment = currentFormOfPayment => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();
    let status = {
      statusName: "change",
      name: "FORMA DE PAGAMENTO",
      date: date,
      hours: hours,
      qtdAdias: pagamento[0].label,
      qtdChange: currentFormOfPayment?.label,
      userCode: userCode,
      displayName: displayName,
    };

    dispatch(addStatus(status));
  };

  const getInversorTypeName = value => {
    let typeName = '';

    switch (value) {
      case 'INVN':
        typeName = 'INVERSOR ON GRID';
        break;
      case 'INVF':
        typeName = 'INVERSOR OFF GRID';
        break;
      case 'INVH':
        typeName = 'INVERSOR HÍBRIDO';
        break;
      case 'MICN':
        typeName = 'MICRO INVERSOR';
    };

    return typeName;
  };

  const inversorType = [];

  Object.keys(filters).map(value => {
    inversorType.push({
      name: getInversorTypeName(value),
      type: value
    });
  });

  const auxManufacturers = filters[data.microOrInv.type]?.map(item => item.fabricante);
  const inversorManufacturers = [...new Set(auxManufacturers)];

  const auxPhases = filters[data.microOrInv.type]
    ?.filter(inv => inv.fabricante === data.fabInversor)
    ?.map(item => item.fase);

  const inversorPhases = [...new Set(auxPhases)];

  const auxVoltage = filters[data.microOrInv.type]
    ?.filter(inv => inv.fabricante === data.fabInversor && inv.fase === data.fase)
    ?.map(item => item.tensao);

  const inversorVoltages = [...new Set(auxVoltage)];

  const formatOptions = (data) => {
    let formatted = [];

    Object.entries(data)
      .forEach(([category, items]) => {
        formatted.push({ label: `${category}`, isGroup: true });

        Object.entries(items)
          .sort(([, a], [, b]) => a.name.localeCompare(b.name))
          .forEach(([key, details]) => {
            formatted.push({ codigo: key, label: details.name, type: category });
          });
      });

    return formatted;
  };


  return (
    <FormGroup className="form-module">
      <Row className="mt-4">
        <Col md={4}>
          <Label>Tipo de inversor:</Label>
          <select
            className="form-select"
            aria-label="Filter select"
            onChange={handleChangeInvType}
            value={data.microOrInv.name}
          >
            {inversorType.map((item, key) => {
              return (
                <option key={key} name={item.type}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </Col>

        <Col md={4}>
          <Label>Fabricante do inversor:</Label>
          <select
            className="form-select"
            aria-label="Filter select"
            onChange={handleChangeInvManufacturer}
            value={data.fabInversor}
          >
            {inversorManufacturers?.map((item, key) => {
              return (
                <option key={key} name={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label>Formas de Pagamento</Label>
            <SelectSearch
              options={
                filterPagamento || []
              }
              disable={loadingSearModule}
              onChange={paymentSelected => {
                if (confirm) {
                  if (!paymentSelected.isGroup) {
                    handleSelectFormOfPayment(paymentSelected);
                    setPagtoSelected(paymentSelected)
                    setRemoveFromCartState(true);
                  }
                }
              }}
              selected={pagamento}
              renderMenuItem={(item) => (
                <div
                  style={{
                    fontWeight: item.isGroup ? "bold" : "normal",
                    backgroundColor: item.isGroup ? colorLight : "transparent",
                    padding: item.isGroup ? "5px 10px" : "3px 10px",
                    cursor: item.isGroup ? "default" : "pointer",
                    pointerEvents: item.isGroup ? "none" : "auto",
                  }}
                >
                  {item.label}
                  {!item.isGroup && <div><small>{item.type}</small></div>}
                </div>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md={4}>
          <Label>Fase:</Label>
          <select
            className="form-select"
            aria-label="Filter select"
            onChange={handleChangeInvPhase}
            value={data.fase}
          >
            {inversorPhases?.map((item, key) => {
              return (
                <option key={key} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md={4}>
          <Label>Tensão da rede:</Label>
          <select
            className="form-select"
            aria-label="Filter select"
            onChange={e =>
              setData(state => {
                return { ...state, tensaoRede: e.target.value };
              })
            }
            value={data.tensaoRede}
          >
            {inversorVoltages?.map((item, key) => {
              return (
                <option key={key} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md={4}>
          <Label>Transformador:</Label>
          <select
            className="form-select"
            aria-label="Filter select"
            onChange={e =>
              setData(state => {
                return { ...state, transformer: e.target.value };
              })
            }
            value={data.transformer}
          >
            <option value="sim">SIM</option>
            <option value="nao">NÃO</option>
          </select>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={4}>
          <Label>Tipo de Telhado:</Label>
          <Typeahead
            id="table"
            labelKey={option => `${option.name}`}
            onFocus={() => {
              setData(state => {
                return { ...state, tipoFix: [] };
              });
            }}
            onChange={e => {
              setData(state => {
                return { ...state, tipoFix: e };
              });
            }}
            selected={data.tipoFix}
            options={roofs}
          />
        </Col>

        <Col md={4}>
          <Label>
            {data.tipoFix[0]?.name == "Solo"
              ? "Conjunto/Estrutura em linhas:"
              : "Módulos por linha:"}
          </Label>
          <Input
            type="number"
            value={data.modLinha}
            onChange={e =>
              setData(state => {
                return { ...state, modLinha: e.target.value };
              })
            }
          />
        </Col>
      </Row>

      <hr />
      <Row className="mt-4">
        <Col md={4}>
          <Label>Potência (kWp):</Label>
          <Input value={data.potenciaKwp} type="text" onChange={format} />
        </Col>
      </Row>
      <div className="container-button">
        <button
          disabled={loadingSearModule || loadingAcrescimo}
          onClick={() => { handleSubmit(); setRemoveFromCartState(false); }}
          className="btn button-module"
        >
          {loadingAcrescimo
            ? "Alterando forma de pagamento..."
            : "Criar Kit Personalizado"}
        </button>
      </div>
    </FormGroup>
  );
};