import React, { useEffect } from "react";

import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { useSolar } from "../../hooks";
import { AvField } from "availity-reactstrap-validation";
import { getRandomString } from "./functions";
import { toast } from "react-toastify";
import { filiais } from "../../utils/filiais";
import Switch from "../../components/Switch";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import Flatpickr from "react-flatpickr";
import { useColors } from "../../hooks/useColors";

const PromotionDetails = ({
  ruleName,
  setRuleName,
  description,
  setDescription,
  active,
  setActive,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  needCoupon,
  setNeedCoupon,
  generatedCode,
  setGeneratedCode,
  company,
  setCompany
}) => {

  const isSolar = useSolar();

  let maxLengthCoupunCode = 11;

  const createRandomStringForCoupun = (needCoupon) => {
    if (needCoupon) {
      setGeneratedCode(getRandomString(5));
    }
  }

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-center flex-column">
          <h5>
            <i className="fas fa-info-circle me-1"></i> Detalhes
          </h5>
          <hr />
          <Row className="mb-3">
            <AvField
              name="ruleName"
              label="Nome da regra"
              type="text"
              placeholder="Digite o nome da regra"
              value={ruleName}
              onChange={(e) => setRuleName(e.target.value)}
              validate={{
                required: { value: true, errorMessage: 'Por favor, digite um nome para a regra' },
              }}
            />
          </Row>

          <Row className="mb-3">
            <AvField
              name="description"
              label="Descrição da regra"
              type="text"
              placeholder="Digite uma descrição"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              validate={{
                required: { value: true, errorMessage: 'Por favor, digite uma descrição para a regra' },
              }}
            />
          </Row>

          <Row className="mb-3">
            <Switch
              textLabel="Ativo: "
              setChecked={(checked) => {
                setActive(checked);
              }}
              checked={active}
            />
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Label>Selecione uma data de ínicio</Label>
              <Flatpickr
                placeholder="DD/MM/AAAA"
                options={{
                  altInput: true,
                  altFormat: "d/m/Y",
                }}
                value={startDate}
                onChange={([date]) =>
                  setStartDate(
                    date
                  )
                }
              />
            </Col>
            <Col md={6}>
              <Label>Selecione uma data final</Label>
              <Flatpickr
                placeholder="DD/MM/AAAA"
                options={{
                  altInput: true,
                  altFormat: "d/m/Y",
                }}
                value={endDate}
                onChange={([date]) =>
                  setEndDate(
                    date
                  )
                }
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Switch
              textLabel="Precisa de cupom: "
              setChecked={(needCoupon) => {
                setNeedCoupon(needCoupon);
                needCoupon ? createRandomStringForCoupun(needCoupon)
                  : setGeneratedCode("")

              }}
              checked={needCoupon}
            />
          </Row>

          <Row className="mb-3 d-flex justify-content-between align-items-end">
            <div className="col-md-9">
              <AvField
                type="text"
                name="generatedCode"
                label="Código do cupom"
                disabled={!needCoupon}
                value={generatedCode}
                maxlength={maxLengthCoupunCode}
                onChange={(e) => setGeneratedCode(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <button
                type="button"
                disabled={!needCoupon}
                className="btn btn-success w-100"
                onClick={() => createRandomStringForCoupun(needCoupon)}
              >
                Gerar Código
              </button>
            </div>
          </Row>

          <Row>
            <Col>
              <AvField
                type="select"
                name="company"
                label="Selecione uma empresa"
                onChange={(e) => setCompany(e.target.value)}
                value={company == "01" ? "Adias Ar Condicionado" : "A.Dias Solar"}
              >
                {!isSolar ? (
                  <option value="01">Adias Ar Condicionado</option>
                ) : (
                  <option value="02">A.Dias Solar</option>
                )}
              </AvField>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default PromotionDetails;