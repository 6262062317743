import { call, put, takeEvery, all, fork, select, apply } from "redux-saga/effects";
// Crypto Redux States
import { OrderActionTypes } from "./actionTypes";
import { orderApiSuccess, orderApiFail } from "./action";
import { toast } from "react-toastify";

//Include Both Helper File with needed methods
import {
  getOrder as getOrderApi,
  getOrderReload as getOrderReloadApi,
  getDocuments as getDocumentsApi,
  getPayments as getPaymentsApi,
  getGfe as getGfeApi,
  getSlips as getSlipsApi,
  getProducts as getProductsApi,
  getNewOrder as getNewOrderApi,
  getFilters as getFiltersApi,
  getCategory as getGategoryApi,
  getCapacidade as getCapacidadeApi,
  getPagto as getPagtoApi,
  getOrderProducts as getOrderProductsApi,
  getProductDetails as getProductDetailsApi,
  getFrete as getFreteApi,
  getCustomer as getCustomerApi,
  getSaldo as getSaldoApi,
  emitirPedido as emitirPedidoApi,
  editarPedido as editarPedidoApi,
  getCuponsNotUsed as getCuponsNotUsedApi,
  verificarCupom as verificarCupomApi,
  checkCartRule as checkCartRuleApi,
  cancelOrder as cancelOrderApi,
  getArquitetos as getArquitetosApi,
  addArquiteto as addArquitetoApi,
  getProductsBySku as getProductsBySkuApi,
  verifyEmailExists as verifyEmailExistsApi,
  getCouponBycode as getCouponBycodeApi
} from "../../api/index";
import { useSolar } from "../../hooks";
import { getDiscountValue, isShippingPromotion } from "../../helpers/cartRuleHelper";

function* verifyEmailExists({ payload: data }) {
  try {
    const response = yield call(verifyEmailExistsApi, data);
    if (response) {
      yield put(
        orderApiSuccess(OrderActionTypes.VERIFY_EMAIL_EXISTS, response)
      );
    } else {
      toast.error(response.messages[0].error, { theme: "colored" });
      yield put(orderApiFail(OrderActionTypes.VERIFY_EMAIL_EXISTS));
    }
  } catch (error) {
    //toast.error(error, { theme: "colored" });
    yield put(orderApiFail(OrderActionTypes.VERIFY_EMAIL_EXISTS, error));
  }
}

function* getOrder({ payload: { orderNum, company, filial } }) {
  try {
    const response = yield call(getOrderApi, { orderNum, company, filial });
    if (response.order) {
      yield put(orderApiSuccess(OrderActionTypes.GET_ORDER_ACTIVE, response));
    } else {
      toast.error(response.messages[0].error, { theme: "colored" });
      yield put(orderApiFail(OrderActionTypes.GET_ORDER_ACTIVE));
    }
  } catch (error) {
    toast.error(`Não foi possível carrregar seu pedido. Tente novamente!`, {
      theme: "colored",
    });
    yield put(orderApiFail(OrderActionTypes.GET_ORDER_ACTIVE, error));
  }
}

function* getDocuments() {
  try {
    const order = state => state.Order.orderActive.order;
    let orderActive = yield select(order);
    let paramns = {
      orderNum: orderActive.NumPedido,
      cpfCnpj: orderActive.CpfCnpjCliente,
    };
    const response = yield call(getDocumentsApi, paramns);
    yield put(orderApiSuccess(OrderActionTypes.GET_DOCUMENTS, response));
  } catch (error) {
    yield put(orderApiFail(OrderActionTypes.GET_DOCUMENTS, error));
  }
}

function* getPayments() {
  try {
    const order = state => state.Order.orderActive.order;
    let orderActive = yield select(order);
    let paramns = {
      orderNum: orderActive.NumPedido,
      cpfCnpj: orderActive.CpfCnpjCliente,
    };
    const response = yield call(getPaymentsApi, paramns);
    yield put(orderApiSuccess(OrderActionTypes.GET_PAYMENT, response));
  } catch (error) {
    yield put(orderApiFail(OrderActionTypes.GET_PAYMENT, error));
  }
}

function* getGfe() {
  try {
    const order = state => state.Order.orderActive.order;
    let orderActive = yield select(order);
    let paramns = {
      orderNum: orderActive.NumPedido,
      cpfCnpj: orderActive.CpfCnpjCliente,
      Orcamento: orderActive.Orcamento,
    };
    const response = yield call(getGfeApi, paramns);
    yield put(orderApiSuccess(OrderActionTypes.GET_GFE, response));
  } catch (error) {
    yield put(orderApiFail(OrderActionTypes.GET_GFE, error));
  }
}

function* getSlips() {
  try {
    const isSolar = useSolar();

    const order = state => state.Order.orderActive.order;

    let orderActive = yield select(order);

    let params = {
      numNota: orderActive.Nota,
      empresa: isSolar ? '02' : '01'
    };

    const response = yield call(getSlipsApi, params);

    yield put(orderApiSuccess(OrderActionTypes.GET_SLIPS, response));

    // if (response.message) {
    //   toast.error(response.message, { theme: "colored" });
    // }
  } catch (error) {
    toast.error(error, { theme: "colored" });

    yield put(orderApiFail(OrderActionTypes.GET_SLIPS, error));
  }
}

function* getProducts() {
  try {
    const order = state => state.Order.orderActive.order;
    let orderActive = yield select(order);
    let paramns = {
      code: orderActive.Cliente,
    };
    const response = yield call(getProductsApi, paramns);
    yield put(orderApiSuccess(OrderActionTypes.GET_PRODUCTS, response));
  } catch (error) {
    ////toast.error(error, { theme: "colored" });
    yield put(orderApiFail(OrderActionTypes.GET_PRODUCTS, error));
  }
}

function* getNewOrder({ payload: data }) {
  const isSolar = useSolar();

  try {
    const response = yield call(getNewOrderApi, data.data);
    yield put(orderApiSuccess(OrderActionTypes.GET_NEW_ORDER, response));

    if (response.message) {
      toast.error(response.message, { theme: "colored" });
    }
    if (response.messages) {
      toast.error(response.messages[0].error, { theme: "colored" });
    }

    if (response.msg) {
      toast.error(response.msg, { theme: "colored" });
      if (isSolar) {
        data.history.push("/solar/");
      } else {
        data.history.push("/dashboard");
      }
    }
  } catch (error) {
    toast.error(error, { theme: "colored" });

    if (isSolar) {
      data.history.push("/solar/");
    } else {
      data.history.push("/dashboard");
    }
    yield put(orderApiFail(OrderActionTypes.GET_NEW_ORDER, error));
  }
}

function* getFilters({ payload: filial }) {
  try {
    const response = yield call(getFiltersApi, filial);
    
    yield put(orderApiSuccess(OrderActionTypes.GET_FILTERS, response));

    if (response.message) {
      toast.error(response.message, { theme: "colored" });
    }

  } catch (error) {
    toast.error(error, { theme: "colored" });

    yield put(orderApiFail(OrderActionTypes.GET_FILTERS, error));
  }
}

function* changeGroup({ payload: group }) {
  try {
    const paramns = {
      group: group,
      category: "",
    };

    let catergories = yield call(getGategoryApi, paramns);
    let cat = [];
    Object.keys(catergories || {}).map(function (key) {
      cat.push({ CODIGO: key, DESCR: catergories[key] });
    });

    const response = {
      category: cat,
    };

    yield put(orderApiSuccess(OrderActionTypes.CHANGE_GROUP, response));
  } catch (error) {
    ////toast.error(error, { theme: "colored" });
    yield put(orderApiFail(OrderActionTypes.CHANGE_GROUP, error));
  }
}

function* changeCategoty({ payload: category }) {
  try {
    let capacidade = yield call(getCapacidadeApi, category);
    let cap = [];
    Object.keys(capacidade || {}).map(function (key) {
      cap.push({ CODIGO: key, DESCR: capacidade[key] });
    });

    const response = {
      capacidade: cap,
    };

    yield put(orderApiSuccess(OrderActionTypes.CHANGE_CATEGORY, response));
  } catch (error) {
    ////toast.error(error, { theme: "colored" });
    yield put(orderApiFail(OrderActionTypes.CHANGE_CATEGORY, error));
  }
}

function* changePagto({ payload: tabela }) {
  try {
    const paramns = {
      tabela: tabela,
    };
    let pagamento = yield call(getPagtoApi, paramns);
    let pagto = {};

    if (pagamento.length != 0) {
      pagamento.map(value => {
        if (value.TPLIB == "S") {
          pagto["Pagamento offline / a prazo"] = {
            ...pagto["Pagamento offline / a prazo"],
            [value.CODPAG]: {
              name: value.DESCRPAG,
            },
          };
        } else {
          pagto["Pagamento online"] = {
            ...pagto["Pagamento online"],
            [value.CODPAG]: {
              name: value.DESCRPAG,
            },
          };
        }
      });
    } else {
      pagto = {};
    }

    yield put(orderApiSuccess(OrderActionTypes.CHANGE_PAGTO, pagto));
  } catch (error) {
    ////toast.error(error, { theme: "colored" });
    yield put(orderApiFail(OrderActionTypes.CHANGE_PAGTO, error));
  }
}
var limitErrorReq = 1;
function* getOrderProducts({ payload: filter }) {
  var maxLimitErrorReq = 7;

  try {
    // const paramns = {
    //   consulta: filter
    // }
    const response = yield call(getOrderProductsApi, filter);

    yield put(orderApiSuccess(OrderActionTypes.GET_ORDER_PRODUCTS, response));
  } catch (error) {
    if (Number(limitErrorReq) == Number(maxLimitErrorReq)) {
      toast.error(error, { theme: "colored" });
      yield put(orderApiFail(OrderActionTypes.GET_ORDER_PRODUCTS, error));
      limitErrorReq = 1;
      return;
    }
    limitErrorReq++;
    yield getOrderProducts({ payload: filter });
  }
}

function* getProductDetails({ payload: sku }) {
  try {
    const response = yield call(getProductDetailsApi, sku);
    const paramns = {
      sku: sku,
      response: response,
    };
    yield put(orderApiSuccess(OrderActionTypes.GET_PRODUCT_DETAILS, paramns));
  } catch (error) {
    //toast.error(error, { theme: "colored" });
    yield put(orderApiFail(OrderActionTypes.GET_PRODUCT_DETAILS, error));
  }
}

function removeArraysVazios(array) {
  return array.filter(function (item) {
    return Array.isArray(item) && item.length > 0;
  });
}

function countItems(arr) {
  const countMap = Object.create(null);

  for (const element of arr) {
    if (!countMap[element]) {
      // Se ainda não existir elemento, definimos como um, já que
      // estamos na primeira ocorrência.
      countMap[element] = 1;
    } else {
      // Caso contrário, incrementamos um no número atual.
      countMap[element] += 1;
    }
  }

  return countMap;
}

var indexNewAddItems = 0;

var arrayResponse = [];
function* getFreteValue({ payload }) {
  const isSolar = useSolar();
  try {
    const frete = state => state.Order.newOrder.newOrder.frete;
    let endereco = yield select(frete);
    //quantidade de filiais existentes no carrinho
    var limitIndexNewAddItems =
      payload?.newAddItemsSeparadoPorFilial?.length - 1;

    const params = {
      zipcode: endereco.address.zipcode,
      valTot: isSolar
        ? payload.valor
        : payload.newAddItemsSeparadoPorFilial[indexNewAddItems].valTotal,
      company: payload.company,
      filial: isSolar
        ? payload.filial
        : payload.newAddItemsSeparadoPorFilial[indexNewAddItems].CODFILIAL,
      date: payload?.date,
      cancelRequestDuplicate: payload?.cancelRequestDuplicate,
    };

    const discountsState = state => state.Order.newOrder.newOrder.discounts;
    const discounts = yield select(discountsState);
    const response = yield call(getFreteApi, params);

    if (discounts) {
      const shippingsPromotions = discounts.filter(discount => isShippingPromotion(discount.applyToShipping));
      response.shippingMethods.map(shipping => {
        if (shippingsPromotions.length > 0) {
          shippingsPromotions.forEach(discount => {
            shipping.promotionDiscount = Number(getDiscountValue(shipping.price, discount.valueDiscount, discount.typeRuleDiscount)).toFixed(2);
          })
        } else {
          shipping.promotionDiscount = 0;
        }
      });
    }

    //verifica se existe mais de um produto com filias diferentes no carrinho
    if (payload?.newAddItemsSeparadoPorFilial?.length > 1) {

      arrayResponse.push(response);
      //verifica se ja foi executada a função de getFreteValue para o mesmo tanto de filiais existem dentro do carrinho
      if (indexNewAddItems != limitIndexNewAddItems) {
        params.newAddItemsSeparadoPorFilial =
          payload.newAddItemsSeparadoPorFilial;
        indexNewAddItems++;

        yield getFreteValue({ payload: payload });
      } else {
        indexNewAddItems = 0;

        var TypesShipping = ["R", "N", "P"];
        var arrayShipping = [];
        var typesInvalides = [];

        // pega os tipos de entrega
        arrayResponse.map(itemPai => {
          itemPai.shippingMethods.map(itemFilho => {
            typesInvalides.push(itemFilho.id);
          });
        });

        //cria um objeto com o nome da chave sendo o tipo de entrega e o valor é quantas vezes esse tipo de chave aparece repetidamente no array
        var itemsEqualLenght = countItems(typesInvalides);

        //filtra os tipos de entrega que não tem em todas as requisiçoes
        const filterInvalidType = Object.keys(itemsEqualLenght).filter(
          item => itemsEqualLenght[item] != arrayResponse.length
        );

        //percorre e filtra o array considerando os tipos inválidos
        arrayResponse.forEach(itemPai => {
          const filtered = itemPai.shippingMethods.filter(itemFilho => !filterInvalidType.includes(itemFilho.id));
          itemPai.shippingMethods = filtered;
        });

        //pega o array de response e junta os objetos com o mesmo TypesShipping dentro do array
        for (var obj in TypesShipping) {
          const objetoShipping = arrayResponse
            .filter(item =>
              item.shippingMethods.some(
                itemSome => itemSome.id === TypesShipping[obj]
              )
            ) // filtra os ids que correspodam ao array de  TypesShipping
            .flatMap(item =>
              item.shippingMethods.filter(
                itemFilter => itemFilter.id === TypesShipping[obj]
              )
            ); // extrai todos os objetos do id correspondete  e retorna um array plano

          arrayShipping.push(objetoShipping);
        }
        var arraySemVazios = removeArraysVazios(arrayShipping);

        var arrayShippingMaxValue = [];

        //pegar os objetos de frete que tenha maior prazo de entrega de cada TypesShipping
        for (var obj in arraySemVazios) {
          const maxValueNormalDeliveryDelay = arraySemVazios[obj].reduce(
            function (prev, current) {
              return prev.delivery_delay > current.delivery_delay
                ? prev
                : current;
            }
          );

          //somar os objetos de frete para retornar o valor total do frete de cada TypesShipping
          let soma = arraySemVazios[obj].reduce(function (acumulador, objeto) {
            return acumulador + objeto.price;
          }, 0);

          maxValueNormalDeliveryDelay.priceSomado = soma;

          arrayShippingMaxValue.push(maxValueNormalDeliveryDelay);
        }

        var objDelivery = {
          shippingMethods: arrayShippingMaxValue,
          arrayShipping: arraySemVazios,
        };
        arrayResponse = [];

        yield put(
          orderApiSuccess(OrderActionTypes.GET_FRETE_VALUE, objDelivery)
        );
      }
    } else {
      yield put(orderApiSuccess(OrderActionTypes.GET_FRETE_VALUE, response));
    }
  } catch (error) {
    if (!String(error)?.includes("shippingMethods")) {
      toast.error("Serviço de cálculo de frete indisponível no momento", {
        theme: "colored",
      });
    }
    if (error?.message == "Cancelando solicitação anterior") {
      return;
    }

    yield put(orderApiFail(OrderActionTypes.GET_FRETE_VALUE, error));
  }
}

function* getCostumer({ payload: valor }) {
  try {
    const response = yield call(getCustomerApi, valor);
    yield put(orderApiSuccess(OrderActionTypes.GET_CUSTOMER, response));
  } catch (error) {
    //toast.error(error, { theme: "colored" });
    yield put(orderApiFail(OrderActionTypes.GET_CUSTOMER, error));
  }
}

function* getSaldo() {
  try {
    const response = yield call(getSaldoApi);
    yield put(orderApiSuccess(OrderActionTypes.GET_SALDO, response));
  } catch (error) {
    //toast.error(error, { theme: "colored" });
    yield put(orderApiFail(OrderActionTypes.GET_SALDO, error));
  }
}
var countPedido = 1;

function* checkCartRuleOnChange({ payload: pedido }) {
  try {
    const getCartItems = state => state.Order.newOrder.newOrder.addedItems;
    const getShippingOptions = state => state.Order.newOrder.newOrder.frete.entrega;

    let cartItems = yield select(getCartItems);
    let shippings = yield select(getShippingOptions);
    const cartRule = yield call(checkCartRuleApi, pedido);

    const { orderWithDiscounts, itemsWithDiscounts } = applyDiscountsInOrder(cartRule.valid_rules, cartItems, shippings, pedido);

    yield put(orderApiSuccess(OrderActionTypes.CART_RULE_UPDATE, { pedido: orderWithDiscounts, cartItems: itemsWithDiscounts }));
  } catch (error) {
    toast.error(error, { theme: "colored" });

    yield put(orderApiFail(OrderActionTypes.CART_RULE_UPDATE, error));
  }
}

const applyDiscountsInOrder = (discounts = [], cartItems = [], shippingOptions = [], pedido) => {
  cartItems.forEach(item => (item.promotionDiscount = 0));

  Object.assign(pedido, {
    subTotalDiscount: 0,
    fullDiscount: 0,
    shipping: { ...pedido.shipping, discount: 0 },
    discounts: []
  });

  let subTotalDiscount = 0;
  let fullDiscount = 0;

  applyShippingPromotions(shippingOptions, discounts);

  discounts.forEach(discount => {
    if (discount.applyToShipping === 0) {
      const { products, typeRuleDiscount, valueDiscount } = discount;

      const calculateItemDiscount = (item, percentage) => {
        const value = typeRuleDiscount === 1
          ? (item.produto.valorTabela * percentage) / 100
          : (valueDiscount);

        const quantityDiscount = value;

        return quantityDiscount;
      };

      if (products.length > 0) {
        products.forEach(product => {
          cartItems.forEach(item => {
            if (item.produto.CODPRODUTO === product.sku) {
              const quantityDiscount = calculateItemDiscount(item, valueDiscount);
              item.promotionDiscount = quantityDiscount;
              const itemPedido = pedido.items.find(p => p.CODPRODUTO === item.produto.sku);
              fullDiscount += quantityDiscount * (itemPedido?.AMOUNT || 0);
            }
          });
        });
      } else {
        const percentageDiscount = typeRuleDiscount === 1
          ? valueDiscount
          : (valueDiscount / pedido.itemsValTot) * 100;

        cartItems.forEach(item => {
          const quantityDiscount = calculateItemDiscount(item, percentageDiscount);
          item.promotionDiscount += quantityDiscount;
          subTotalDiscount += quantityDiscount;
          fullDiscount += quantityDiscount;
        });
      }
    }
  });

  Object.assign(pedido, {
    sub_total_discount: subTotalDiscount,
    full_discount: fullDiscount,
    discounts
  });

  return { orderWithDiscounts: pedido, itemsWithDiscounts: cartItems };
}

const applyShippingPromotions = (shippings, discounts) => {
  if (shippings && discounts) {
    const shippingsPromotions = discounts.filter(discount => isShippingPromotion(discount.applyToShipping));
    shippings.map(shipping => {
      if (shippingsPromotions.length > 0) {
        shippingsPromotions.forEach(discount => {
          shipping.promotionDiscount = Number(getDiscountValue(shipping.price, discount.valueDiscount, discount.typeRuleDiscount)).toFixed(2);
        })
      } else {
        shipping.promotionDiscount = 0;
      }
    });
  }
}

function* emitirPedido({ payload: pedido }) {
  try {
    var countRequestPedido = pedido?.countRequest ? pedido?.countRequest : 1;

    const response = yield call(emitirPedidoApi, pedido);

    if (countPedido == countRequestPedido) {
      countPedido = 1;
      if (response.MSG) {
        toast.info(response.MSG, { theme: "colored" });
      }

      if (response?.message) {
        toast.info(response.message, { theme: "colored" });
      }

      if (response.msg) {
        if (
          response.msg.includes(
            "Error ao validar frete, digite o CEP de entrega novamente ou entre em contato com o Suporte."
          )
        ) {
          toast.info("Selecione o frete e clique para emitir novamente!", {
            theme: "colored",
          });
        } else {
          toast.info(response.msg, { theme: "colored" });
        }
      }

      if (response?.erro) {
        toast.info(response.error, { theme: "colored" });
      }

      if (response?.erro) {
        toast.info(response.error);
      }

      if (response?.error_msg) {
        toast.error(response.error_msg, { theme: "colored" });
      }

      if (!response.success) {
        yield put(orderApiFail(OrderActionTypes.EMITIR_PEDIDO, response.data));
      }

      yield put(orderApiSuccess(OrderActionTypes.EMITIR_PEDIDO, response));
    } else {
      countPedido++;
    }
  } catch (error) {
    toast.warning("Erro ao emitir pedido " + error, {
      position: "top-right",
      autoClose: 60000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    yield put(orderApiFail(OrderActionTypes.EMITIR_PEDIDO, error));
  }
}

const valueCart = (pedido, filteredPercent, discountrule) => {
  var prcvenda = "";
  var totalPercent = 0;

  const filteredTypeRulesValueCartPercent = (filteredPercent || []).filter(
    item => item?.type == "Value Cart"
  );

  const total = pedido.itemsValTot;

  if (filteredTypeRulesValueCartPercent?.length > 0) {
    totalPercent = filteredTypeRulesValueCartPercent.reduce(
      (acumulador, valor) => acumulador + parseFloat(valor.valuediscount),
      0
    );

    return (
      (parseFloat(total) / 100) * Number(totalPercent) +
      parseFloat(discountrule)
    );
  } else {
    return 0 + discountrule;
  }
};

const valueShipping = (pedido, filteredPercent, discountrule) => {
  var totalPercent = 0;

  const filteredTypeRulesValueShippingPercent = (filteredPercent || []).filter(
    item => item?.type == "Value Shipping"
  );

  if (filteredTypeRulesValueShippingPercent?.length > 0) {
    const shippingValue = pedido.shipping.price;
    totalPercent = filteredTypeRulesValueShippingPercent.reduce(
      (acumulador, valor) => acumulador + parseFloat(valor.valuediscount),
      0
    );

    return (
      (parseFloat(shippingValue) / 100) * Number(totalPercent) +
      parseFloat(discountrule)
    );
  } else {
    return discountrule;
  }
};

function* editarPedido({ payload: pedido }) {
  try {
    const cartRule = yield call(checkCartRuleApi, pedido.pedido);
    let discountrule = 0;

    if (cartRule.valid_rules) {
      (cartRule.valid_rules || []).map(value => {
        discountrule = Number(discountrule) + Number(value.valuediscount);
      });

      if (discountrule > 0) {
        toast.success(
          `Você ganhou ${discountrule * 100} % de desconto em seu pedido!`,
          { theme: "colored" }
        );
        pedido.pedido = {
          ...pedido.pedido,
          discountrule,
          discountValue: cartRule,
        };
      }
    }

    const response = yield call(editarPedidoApi, pedido);
    yield put(orderApiSuccess(OrderActionTypes.EMITIR_PEDIDO, response));
  } catch (error) {
    //toast.error(error);
    yield put(orderApiFail(OrderActionTypes.EMITIR_PEDIDO, error));
  }
}

function* getItemTable({ payload: item }) {
  try {
    const response = yield call(getProductDetailsApi, item);
    const paramns = {
      sku: item,
      response: response,
    };
    yield put(orderApiSuccess(OrderActionTypes.GET_ITEM_TABLE, paramns));
  } catch (error) {
    //toast.error(error);
    yield put(orderApiFail(OrderActionTypes.GET_ITEM_TABLE, error));
  }
}

function* getCuponsNotUsed() {
  try {
    const response = yield call(getCuponsNotUsedApi);
    yield put(orderApiSuccess(OrderActionTypes.GET_CUPONS_NOT_USED, response));
  } catch (error) {
    //toast.error(error);
    yield put(orderApiFail(OrderActionTypes.GET_CUPONS_NOT_USED, error));
  }
}

function* verificarCupom({ payload: cupom }) {
  try {
    const response = yield call(verificarCupomApi, cupom);
    yield put(orderApiSuccess(OrderActionTypes.VERIFICAR_CUPOM, response));
  } catch (error) {
    //toast.error(error);
    yield put(orderApiFail(OrderActionTypes.VERIFICAR_CUPOM, error));
  }
}

function* getCouponBycode({ payload: { orderDetails, couponCode } }) {
  try {
    const deliveryAddress = orderDetails.client?.customer_address[1] ?? orderDetails.client?.customer_address[0];
    const bodyRequestCoupons = {
      company: orderDetails?.CODEMPRESA,
      branch: orderDetails?.CODFILIAL,
      payment_form: orderDetails?.filters?.condpagto,
      price_table: orderDetails?.filters?.tabela_preco,
      items: orderDetails?.items?.map(item => ({
        capacity: item.CAPACIDADE,
        sku: item.CODPRODUTO,
        group: item.GRUPO,
        category: item.CATEGORIA,
        brand: item.MARCA,
        unitary_value: item.PRCRT,
        quantity: item.AMOUNT,
      })),
      city: deliveryAddress.city,
      uf: deliveryAddress.state,
      partnerSeller: orderDetails?.filters?.vendedor2
    }
    const coupon = yield call(getCouponBycodeApi, { orderDetails: bodyRequestCoupons, couponCode });

    const getCartItems = state => state.Order.newOrder.newOrder.addedItems;
    const getShippingOptions = state => state.Order.newOrder.newOrder.frete.entrega;
    const getDiscounts = state => state.Order.newOrder.newOrder.discounts;

    const cartItems = yield select(getCartItems);
    const shippings = yield select(getShippingOptions);
    let discounts = yield select(getDiscounts);
    discounts = [...discounts, coupon];
    const { orderWithDiscounts, itemsWithDiscounts } = applyDiscountsInOrder(discounts, cartItems, shippings, orderDetails);
    yield put(orderApiSuccess(OrderActionTypes.CART_RULE_UPDATE, {
      pedido: orderWithDiscounts,
      cartItems: itemsWithDiscounts
    }));
  } catch (error) {
    toast.error(error);
    yield put(orderApiFail(OrderActionTypes.CART_RULE_UPDATE, error));
  }
}


function* cancelOrder({ payload: order }) {
  try {
    // toast.success("value");
    const response = yield call(cancelOrderApi, order);

    if (response?.success?.length > 0 || response?.success) {
      toast.success(response?.msg, { theme: "colored" });

      const succ = yield call(getOrderReloadApi, order);
      yield put(orderApiSuccess(OrderActionTypes.GET_ORDER_ACTIVE, succ));
    } else {
      toast.error(response?.msg, { theme: "colored" });
      yield put(orderApiSuccess(OrderActionTypes.CANCEL_ORDER));
    }
  } catch (error) {
    yield put(orderApiFail(OrderActionTypes.CANCEL_ORDER, error));
  }
}

function* getArquitetos() {
  try {
    const response = yield call(getArquitetosApi);
    yield put(orderApiSuccess(OrderActionTypes.GET_ARQUITETOS, response));
  } catch (error) {
    //toast.error(error);
    yield put(orderApiFail(OrderActionTypes.GET_ARQUITETOS, error));
  }
}

function* addArquiteto({ payload: data }) {
  try {
    // let d = new Date;
    const paramns = {
      nome: data.nome,
      default_bank: "",
      rg: "",
      sobrenome: "",
      telefone: "",
      razao_social: "",
      nome_fantasia: "",
      celular: "",
      inscricao_estadual: "",
      inscricao_municipal: "",
      person_type: "",
      cpf: "",
      observacao: "",
    };

    const response = yield call(addArquitetoApi, paramns);
    if (response.success) {
      const data = yield call(getArquitetosApi);
      yield put(orderApiSuccess(OrderActionTypes.GET_ARQUITETOS, data));
    }
  } catch (error) {
    //toast.error(error);
    yield put(orderApiFail(OrderActionTypes.ADD_ARQUITETO, error));
  }
}

function* atualizaCarrinho({ payload: data }) {
  data.pgto.setAwaitModfyCart("close");

  try {
    const carr = state => state.Order.newOrder.newOrder.addedItems;
    let carrinho = yield select(carr);
    const tabela = data.pgto.tabela;
    const pagamento = data.pgto.pgto;
    const skus = [];
    const carrinhoAtualizado = [];
    const isSolar = useSolar();

    if (carrinho.length > 0 && tabela.length > 0 && pagamento.length > 0) {
      carrinho.map(value => {
        skus.push(value.cod);
      });

      let dataObj = {
        sku: skus.join(),
        tabela: tabela[0].codigo,
        pagto: pagamento[0].codigo,
        isSolar: isSolar,
      };

      let response = yield call(getProductsBySkuApi, dataObj);

      if (response.success) {
        for (var obj in carrinho) {
          for (var obj2 in response.produtos) {
            if (carrinho[obj].cod == response.produtos[obj2].CODPRODUTO) {
              (data.pedido.items[obj2].PRCRT =
                response.produtos[obj2].PRCVENDA.STRETPRCFILADIAS[
                  carrinho[obj].filial
                ].PRCVENDA * data.pedido.items[obj2].AMOUNT),
                carrinhoAtualizado.push({
                  cod: response.produtos[obj2].CODPRODUTO,
                  produto: {
                    ...response.produtos[obj2],
                    CODFILIAL: carrinho[obj].produto.CODFILIAL,
                    valorTabela: response.produtos[obj2].PRCVENDA.STRETPRCFILADIAS[
                      carrinho[obj].filial
                    ].PRCVENDA,
                    sku: response.produtos[obj2].CODPRODUTO,
                  },
                  precoVenda:
                    response.produtos[obj2].PRCVENDA.STRETPRCFILADIAS[
                      carrinho[obj].filial
                    ].PRCVENDA,
                  filial: carrinho[obj].filial,
                });
            }
          }
        }

        yield put(
          orderApiSuccess(
            OrderActionTypes.ATUALIZA_CARRINHO,
            carrinhoAtualizado
          )
        );
        setTimeout(() => {
          data.pgto.setAwaitModfyCart("open");
        }, 3000);
      }
    }
  } catch (error) {
    console.log(`error`, error);
    //toast.error(error);
    data.pgto.setAwaitModfyCart("open");
    yield put(orderApiFail(OrderActionTypes.ATUALIZA_CARRINHO, error));
  }
}

function* addProductBySku({ payload: product }) {
  try {
    let response = yield call(getProductsBySkuApi, product);
    if (response.success) {
      let data = {
        cod: product.sku,
        valorTabela: product.valorTabela,
        produto: {
          ...response.produtos[0],
          ITEM: product.item,
        },
        filial: product.filial,
        precoVenda: product.precoVenda,
        quant: product.quant,
      };
      yield put(orderApiSuccess(OrderActionTypes.ADD_PRODUCT_BY_SKU, data));
    }
  } catch (error) {
    //toast.error(error);
    yield put(orderApiFail(OrderActionTypes.ADD_PRODUCT_BY_SKU, error));
  }
}

function* removePromotionsAndCoupons({ payload: { idRule = false, orderDetails = {} } }) {
  try {
    
    const getDiscounts = state => state.Order.newOrder.newOrder.discounts;
    let discounts = yield select(getDiscounts);

    let removeDiscountList = [];

    if (idRule) {
      removeDiscountList = discounts.filter(item => item.id == idRule);
    }

    const getCartItems = state => state.Order.newOrder.newOrder.addedItems;
    const getShippingOptions = state => state.Order.newOrder.newOrder.frete.entrega;

    const cartItems = yield select(getCartItems);
    const shippings = yield select(getShippingOptions);

    const { orderWithDiscounts, itemsWithDiscounts } = applyDiscountsInOrder(removeDiscountList, cartItems, shippings, orderDetails);
    yield put(orderApiSuccess(OrderActionTypes.CART_RULE_UPDATE, {
      pedido: orderWithDiscounts,
      cartItems: itemsWithDiscounts
    }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetOrder() {
  yield takeEvery(OrderActionTypes.GET_ORDER_ACTIVE, getOrder);
}

export function* watchVerifyEmailExists() {
  yield takeEvery(OrderActionTypes.VERIFY_EMAIL_EXISTS, verifyEmailExists);
}

export function* watchGetDocuments() {
  yield takeEvery(OrderActionTypes.GET_DOCUMENTS, getDocuments);
}

export function* watchGetPayments() {
  yield takeEvery(OrderActionTypes.GET_PAYMENT, getPayments);
}

export function* watchGetGfe() {
  yield takeEvery(OrderActionTypes.GET_GFE, getGfe);
}

export function* watchGetSlips() {
  yield takeEvery(OrderActionTypes.GET_SLIPS, getSlips);
}

export function* watchGetProducts() {
  yield takeEvery(OrderActionTypes.GET_PRODUCTS, getProducts);
}

export function* watchGetNewOrder() {
  yield takeEvery(OrderActionTypes.GET_NEW_ORDER, getNewOrder);
}

export function* watchGetFilters() {
  yield takeEvery(OrderActionTypes.GET_FILTERS, getFilters);
}

export function* watchGetCouponByCode() {
  yield takeEvery(OrderActionTypes.GET_COUPON_BY_CODE, getCouponBycode);
}

export function* watchChangeGroup() {
  yield takeEvery(OrderActionTypes.CHANGE_GROUP, changeGroup);
}

export function* watchChangeCategory() {
  yield takeEvery(OrderActionTypes.CHANGE_CATEGORY, changeCategoty);
}

export function* watchChangePagto() {
  yield takeEvery(OrderActionTypes.CHANGE_PAGTO, changePagto);
}

export function* watchGetOrderProducts() {
  yield takeEvery(OrderActionTypes.GET_ORDER_PRODUCTS, getOrderProducts);
}

export function* watchGetProductDetails() {
  yield takeEvery(OrderActionTypes.GET_PRODUCT_DETAILS, getProductDetails);
}

export function* watchGetFreteValue() {
  yield takeEvery(OrderActionTypes.GET_FRETE_VALUE, getFreteValue);
}

export function* watchGetCostumer() {
  yield takeEvery(OrderActionTypes.GET_CUSTOMER, getCostumer);
}

export function* watchGetSaldo() {
  yield takeEvery(OrderActionTypes.GET_SALDO, getSaldo);
}

export function* watchEmitirPedido() {
  yield takeEvery(OrderActionTypes.EMITIR_PEDIDO, emitirPedido);
}

export function* watchEditarPedido() {
  yield takeEvery(OrderActionTypes.EDITAR_PEDIDO, editarPedido);
}

export function* watchGetItemTable() {
  yield takeEvery(OrderActionTypes.GET_ITEM_TABLE, getItemTable);
}

export function* watchGetCuponsNotUsed() {
  yield takeEvery(OrderActionTypes.GET_CUPONS_NOT_USED, getCuponsNotUsed);
}

export function* watchVerificarCupom() {
  yield takeEvery(OrderActionTypes.VERIFICAR_CUPOM, verificarCupom);
}

export function* watchCancelOrder() {
  yield takeEvery(OrderActionTypes.CANCEL_ORDER, cancelOrder);
}

export function* watchGetArquitetos() {
  yield takeEvery(OrderActionTypes.GET_ARQUITETOS, getArquitetos);
}

export function* watchAddArquiteto() {
  yield takeEvery(OrderActionTypes.ADD_ARQUITETO, addArquiteto);
}

export function* watchAtualizaCarrinho() {
  yield takeEvery(OrderActionTypes.ATUALIZA_CARRINHO, atualizaCarrinho);
}

export function* watchAddProductBySku() {
  yield takeEvery(OrderActionTypes.ADD_PRODUCT_BY_SKU, addProductBySku);
}

export function* watchCheckCartRuleOnChange() {
  yield takeEvery(OrderActionTypes.CART_RULE_UPDATE, checkCartRuleOnChange);
}

export function* watchRemovePromotionsAndCoupons() {
  yield takeEvery(OrderActionTypes.REMOVE_PROMOTIONS_AND_COUPONS, removePromotionsAndCoupons);
}

function* orderSaga() {
  yield all([
    fork(watchGetOrder),
    fork(watchGetDocuments),
    fork(watchGetPayments),
    fork(watchGetGfe),
    fork(watchGetSlips),
    fork(watchGetProducts),
    fork(watchGetNewOrder),
    fork(watchGetFilters),
    fork(watchChangeGroup),
    fork(watchGetCouponByCode),
    fork(watchChangeCategory),
    fork(watchChangePagto),
    fork(watchGetOrderProducts),
    fork(watchGetProductDetails),
    fork(watchGetFreteValue),
    fork(watchGetCostumer),
    fork(watchGetSaldo),
    fork(watchEmitirPedido),
    fork(watchEditarPedido),
    fork(watchGetItemTable),
    fork(watchCheckCartRuleOnChange),
    fork(watchGetCuponsNotUsed),
    fork(watchVerificarCupom),
    fork(watchCancelOrder),
    fork(watchGetArquitetos),
    fork(watchAddArquiteto),
    fork(watchAtualizaCarrinho),
    fork(watchAddProductBySku),
    fork(watchVerifyEmailExists),
    fork(watchRemovePromotionsAndCoupons),
  ]);
}

export default orderSaga;
