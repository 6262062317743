import React, { useState } from "react";
import { Card, CardBody, Row, Col, Modal, ModalHeader, Button, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatInvertDate, copyToClipboard } from "../../utils";

// components
import SvgIcon from "../../components/SvgIcon";
import SectionTitle from "./SectionTitle";
import iconenf from "../../assets/images/vendedores/NF.png";
import clube from "../../assets/images/selos/fidelidade/adias.png";
import ouro from "../../assets/images/selos/fidelidade/ouro.png";
import prata from "../../assets/images/selos/fidelidade/prata.png";
import bronze from "../../assets/images/selos/fidelidade/bronze.png";
import diamante from "../../assets/images/selos/fidelidade/diamante.png";
import esmeralda from "../../assets/images/category/fidelidade_esmeralda.png";

import { useSolar } from "../../hooks";
import { MagnifyingGlass } from "phosphor-react";
import usePdf from "../Orcamento/hooks/usePdf";
import { pdfDefinition } from "../Orcamento/pdfDefinition";
import { getOrderActive, removeOrderActive } from "../../store/actions";
import EditCustomKit from "../../Solar/views/edit-kit-personalizado";
import EditOrder from "../OrderEdit";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isAllowsChange } from "../../utils/orderUtils";
import { getNotaFiscalDownload } from "../../api";
import { downloadBlob } from "../../helpers/download_helper";
import { toast } from "react-toastify";
// import iconexml from "../../assets/images/vendedores/XML.png"
const Header = ({ isOpen, colapse }) => {
  const { order, fidelidade } = useSelector(state => ({
    order: state.Order.orderActive.order,
    fidelidade: state.Order.orderActive.fidelidadeCategoria,
  }));
  
  let fidelidadeImg = "";

  switch (fidelidade) {
    case 'Ouro':
      fidelidadeImg = ouro;
      break;
    case 'Prata':
      fidelidadeImg = prata;
      break;
    case 'Bronze':
      fidelidadeImg = bronze;
      break;
    case 'Clube':
      fidelidadeImg = clube;
      break;
    case 'Diamante':
      fidelidadeImg = diamante;
      break;
    default:
      fidelidadeImg = '';
      break;
  }
  const shippingCtpoper = ['05', '55'];

  const handleClickNfDownload = async (orderId, cpfCnpj, downloadType) => {
    try {
      const response = await getNotaFiscalDownload({ orderId, cpfCnpj, downloadType });
      downloadBlob(response, `NotaFiscal-${orderId}.${downloadType}`, downloadType);
    } catch (error) {
      toast.error("Erro ao baixar a Nota Fiscal.");
    }
  };

  return (
    <React.Fragment>
      <Row className="d-flex align-items-center">
        <Col md={shippingCtpoper.includes(order?.items_orcamento[0]?.CTPOPER) ? 3 : 4}>
          <p className="texto-order-data" style={{ marginBottom: 0 }}>
            <strong style={{ marginRight: 5 }}>Pagamento: </strong>
            {order?.payment_method?.E4_DESCRI || ""}
          </p>
        </Col>
        {(order?.Nota?.trim() || "") != "" && (
          <Col md={2} className="me-auto">
            <p className="texto-order-data mb-0">
              <span className="fw-bold me-3">NF</span>
              {order?.Nota || ""}
              <button
                onClick={() => copyToClipboard(order?.Nota || "")}
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <SvgIcon
                  name="copy"
                  className="ms-2"
                />
              </button>
            </p>
          </Col>
        )}
        <Col md={2} className="col-auto  d-flex align-items-start">
          <strong
            style={{
              marginRight: 5,
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "auto",
              marginTop: "auto",
            }}
          >
            Download:{" "}
          </strong>
          <a
            onClick={() => handleClickNfDownload(order?.NumPedido, order?.CpfCnpjCliente?.trim(), 'pdf')}
            target="_blank"
            rel="noreferrer"
            className="me-2 img-dados"
          >
            <SvgIcon
              name="file-pdf"
              className="ms-2 icon-md"
            />
          </a>
          <a
            onClick={() => handleClickNfDownload(order?.NumPedido, order?.CpfCnpjCliente?.trim(), 'xml')}
            target="_blank"
            rel="noreferrer"
            className="d-block"
          >
            <img src={iconenf} className="img-pedidos" />
          </a>
        </Col>
        <Col md={3}>
          <strong className="me-1">Fidelidade: </strong><img style={{width: '50px'}} src={fidelidadeImg} />
        </Col>
        {shippingCtpoper.includes(order?.items_orcamento[0]?.CTPOPER) && (
          <Col md={3} className="col-auto d-flex align-items-start">
            <strong
              style={{
                marginRight: 5,
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "auto",
                marginTop: "auto",
              }}
            >
              Nota de remessa:{" "}
            </strong>
            <a
              href={`${order?.notaRemessa}`}
              target="_blank"
              rel="noreferrer"
              className="me-2 img-dados"
              download
            >
              <SvgIcon 
                name="file-pdf"
                className="ms-2 icon-md"
              />
            </a>
          </Col>
        )}
        <Col md={((order?.Nota?.trim() || "") != "") || shippingCtpoper.includes(order?.items_orcamento[0]?.CTPOPER) ? 1 : 3}>
          <a
            href="#"
            style={{
              width: "fit-content",
              marginLeft: "auto",
              display: "block",
            }}
            onClick={event => {
              event.preventDefault();
              colapse();
            }}
          >
            {isOpen ? (
              <i className="fas fa-chevron-down"></i>
            ) : (
              <i className="fas fa-chevron-up"></i>
            )}
          </a>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col md={4}>
          <p className="text-order">
            <strong>Tabela: </strong>
            {order?.tabela?.DA0_DESCRI || ""}
          </p>
        </Col>{" "}
        <Col md={6}>
          <p className="text-order">
            <strong>Emissão: </strong>
            {formatInvertDate(order?.DataEmissao || "")}
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const OrderData = ({ orderId, codFilial }) => {
  const { order, orderActive } = useSelector(state => ({
    order: state.Order.orderActive.order,
    orderActive: state.Order.orderActive,
  }));
  const isSolar = useSolar();
  const [loadingPdf, setLoadingPdf] = useState(false);

  const [isOpen, setIsOpen] = useState(true);
  const colapse = () => {
    setIsOpen(!isOpen);
  };
  const {
    buttonType,
    codPag,
    dataActive,
    detailsProductItem,
    displayChildBudget,
    downloadSemValores,
    filiaisArray,
    id,
    ctpoper,
    totalToShow,
  } = usePdf();

  const origem = {
    1: "Clube ADias",
    2: "Protheus",
    3: "Fbits",
    4: "Shoppub",
    6: "Anymarket",
  };

  const entrega = {
    R: "Rapido",
    S: "Rapido",
    P: "Premium",
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const [modalEditOrderIsOpen, setModalEditOrderIsOpen] = useState(false);

  return (
    <div>
      <div className="order-data-title">
        <SectionTitle
          title="Dados do Pedido"
          action={
            ((order?.Situacao || "A") <= "D" && isAllowsChange(order?.items_orcamento[0]?.CTPOPER)) && (
              <Button
                className="botao-orders"
                onClick={() => {
                  if (isSolar) {
                    return setModalEditOrderIsOpen(true)
                  }
                  dispatch(removeOrderActive());
                  history.push(`/pedidos/${orderId}/${codFilial}/order/edit`);
                }}
              >
                EDITAR PEDIDO
                <SvgIcon
                  name="edit"
                  className="icon icon-xxs icon-outline text-info ms-2"
                />
              </Button>
            )
          }
        />
      </div>
      <Card>
        <CardBody className="cardbody-data">
          {/* header */}
          <div>
            <Header colapse={colapse} isOpen={isOpen} />
          </div>
          <div className="pt-3 divisao" hidden={isOpen}>
            <Row>
              <Col md={4} xs={12}>
                <p className="texto-pedidos-dados">
                  <span className="fw-bold me-3">Vendedor</span>
                  {order?.Vendedor1 || ""} - {orderActive?.Vendedor1 || ""}
                </p>
                <p className="d-flex mb-3 mb-lg-0 texto-pedidos-dados">
                  <span className="fw-bold me-3">
                    {order?.CJ_XPEDMKT?.trim()
                      ? "Pedido Marketplace"
                      : "Orçamento"}
                  </span>
                  {order?.CJ_XPEDMKT?.trim() ? (
                    order.CJ_XPEDMKT
                  ) : (
                    <>
                      <Link
                        to={`${isSolar ? "/solar/" : "/"}orcamento/${order.Orcamento
                          }/${order.C5_FILIAL}`}
                        style={{ color: "#269fec", marginRight: 5 }}
                        target="_blank"
                      >
                        {order.Orcamento}{" "}
                        <MagnifyingGlass
                          size={18}
                          color="#269fec"
                          weight="bold"
                        />
                      </Link>
                      {` - `}
                      <button
                        style={{
                          color: "#269fec",
                          border: "none",
                          fontSize: 18,
                          display: "flex",
                        }}
                        disabled={loadingPdf}
                        onClick={() => {
                          setLoadingPdf(true);
                          pdfDefinition(
                            dataActive,
                            codPag,
                            id,
                            buttonType,
                            detailsProductItem,
                            totalToShow,
                            filiaisArray,
                            downloadSemValores,
                            setLoadingPdf,
                            ctpoper,
                            displayChildBudget
                          );
                        }}
                      >
                        <i className="ph-file-pdf"></i>
                      </button>
                    </>
                  )}
                </p>{" "}
                <p className="mt-3 texto-pedidos-dados">
                  <span className="fw-bold me-3">Entrega</span>
                  {order?.CJ_XTRANSP == "000011"
                    ? "Retira"
                    : "Transportadora" || ""}{" "}
                  -{entrega[order?.CJ_XTRANSP] || " Normal"}
                </p>
              </Col>
              <Col md={4} xs={12}>
                <p className="texto-pedidos-dados">
                  <span className="fw-bold me-3">Origem</span>
                  {origem[order?.C5_XELETRO] || ""}
                </p>
                <p className="mb-3 mb-lg-0">
                  <span className="fw-bold me-3">Pedido Web</span>
                  {order?.CJ_XPEDWEB || ""}
                </p>
              </Col>
              <Col md={4} xs={12}>
                <p className="texto-order-data texto-pedidos-dados">
                  <span className="fw-bold me-3">Parceiro</span>
                  <Link
                    to={`/vendedores/${order?.Vendedor2}`}
                    style={{ color: "#269fec", display: "flex" }}
                    target="_blank"
                  >
                    {order?.Vendedor2 || ""} - {orderActive?.Vendedor2 || ""}{" "}
                    <i className="ph-note-pencil icone-nf"></i>
                  </Link>
                </p>
                <p>
                  <span className="fw-bold me-3">Parceiro 2</span>
                  {order?.Vendedor3 || ""} - {orderActive?.Vendedor3 || ""}
                </p>
                <p className="mb-3 mb-lg-0 texto-pedidos-dados">
                  <span className="fw-bold me-3">Emitido por</span>
                  {order?.CJ_XVENDLG || ""}
                </p>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>

      <Modal size="xl" isOpen={modalEditOrderIsOpen} className="modal-atividade">
        <ModalHeader
          toggle={() => setModalEditOrderIsOpen(!modalEditOrderIsOpen)}>
          Editando o pedido #{id}
        </ModalHeader>
        <ModalBody>
          <EditCustomKit
          setIsOpen={setModalEditOrderIsOpen}
            onEditedOrder={() => {
              dispatch(getOrderActive(order.NumPedido, isSolar ? "02" : "01", order.CodFilial));
            }} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OrderData;
