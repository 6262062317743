import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Col, Row } from "reactstrap";

import RulesItems from "./RulesItems";
import RulesPagination from "./RulesPagination";

const RulesList = () => {
  const { rulesList } = useSelector(state => ({
    rulesList: state.Rules.rulesList,
  }));
  
  const [asc, setAsc] = useState(true);

  let sorted = rulesList?.sort(function (a, b) {
    if (asc) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
    } else {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
    }
  });

  const [lista, setLista] = useState(sorted);
  const [listaPaginada, setListaPaginada] = useState([]);
  const [index, setIndex] = useState(0);
  const changeIndex = valor => setIndex(valor);

  useEffect(() => {
    setListaPaginada(paginate(lista));
  }, [lista, asc]);

  function paginate(arr) {
    let rules = [];
    let data = arr;
    while (data.length) {
      rules.push(data.splice(0, 10));
    }

    return rules;
  }

  function despaginate(arr) {
    let rules = [];

    arr.map(value => {
      value.map(item => {
        rules.push(item);
      });
    });

    return rules;
  }
  return (
    <React.Fragment>

      <Row style={{}} className="mx-auto mt-4">
        <Col
          style={{ paddingRight: "2.25rem", paddingLeft: "2.25rem" }}
          className="d-none d-md-flex align-items-center justify-content-between"
        >
          <p
            style={{ color: "#005696", textAlign: "center" }}
            className="text-indigo d-flex align-items-center justify-content-center"
          >
            <strong>Id</strong>
            <Link
              to="#"
              className="justify-content-center d-flex align-items-center"
              style={{ marginLeft: 2 }}
              onClick={() => {
                let data = despaginate(listaPaginada);
                data.sort(function (a, b) {
                  if (asc) {
                    if (a.id > b.id) {
                      return 1;
                    }
                    if (a.id < b.id) {
                      return -1;
                    }
                  } else {
                    if (a.id < b.id) {
                      return 1;
                    }
                    if (a.id > b.id) {
                      return -1;
                    }
                  }
                });
                setAsc(!asc);
                setLista(data);
              }}
            >
              <i className="ph-arrows-down-up"></i>
            </Link>
          </p>
          <p
            style={{ color: "#005696", width: "200px", textAlign: "center" }}
            className="text-indigo d-flex align-items-center justify-content-center"
          >
            <strong>Nome</strong>
            <Link
              to="#"
              className="justify-content-center d-flex align-items-center"
              style={{ marginLeft: 2 }}
              onClick={() => {
                let data = despaginate(listaPaginada);
                data.sort(function (a, b) {
                  let aName = a.rule_name || "";
                  let bName = b.rule_name || "";

                  if (asc) {
                    return aName.localeCompare(bName, undefined, {
                      sensitivity: "base",
                    });
                  } else {
                    return bName.localeCompare(aName, undefined, {
                      sensitivity: "base",
                    });
                  }
                });
                setAsc(!asc);
                setLista(data);
              }}
            >
              <i className="ph-arrows-down-up"></i>
            </Link>
          </p>
          <p
            style={{ color: "#005696", minWidth: "69px", textAlign: "center" }}
            className="text-indigo d-flex align-items-center justify-content-center"
          >
            <strong>Início</strong>
            <Link
              to="#"
              className="justify-content-center d-flex align-items-center"
              style={{ marginLeft: 2 }}
              onClick={() => {
                let data = despaginate(listaPaginada);
                data.sort(function (a, b) {
                  if (asc) {
                    if (a.created_at > b.created_at) {
                      return 1;
                    }
                    if (a.created_at < b.created_at) {
                      return -1;
                    }
                    return 0;
                  } else {
                    if (a.created_at < b.created_at) {
                      return 1;
                    }
                    if (a.created_at > b.created_at) {
                      return -1;
                    }
                    return 0;
                  }
                });
                setAsc(!asc);
                setLista(data);
              }}
            >
              <i className="ph-arrows-down-up"></i>
            </Link>
          </p>
          <p
            style={{ color: "#005696" }}
            className="text-indigo d-flex align-items-center justify-content-center"
          >
            <strong>Cupom</strong>
            <Link
              to="#"
              className="justify-content-center d-flex align-items-center"
              style={{ marginLeft: 2 }}
              onClick={() => {
                let data = despaginate(listaPaginada);
                data.sort(function (a, b) {
                  if (asc) {
                    if (a.need_coupom > b.need_coupom) {
                      return 1;
                    }
                    if (a.need_coupom < b.need_coupom) {
                      return -1;
                    }
                    return 0;
                  } else {
                    if (a.need_coupom < b.need_coupom) {
                      return 1;
                    }
                    if (a.need_coupom > b.need_coupom) {
                      return -1;
                    }
                    return 0;
                  }
                });
                setAsc(!asc);
                setLista(data);
              }}
            >
              <i className="ph-arrows-down-up"></i>
            </Link>
          </p>
          <p
            style={{ color: "#005696" }}
            className="text-indigo d-flex align-items-center justify-content-center"
          >
            <strong>Frete</strong>
            <Link
              to="#"
              className="justify-content-center d-flex align-items-center"
              style={{ marginLeft: 2 }}
              onClick={() => {
                let data = despaginate(listaPaginada);
                data.sort(function (a, b) {
                  if (asc) {
                    if (a.applytoshipping > b.applytoshipping) {
                      return 1;
                    }
                    if (a.applytoshipping < b.applytoshipping) {
                      return -1;
                    }
                    return 0;
                  } else {
                    if (a.applytoshipping < b.applytoshipping) {
                      return 1;
                    }
                    if (a.applytoshipping > b.applytoshipping) {
                      return -1;
                    }
                    return 0;
                  }
                });
                setAsc(!asc);
                setLista(data);
              }}
            >
              <i className="ph-arrows-down-up"></i>
            </Link>
          </p>
          <p style={{ color: "#005696" }} />
        </Col>
      </Row>

      {(listaPaginada[index] || []).map((value, index) => {
        return <RulesItems key={index} rule={value} />;
      })}

      <RulesPagination
        rules={listaPaginada}
        index={index}
        changeIndex={changeIndex}
      />
    </React.Fragment>
  );
};

export default RulesList;
