import React, { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AddedItem from "../AddedItem";
import { useSelector, useDispatch } from "react-redux";
import { formato } from "../../../utils";
import { removeFromCart } from "../../../store/actions";

const CartProducts = ({
  setValServ,
  setSendValServ,
  addValTot,
  removeValTot,
  valores,
  setItensCarrinho,
  itensCarrinho,
  valArquiteto,
  addValArqu,
  valorRt,
  addValorRt,
  deleteValorRt,
  setPercentResumo,
  setQtdAdd,
  valTot,
  addValTotTabela,
  valTotTabela,
  valoresTabela,
  totalQuantity,
  addTotalQuantity,
  setValTot, 
  removeFromCartState,
}) => {
  const { newOrder } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (removeFromCartState) {
      let newValTot = valTot; // Inicializa com o valor atual do subtotal
      let updatedCartItems = { ...itensCarrinho };
  
      // Itera sobre os itens adicionados no pedido e remove-os
      Object.keys(newOrder.addedItems).forEach((key) => {
        const item = newOrder.addedItems[key];
        newValTot -= item.precoVenda; // Atualiza o subtotal
        let valTotal = valores;
        delete valTotal[item.produto.CODPRODUTO];
        addValTot(valTotal);
    
        let itemCar = itensCarrinho;
        delete itemCar[item.produto.CODPRODUTO];
        setItensCarrinho(itemCar);
    
        let arqu = valArquiteto;
        delete arqu[item.produto.CODPRODUTO];
        addValArqu(arqu);
    
        dispatch(removeFromCart(item)); // Remove do estado global (Redux)
      });
  
      setValTot(newValTot); // Atualiza o subtotal
      setItensCarrinho(updatedCartItems); // Atualiza os itens do carrinho
  
    }
  }, [removeFromCartState]);

  return (
    <Row className="mt-4" id="carrinho">
      <Col>
        <h4
          className="mb-4"
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#2D567B",
          }}
        >
          Produtos Adicionados
        </h4>

        {newOrder.addedItems.map(item => (
          <AddedItem
            key={item.cod}
            item={item}
            setValServ={setValServ}
            setSendValServ={setSendValServ}
            addValTot={addValTot}
            valores={valores}
            setItensCarrinho={setItensCarrinho}
            itensCarrinho={itensCarrinho}
            valArquiteto={valArquiteto}
            addValArqu={addValArqu}
            valorRt={valorRt}
            addValorRt={addValorRt}
            deleteValorRt={deleteValorRt}
            setPercentResumo={setPercentResumo}
            setQtdAdd={setQtdAdd}
            addValTotTabela={addValTotTabela}
            valTotTabela={valTotTabela}
            valoresTabela={valoresTabela}
            totalQuantity={totalQuantity}
            addTotalQuantity={addTotalQuantity}
            removeValTot={removeValTot}
          />
        ))}

        <Card
          style={{
            borderRadius: "0 0  15px 15px",
            backgroundColor: "#f2f5ff",
            zIndex: 100,
            marginTop: -15,
          }}
        >
          <CardBody>
            <Row className="d-flex justify-content-end">
              <Col md={4} className="d-flex justify-content-between">
                <h4
                  className="mb-0"
                  style={{
                    color: "rgb(0, 86, 150)",
                    fontWeight: "bold",
                  }}
                >
                  Subtotal
                </h4>
                <h4
                  className="mb-0"
                  style={{
                    color: "rgb(0, 86, 150)",
                    fontWeight: "bold",
                  }}
                >
                  {Number(valTot).toLocaleString("pt-br", formato)}
                </h4>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CartProducts;
