import { useSolar } from "../hooks";

//FILIAIS AR
export const filiaisAr = {
  1: "São Paulo / SP",
  2: "Campo Grande / MS",
  3: "João Pessoa / PB",
  4: "Simões Filho / BA",
  6: "Viana / ES",
};
//FILIAIS SOLAR
export const filiaisSolar = {
  1: "São Paulo / SP",
  2: "Cascavel / PR",
  5: "Vitoria / ES",
  6: "Ipojuca / PE",
};

const isSolar = useSolar();

export const filiais = isSolar ? filiaisSolar : filiaisAr;
