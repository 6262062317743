import React from "react";
import { useProfile, useSolar } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import {
  addProductBySku,
  atualizaCarrinho,
  clearNewOrder,
  checkCartRuleOnChange,
  getNewOrder,
  getOrderActive,
  getProductDetails,
  ocultarRT,
  removeOrderActive,
  resetAllDataOrder,
  setFilterPedido,
  setPagtoSelected,
  setTabela,
  removePromotionsAndCoupons as removePromotionsAndCouponsAction,
  removeAllItems,
  removePromotionsAndCoupons
} from "../../../store/actions";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDataLayer } from "../../../hooks/DataLayerHooks";
const useLogic = () => {
  const { userProfile, setUserProfile } = useProfile();
  const { newOrder, loading, order, loadingPromotions, addedItems, emissaoPedido, freteRedux } =
    useSelector(state => {
      return {
        newOrder: state.Order.newOrder.newOrder,
        loadingPromotions: state.Order.newOrder.loadingPromotions,
        loading: state.Order.loading,
        order: state.Order.orderActive.order,
        addedItems: state.Order.newOrder.newOrder.addedItems,
        emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
        freteRedux: state.Order.newOrder.newOrder.frete,
      };
    });

  const { pushToDataLayer } = useDataLayer();
  const dispatch = useDispatch();
  const { id, token, codFilial } = useParams();
  const isSolar = useSolar();
  const [frete, setFrete] = useState({});
  const [valores, setValores] = useState({});
  const [valoresTabela, setValoresTabela] = useState({});
  const [totalQuantity, setTotalQuantity] = useState({});
  const [valArquiteto, setValArquiteto] = useState({});
  const [valorRt, setValorRt] = useState({});
  const [ocultarSemEstoque, setOcultarSemEstoque] = useState(true);
  const [nfIsOpen, setNfIsOpen] = useState(false);
  const [obsIsOpen, setObsIsOpen] = useState(false);
  const [cepCliente, setCepCliente] = useState(null);

  const [client, setClient] = useState({});
  const [saldo, setSaldo] = useState(0);
  const [itensCarrinho, setItensCarrinho] = useState({});
  const [filterPedido] = useState({});
  const [pedidoEmitido, setPedidoEmitido] = useState(false);
  const [type, setType] = useState("order");
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [discountValue, setDiscountValue] = useState();
  const [fullDiscount, setFullDiscount] = useState(0);
  const [arrayShippingFilialSelect, setArrayShippingFilialSelect] = useState(
    []
  );
  const [codPartner, setCodPartner] = useState();
  const [isRevenda, setIsRevenda] = useState(false);
  const [awaitModfyCart, setAwaitModfyCart] = useState("open");
  const [pagamento, setPagamento] = useState([]);
  const [, setObsInterna] = useState("");
  const [, setObsNF] = useState("");
  const [arrayOldUfs, setArrayOldUfs] = useState([]);
  const [percentResumo, setPercentResumo] = useState(0);
  const [removeFromCartState, setRemoveFromCartState] = useState(false);

  const [sendValServ, setSendValServ] = useState(false);
  const [valServ, setValServ] = useState(0);
  const [discounts, setDiscounts] = useState([]);
  const [activePromotionsAndCoupons, setActivePromotionsAndCoupons] = useState(true);
  const [customerDeliveryAddress, setCustomerDeliveryAddress] = useState({
    city: '',
    state: ''
  });

  const [formVisible, setFormVisible] = useState(
    id
      ? {
        prospect: false,
        client: true,
        partner: false,
      }
      : {
        prospect: true,
        client: false,
        partner: false,
      }
  );
  const [loadingProspect, setLoadingProspect] = useState(false);
  const [dataProducts, setDataProducts] = useState(
    newOrder?.orderProducts?.produtos || []
  );

  const [errosDataProspectAndPartner, setErrosDataProspectAndPartner] =
    useState({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });

  const vendedorComRt = newOrder.comRt == '1';

  const [valTot, setValTot] = useState(0);

  useEffect(() => {
    setValTot(Somar(Object.values(valores)));
  }, [valores]);
  
  const valArq = Somar(Object.values(valArquiteto));
  const valRt = Somar(Object.values(valorRt));
  const valTotTabela = Somar(Object.values(valoresTabela));
  const sumTotalQuantity = Somar(Object.values(totalQuantity));

  const filiais = [
    { cod: 1, name: "São Paulo / SP", uf: "SP" },
    { cod: 2, name: "Campo Grande / MS", uf: "MS" },
    { cod: 3, name: "João Pessoa / PB", uf: "PB" },
    { cod: 4, name: "Simões Filho / BA", uf: "BA" },
    { cod: 6, name: "Viana / ES", uf: "ES" },
  ];
  useEffect(() => {
    dispatch(resetAllDataOrder());
  }, []);
  useEffect(() => {
    setObsInterna(order?.ObservacaoInterna);
    setObsNF(order?.ObservacaoNota);
  }, [order]);

  useEffect(() => {
    dispatch(clearNewOrder());
    setFrete({});
    setValores({});
    setValoresTabela({});
    setTotalQuantity({});
    setValArquiteto({});
    setValorRt({});
    setOcultarSemEstoque(true);
    dispatch(ocultarRT(false));
    setNfIsOpen(false);
    setObsIsOpen(false);
    setCepCliente(null);
    dispatch(setPagtoSelected([]));
    setClient({});
    setSaldo(0);
    setItensCarrinho({});

    dispatch(setFilterPedido({}));
    setPedidoEmitido(false);
    setType("order");
    dispatch(getNewOrder("CODEMPRESA=01&CODFILIAL=02", history));
  }, []);

  const total = () => {
    var shipping = frete?.priceSomado ? frete?.priceSomado : frete.price || 0;
    const discount =
    discountValue?.typerulediscount == 1
      ? (parseFloat(valTot) / 100) * Number(discountValue?.discountValue)
      : discountValue?.discountValue;
    var totalDiscount = parseFloat(valTot) - parseFloat(discount || 0);

    return parseFloat(
      parseFloat(totalDiscount) +
        parseFloat(shipping) -
        parseFloat(saldo)
    );
  }

  const subTotalWithoutDiscount = newOrder.addedItems.reduce(getTotal, 0);

  function getTotal(total, item) {
    const quantity = itensCarrinho[item.cod] ? itensCarrinho[item.cod].AMOUNT : 1;
    return total + (item.precoVenda * quantity);
  }

  useEffect(() => {
    if (emissaoPedido?.data?.success) {

      pushToDataLayer(
        type == 'budget' ? 'solicitar_orcamento' : 'purchase',
        {
          ecommerce: {
            currency: 'BRL',
            value: total(),
            transaction_id: emissaoPedido.data.CODPEDIDO,
            shipping: emissaoPedido.data.FRETE,
            vendedor1: emissaoPedido.data.VENDEDOR1,
            vendedor2: emissaoPedido.data.VENDEDOR2,
            vendedor3: emissaoPedido.data.VENDEDOR3,
            items: newOrder?.addedItems.map(product => ({
              item_id: product.produto.CODPRODUTO,
              item_name: product.produto.DESCRICAO,
              item_brand: product.produto.MARCA,
              item_category: product.produto.CATEGORIA,
              price: itensCarrinho[product.produto.CODPRODUTO]?.PRCRT,
              quantity: itensCarrinho[product.produto.CODPRODUTO]?.AMOUNT
            }))
          }
        },
        { clearEcommerce: true }
      );
    }
  }, [emissaoPedido]);

  useEffect(() => {
    if (id) {
      toast.warning(
        "Atenção: valores de equipamentos e frete poderão ser alterados.",
        { theme: "colored" }
      );
      dispatch(getOrderActive(id, isSolar ? "02" : "01", codFilial));
    } else {
      dispatch(removeOrderActive());
    }
  }, [id]);

  useEffect(() => {
    setDiscounts(newOrder?.discounts ? newOrder.discounts : [])
  }, [newOrder?.discounts])

  useEffect(() => {
    if (id) {
      (order?.items_orcamento || []).map(value => {
        dispatch(getProductDetails(value.Produto));
        const result = addedItems.find(i => i.cod === value.Produto);
        if (!result) {
          let data = {
            sku: value.Produto,
            tabela: order.TabelaPreco,
            pagto: order.CondicaoPagamento,
            filial: value.CodFilial,
            precoVenda: value.PrecoVend,
            quant: value.Qtdven,
            item: value.Item,
          };

          dispatch(addProductBySku(data));
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (newOrder.orderProducts?.produtos?.length > 0) {
      setPaginatedProducts(
        paginate(ocultarSemEstoque, newOrder.orderProducts.produtos, 10)
      );
    }
  }, [newOrder.orderProducts?.produtos, ocultarSemEstoque]);

  useEffect(() => {
    getBranchWithGreaterQuantityOfMoreProducts(addedItems);
  }, [addedItems, itensCarrinho]);

  const getBranchWithGreaterQuantityOfMoreProducts = (items) => {
    const branchesPerProduct = items.map(product => {
      return getBranchesWithEnoughProductsInStock(product.produto).map(branch => ({
        branchId: branch.CODFILPRD,
        stock: branch.ESTOQUE,
        deliveryDays: getShippingDaysByStock(branch),
      }));
    });
  
    const commonBranches = branchesPerProduct.reduce((acc, branches) => {
      const branchIds = branches.map(branch => branch.branchId);
      return acc.filter(branchId => branchIds.includes(branchId));
    }, branchesPerProduct[0]?.map(branch => branch.branchId));
  
    items.forEach((product, index) => {
      const quantityRequired = itensCarrinho[product.produto.CODPRODUTO]?.AMOUNT || 1;
      const branches = branchesPerProduct[index];
      let branchesUsed = [];
  
      if (commonBranches.length > 0) {
        const branchesInCommon = branches.filter(branch => commonBranches.includes(branch.branchId));
        const sortedBranchesInCommon = branchesInCommon.sort((a, b) => a.deliveryDays - b.deliveryDays);
  
        const branchWithStock = sortedBranchesInCommon.find(branch => branch.stock >= quantityRequired);
        if (branchWithStock) {
          branchesUsed.push({
            branchId: branchWithStock.branchId,
            deliveryDays: branchWithStock.deliveryDays,
            quantityUsed: quantityRequired,
          });
        }
      }
      
      if (branchesUsed.length === 0) {
        const sortedBranches = branches.sort((a, b) => a.deliveryDays - b.deliveryDays);
        let quantityRequiredAux = 0;
  
        for (const branch of sortedBranches) {
          if (quantityRequiredAux >= quantityRequired) break;
          const quantityFromStock = Math.min(branch.stock, quantityRequired - quantityRequiredAux);
          
          branchesUsed.push({
            branchId: branch.branchId,
            deliveryDays: branch.deliveryDays,
            quantityUsed: quantityFromStock,
          });
  
          quantityRequiredAux += quantityFromStock;
        }
      }
  
      product.produto.branchesUsed = branchesUsed;
    });
  };
  

  const getBranchesWithEnoughProductsInStock = (product) => {
    return product.PRDDISPS.STRETPRDFILADIAS.filter(estoque => estoque.ESTOQUE > 0);
  };

  const getShippingDaysByStock = stock => {
    return stock.PRAZOCALC ? typeof stock.PRAZOCALC === 'string' ? parseInt(stock.PRAZOCALC.match(/\d+/)[0]) : stock.PRAZOCALC : 0;
  };

  useEffect(() => {
    if (valorRt) {
      setPercentResumo((valRt / valTot) * 100)
    } else {
      setPercentResumo(0);
    }
  }, [valorRt, valTot]);

  function canOrCanNot() {
    var profileTrueOrFalse = false;
    userProfile?.claims?.groups.map(value => {
      if (value === 8 || value === 9 || (value >= 14 && value <= 20)) {
        setIsRevenda((profileTrueOrFalse = true));
      }
    });
  }

  useEffect(() => {
    if (userProfile?.claims?.groups) {
      canOrCanNot();
    }
  }, [userProfile?.claims?.groups]);

  useEffect(() => {
    if (
      newOrder?.orderProducts?.success &&
      newOrder?.orderProducts?.produtos.length < 1
    ) {
      toast.warning("Nenhum produto encontrado com os filtros atuais", {
        theme: "colored",
      });
    }
  }, [newOrder?.orderProducts]);

  useEffect(() => {
    getPromotionsAndDiscounts();
  }, [customerDeliveryAddress, formVisible, client.customerType, filterPedido.vendedor2, valoresTabela, activePromotionsAndCoupons, sumTotalQuantity]);

  const getPromotionsAndDiscounts = () => {
    if (formVisible.client && customerDeliveryAddress && Object.keys(valoresTabela).length > 0 && activePromotionsAndCoupons) {
      setFrete({
        ...frete,
        promotionDiscount: 0
      });
      dispatch(removePromotionsAndCoupons());
      dispatch(checkCartRuleOnChange(pedido));
    }
  }

  useEffect(() => {
    const customerDeliveryAddressUpdate = client?.customer_address?.length > 0
    ? pedido.client.customer_address.length > 1
      ? pedido.client.customer_address[1]
      : pedido.client.customer_address[0]
    : null;

    if (
      customerDeliveryAddressUpdate?.city && 
      customerDeliveryAddressUpdate?.state && 
      (
        customerDeliveryAddressUpdate.city != customerDeliveryAddress.city ||
        customerDeliveryAddressUpdate.state != customerDeliveryAddressUpdate.state
      )
    ) {
      setCustomerDeliveryAddress({
        city: customerDeliveryAddressUpdate.city,
        state: customerDeliveryAddressUpdate.state
      });
    }
  }, [client?.customer_address]);

  useEffect(() => {
    if (!formVisible.client && discounts.length > 0) {
      dispatch(removePromotionsAndCoupons());
    }
  }, [formVisible, discounts]);

  useEffect(() => {
    if (!activePromotionsAndCoupons) {
      dispatch(removePromotionsAndCouponsAction())
    }
  }, [activePromotionsAndCoupons]);

  useEffect(() => {
    setFullDiscount(newOrder.fullDiscount);
  }, [
    newOrder.fullDiscount
  ]);

  const pedido = {
    client: client,
    type_order: "order",
    product_type: "",
    budget_infos: {
      codigo_cliente: 0,
      tipo_cliente: 0,
    },
    items: retornaItems(),
    itemsValTot: valTot,
    shipping: {
      id: frete?.id || "",
      shippingDiscount: frete?.promotionDiscount,
      shipping_selected: {
        delivery_delay: frete?.delivery_delay || "",
        id: frete?.id || "",
        price: frete?.price || "",
        description: frete?.description || "",
        price_percent: frete?.price_percent || "",
        usado_percentual: frete?.usado_percentual || "",
        frete_gratis: frete?.frete_gratis || "",
      },
      price: frete?.price || "",
    },
    filters: filterPedido,
    services: {
      product_type: "",
      ambientes: [],
    },
    discounts: discounts,
    discountcashback: saldo,
    fullDiscount: newOrder?.fullDiscount,
    subTotalDiscount: newOrder?.subTotalDiscount,
    CODEMPRESA: "01",
    CODFILIAL: "02",
  };

  function retornaItems() {
    let items = [];

    newOrder.addedItems.map(value => {
      function getEstoques(es) {
        let result =
          value.produto.PRDDISPS?.STRETPRDFILADIAS?.find(
            i => i.CODFILPRD === es
          ) || "";
        return result?.ESTOQUE || 0;
      }
      let produto = {
        ...value.produto,
        AMOUNT: value.produto.AMOUNT ? value.produto.AMOUNT :  1,
        PRCTAB: value.valorTabela,
        ESTOQUE_CD01: getEstoques("01"),
        ESTOQUE_CD02: getEstoques("02"),
        ESTOQUE_CD03: getEstoques("03"),
        ESTOQUE_CD04: getEstoques("04"),
        PRCRT: value.precoVenda || 0,
        precos: {
          cd:
            value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.CODFILPRD ||
            "",
          preco: value.precoVenda,
          diferente: "N",
        },
        PRECODIFER:
          value.produto.PRCVENDA?.STRETPRCFILADIAS[value.filial]?.PRECODIFER ||
          "",
        ESTOQUE:
          value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.ESTOQUE || "",
        instalacao: false,
        VALSERV: sendValServ ? valServ : 0,
        ...itensCarrinho[value.produto.CODPRODUTO],
      };

      items.push(produto);
    });

    return items;
  }

  function Somar(array) {
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i] >= 2) {
        total += array[i];
      }
    }
    return total;
  }

  function paginate(ocultarSemEstoque, arr, size) {
    var newArray = [];
    if (ocultarSemEstoque) {
      hasEstoque(arr, newArray);
    } else {
      newArray = arr;
    }
    setCurrentPage(0);
    setDataProducts(newArray);

    if (newArray?.length <= 0) {
      return 0;
    }
 
    // Ordenar pelo menor valor
    var newArraySort = newArray.sort(
      (a, b) =>
        a.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA -
        b.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA
    );

    return newArraySort.reduce((acc, val, i) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);
      return acc;
    }, []);
  }
  const semEstoque = isRevenda ? 1 : 0;
  function hasEstoque(arr, newArray) {
    for (let prop1 in arr) {
      var arrayEstoque = [];
      for (var items in arr[prop1].PRDDISPS.STRETPRDFILADIAS) {
        if (arr[prop1].PRDDISPS.STRETPRDFILADIAS[items].ESTOQUE <= semEstoque) {
          arrayEstoque.push(true);
        } else {
          arrayEstoque.push(false);
        }
      }

      if (arrayEstoque.includes(false)) {
        newArray.push(arr[prop1]);
      }
    }
  }

  const addValTot = (item, valorTotal) => {
    setValores(prevValores => ({
      ...prevValores,
      [item]: Number(valorTotal),
    }));
  };

  const removeValTot = productId => {
    setValores(prevValores => {
      const { [productId]: _, ...newValores } = prevValores;
      return newValores;
    });
  };

  const addTotalQuantity = valor => {
    setTotalQuantity(valor);
  };

  const addValTotTabela = valor => {
    setValoresTabela(valor);
  };

  const addValArqu = valor => {
    const newValArqu = { ...valor }
    setValArquiteto(newValArqu);
  };

  const addValorRt = valor => {
    setValorRt(prevState => {
      return {
        ...prevState,
        [valor.nome]: valor.valCashBack,
      };
    });
  };

  const deleteValorRt = productName => {
    setValorRt(prevState => {
      const newState = { ...prevState };
      delete newState[productName];
      return newState;
    });
  };

  const isShippingPromotion = applyToShipping => {
    return applyToShipping == 1;
  } 
  
  const getDiscountValue = (price, valueDiscount, typeRuleDiscount) => {
    const moneyDiscount = 0;
    return typeRuleDiscount == moneyDiscount ? valueDiscount : valueDiscount * (price / 100);
  }
  useEffect(() => {
    if (frete?.id) {
      pushToDataLayer(
        'add_shipping_info',
        { 
          ecommerce: {
            currency: 'BRL',
            value: total(),
            selected_shipping: frete,
            items: newOrder?.addedItems.map(product => ({
              item_id: product.produto.CODPRODUTO,
              item_name: product.produto.DESCRICAO,
              item_brand: product.produto.MARCA,
              item_category: product.produto.CATEGORIA,
              price: itensCarrinho[product.produto.CODPRODUTO]?.PRCRT,
              quantity: itensCarrinho[product.produto.CODPRODUTO]?.AMOUNT
            }))
          }
        },
        { clearEcommerce: true }
      );
    }
  }, [frete]);

  const addFrete = valor => {
    var objetosFiltrados = [];
    
    const shippingsPromotions = discounts.filter(discount => isShippingPromotion(discount.applyToShipping));
    shippingsPromotions.forEach(discount => {
      valor.promotionDiscount = Number(getDiscountValue(valor.price, discount.valueDiscount, discount.typer));  
    });
    setFrete(valor);

    if (freteRedux.arrayShipping) {
      freteRedux.arrayShipping.forEach(function (objetoPai) {
        objetoPai.forEach(function (objetoFilho) {
          if (objetoFilho.id === valor.id) {
            objetosFiltrados.push(objetoFilho);
          }
        });
      });

      setArrayShippingFilialSelect(objetosFiltrados);
    }
  };

  useEffect(() => {
    if (frete?.price && discounts ) {
      const shippingsPromotions = discounts.filter(discount => isShippingPromotion(discount.applyToShipping));
      
      let discountValue = 0;  
      shippingsPromotions.forEach(discount => {
        discountValue += Number(getDiscountValue(frete.price, discount.valueDiscount, discount.typer));  
      });
      
      setFrete({
        ...frete,
        promotionDiscount: discountValue
      });
    }
  }, [discounts]);

  const changeType = type => {
    setType(type);
  };

  const changeCepCliente = cep => {
    setCepCliente(cep);
  };

  const changeSaldo = saldo => {
    setSaldo(Number(saldo));
  };

  const changeClient = client => {
    setClient(prev => ({ ...prev, ...client }));
  };

  const changeItensCarrinho = itens => {
    setItensCarrinho(itens);
  };

  const changePedidoEmitido = () => {
    setPedidoEmitido(!pedidoEmitido);
  };

  const [qtdAdd, setQtdAdd] = useState(1);

  let newAddItemsSeparadoPorFilial = organizandoObjeto(pedido.items);

  function organizandoObjeto() {
    const uniqueUsedStocks = Array.from(
      pedido.items.reduce((acc, item) => {
        item.branchesUsed?.forEach(stock => acc.add(stock.branchId));
        return acc;
      }, new Set())
    ).map(branchId => {
      return { branchId };
    });

    const itemsSeparatedByBranch = [];

    uniqueUsedStocks.forEach(stock => {
      let itemsForThisStock = []

      let totalValue = 0;

      pedido.items.forEach(item => {
        const branch = item.branchesUsed?.find(branch => branch.branchId === stock.branchId);

        if (branch) {
          const itemCopy = { ...item };

          itemCopy.AMOUNT = branch.quantityUsed;
          itemCopy.CODFILIAL = branch.branchId;
          totalValue += itemCopy.PRCRT * branch.quantityUsed;
          itemsForThisStock.push(itemCopy);
        }
      });

      itemsSeparatedByBranch.push({
        CODFILIAL: stock.branchId,
        valTotal: totalValue,
        data: itemsForThisStock
      });
    });

    return itemsSeparatedByBranch;
  }

  function removeArraysVazios(array) {
    return array.filter(function (item) {
      return Array.isArray(item) && item.length > 0;
    });
  }

  const cartBranchSameCustomerUf = uf => {
    var ufCliente = uf || "";

    var getFilialData = [];

    //verifica se dentro do carrinho existe um produto com a filial iguail a uf do cliente
    for (var order in pedido.items) {
      getFilialData.push(
        filiais.filter(
          item =>
            item.cod == Number(pedido.items[order].CODFILIAL) &&
            item.uf == ufCliente
        )
      );
    }
    var removeArraysVazio = removeArraysVazios(getFilialData) || 0;

    //se a uf antiga for igual a atual ele mantem
    if (arrayOldUfs.length == removeArraysVazio?.length) {
      return;
    }

    setArrayOldUfs(removeArraysVazio);

    if (removeArraysVazio[0]?.length > 0) {
      toast.info(
        "Detectamos uma venda para dentro do estado e os preços dos produtos foram atualizado em seu carrinho.",
        { theme: "colored" }
      );
      dispatch(
        atualizaCarrinho(
          {
            pgto: pagamento,
            tabela: [{ codigo: "003", label: "REVENDA / PARCEIRO MS ou ES" }],

            setAwaitModfyCart,
          },
          pedido
        )
      );
      dispatch(
        setTabela([{ codigo: "003", label: "REVENDA / PARCEIRO MS ou ES" }])
      );
    } else {
      toast.info(
        "Detectamos uma venda para  fora do estado e os preços dos produtos foram atualizado em seu carrinho.",
        { theme: "colored" }
      );

      dispatch(
        atualizaCarrinho(
          {
            pgto: pagamento,
            tabela: [{ codigo: "002", label: "REVENDA / PARCEIROS" }],
            setAwaitModfyCart,
          },
          pedido
        )
      );

      dispatch(setTabela([{ codigo: "002", label: "REVENDA / PARCEIROS" }]));
    }
  };

  const ufValidate = pedido?.client?.customer_address
    ? pedido.client.customer_address.length > 1
      ? pedido.client.customer_address[1].state
      : pedido.client.customer_address[0].state
    : "";

  /*  useEffect(() => {
    if (ufValidate) {
      cartBranchSameCustomerUf(ufValidate);
    }
  }, [ufValidate, pedido?.items]); */

  const cartBranchSameCustomerUfTable = (uf, table) => {
    var ufCliente = uf || "";
    var verifyMsOrEs = ufCliente == "ES" || ufCliente == "MS" ? true : false;

    var getFilialData = [];

    if (!ufCliente) {
      return;
    }
    //verifica se dentro do carrinho existe um produto com a filial iguail a uf do cliente
    for (var order in pedido.items) {
      getFilialData.push(
        filiais.filter(
          item =>
            item.cod == Number(pedido.items[order].CODFILIAL) &&
            item.uf == ufCliente
        )
      );
    }
    var removeArraysVazio = removeArraysVazios(getFilialData) || 0;

    if (
      table[0].codigo == "003" &&
      verifyMsOrEs &&
      removeArraysVazio[0]?.length > 0
    ) {
      toast.info(
        "Detectamos uma venda para dentro do estado e os preços dos produtos foram atualizado em seu carrinho.",
        { theme: "colored" }
      );
      dispatch(
        atualizaCarrinho(
          {
            pgto: pagamento,
            tabela: [{ codigo: "003", label: "REVENDA / PARCEIRO MS ou ES" }],

            setAwaitModfyCart,
          },
          pedido
        )
      );
    } else {
      toast.info(
        "Detectamos uma venda para  fora do estado e os preços dos produtos foram atualizado em seu carrinho.",
        { theme: "colored" }
      );

      dispatch(
        atualizaCarrinho(
          {
            pgto: pagamento,
            tabela: [{ codigo: "002", label: "REVENDA / PARCEIROS" }],
            setAwaitModfyCart,
          },
          pedido
        )
      );
    }
  };

  return {
    newOrder,
    loading,
    order,
    addedItems,
    emissaoPedido,
    loadingProspect,

    filterPedido,

    pagamento,
    setPagamento,
    setAwaitModfyCart,
    pedido,
    cartBranchSameCustomerUfTable,
    ufValidate,
    paginatedProducts,
    ocultarSemEstoque,
    setOcultarSemEstoque,
    currentPage,
    setCurrentPage,
    isRevenda,
    dataProducts,
    setValServ,
    setSendValServ,
    addValTot,
    removeValTot,
    valores,
    setItensCarrinho,
    itensCarrinho,
    valArquiteto,
    addValArqu,
    valorRt,
    vendedorComRt,
    addValorRt,
    deleteValorRt,
    setPercentResumo,
    setQtdAdd,
    valTot,
    changeCepCliente,

    changeClient,
    client,
    formVisible,
    setFormVisible,
    errosDataProspectAndPartner,
    setErrosDataProspectAndPartner,
    setDiscountValue,
    changeSaldo,
    obsIsOpen,
    setObsIsOpen,
    setClient,
    nfIsOpen,
    setNfIsOpen,
    addFrete,
    cepCliente,
    newAddItemsSeparadoPorFilial,
    awaitModfyCart,
    valRt,
    frete,
    saldo,
    changePedidoEmitido,
    changeType,
    token,
    setLoadingProspect,
    percentResumo,
    qtdAdd,
    discountValue,
    fullDiscount,
    setFullDiscount,
    arrayShippingFilialSelect,
    type,
    id,
    activePromotionsAndCoupons,
    setActivePromotionsAndCoupons,
    discounts,
    addValTotTabela,
    valTotTabela,
    valoresTabela,
    freteRedux,
    totalQuantity,
    addTotalQuantity,
    getPromotionsAndDiscounts,
    codPartner,
    setCodPartner,
    removeFromCartState, 
    setRemoveFromCartState,
    setValTot,
    subTotalWithoutDiscount,
    loadingPromotions,
  };
};

export default useLogic;
