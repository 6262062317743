import { apiClient } from "./apiCore";

export const getTermos = company => {
  const base_url = "api/terms";

  return apiClient.get(`${base_url}/${company}`);
};

export const getDeliveryTerms = company =>
{
  const baseUrl = "api/terms/delivery";
  return apiClient.get(`${baseUrl}/${company}`);
}
