export const downloadBlob = async (blob, downloadName, downloadType) => {
  try {
    const file = new Blob([blob], { type: `application/${downloadType}` });

    const fileURL = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", downloadName);
    
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileURL);
  } catch (error) {
    console.log("Erro ao baixar a Nota Fiscal.");
  }
};