import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  Card,
  CardBody,
  FormGroup
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { addStatus, addToCartWithoutKit, editPrecoClienteWithoutKit, resetFreteValue, getFireBaseRemoteConfig } from "../../../store/actions";
import { toast } from "react-toastify";
import { getAcrescimoCount, valueByQTD, getOutOfStockMessage } from "../../../utils";
import { apiClient } from "../sunbeam-at-your-address/helpers/importer.helper";
import { arredondar, dateNow } from "../../../utils";
import { useProfile } from "../../../hooks";
import { isInverter } from "../../utils/functions/solarUtils";
import SelectSearch from "../../../components/SelectSerach";
import { GET_FIREBASE_REMOTE_CONFIG_CONDICAO_PAGAMENTO_SOLAR } from "../../../store/firebaseRemoteConfig/actionTypes";
import { useColors } from "../../../hooks/useColors";

function InsertProduct({
  addValTot,
  valores,
  acrescimo,
  setProductsVisible,
  setAcrescimo,
  setWaitingToChangeCartItems,
  setFilterPedido,
  setPagtoSelected,
  setChangeFormOfPayment,
  largeAccountCode,
}) {
  const dispatch = useDispatch();
  const { newOrder, user, condPagamentoSolar } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      user: state.Profile.profileDetails.user,
      condPagamentoSolar: state.FirebaseRemoteConfig.condPagamentoSolar,
    };
  });

  const { userProfile } = useProfile();

  const { colorLight } = useColors();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";
  const [data, setData] = useState({
    categoria: [{ id: 0, nome: "Carregando..." }],
    produto: [],
    quantidade: 1,
  });
  const [categoriaSelect, setCategoriaSelect] = useState([
    { id: 0, name: "Carregando...", values: [] },
  ]);
  const [produtoSelect, setProdutoSelect] = useState([
    { id: 0, name: "Carregando..." },
  ]);
  const [attListProducts, setAttListProducts] = useState(false);
  const userCode = user?.code ? user?.code : "";
  const [pagamento, setPagamento] = useState([]);
  const [filterPagamento, setFilterPagamento] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAcrescimo, setLoadingAcrescimo] = useState(false);

  useEffect(() => {
    if (data?.categoria[0]?.values?.length > 0) {
      modifyProductValues();
    }
  }, [data?.categoria[0]?.values, newOrder.addedItems, attListProducts]);

  useEffect(() => {
    let pagto = "";
    if (pagamento.length > 0) {
      pagto = pagamento[0]?.codigo || "";

      if (largeAccountCode.includes(pagto)) {
        dispatch(resetFreteValue());
      }

      setFilterPedido(state => {
        return {
          ...state,
          Nomecondpagto: pagamento[0]?.label || "",
          condpagto: pagamento[0]?.codigo || "",
        };
      });
    }
  }, [pagamento]);

  useEffect(() => {
    if (pagamento[0]?.codigo) {
      getAcrescimo();
    }
  }, [pagamento]);

  useEffect(() => {
    dispatch(getFireBaseRemoteConfig("CONDICAO_PAGAMENTO_SOLAR", "", GET_FIREBASE_REMOTE_CONFIG_CONDICAO_PAGAMENTO_SOLAR));
  }, []);

  useEffect(() => {
    if (condPagamentoSolar) {

      const parsedPaymentData = typeof condPagamentoSolar === 'string' ? JSON.parse(condPagamentoSolar) : condPagamentoSolar;
      setFilterPagamento(formatOptions(parsedPaymentData));
    }
  }, [condPagamentoSolar]);

  const formatOptions = (data) => {
    let formatted = [];
    Object.entries(data)
      .forEach(([category, items]) => {
        formatted.push({ label: `${category}`, isGroup: true });
        Object.entries(items)
          .sort(([, a], [, b]) => a.name.localeCompare(b.name))
          .forEach(([key, details]) => {
            formatted.push({ codigo: key, label: details.name, type: category });
          });
      });
    return formatted;
  };

  useEffect(() => {
    const paymentConditions = filterPagamento.filter((item) => !item.isGroup);
    if (filterPagamento.filter((item) => !item.isGroup).length > 0) {
      setPagamento([paymentConditions[0]]);
      setPagtoSelected(paymentConditions[0]);
    }
  }, [filterPagamento.length > 0]);

  useEffect(async () => {
    setLoading(true);

    const resp = await apiClient.get(
      `/api/person-kits/listProducts/02/01`, { withoutKit: true }
    );

    let newObj = [];

    Object.keys(resp).map((item, index) =>
      newObj.push({ id: index, nome: item, values: resp[item] })
    );
    setCategoriaSelect(newObj);
    setData(state => {
      return { ...state, categoria: newObj[0] ? [newObj[0]] : [] };
    });
    setLoading(false);
  }, []);

  const modifyProductValues = () => {
    var subItems = data?.categoria[0]?.values;
    var arraySubitemsRepetitions = [];
    for (const value in subItems) {
      const countInverter = newOrder.addedItems.filter(product =>
        product.produto[0].nome.includes(subItems[value].nome)
      );

      if (countInverter.length == 0) {
        arraySubitemsRepetitions.push(subItems[value]);
      }
    }

    setProdutoSelect(arraySubitemsRepetitions);
    setData(state => {
      return {
        ...state,
        produto: arraySubitemsRepetitions[0]
          ? [arraySubitemsRepetitions[0]]
          : [],
      };
    });
  };

  const saveNewProduct = () => {
    const stock = (data.produto[0].qtdEstoque + 1) - Number(data.quantidade);

    if (!Number.isInteger(Number(data.quantidade))) {
      return toast.error("Só são permitidas quantidades inteiras de produtos", {
        theme: "colored",
      });
    }

    if (data?.quantidade <= 0) {
      return toast.info("A quantidade precisa ser maior que 0", {
        theme: "colored",
      });
    } else if (stock < 0) {
      return toast.info(getOutOfStockMessage(userProfile.claims.groups), {
        theme: "colored",
      });
    }

    setProductsVisible(true);

    if (isInverter(data.produto[0])) {
      addToCartMC4();
    } else {
      addToCartProduct(stock);
    }
  };

  const addToCartMC4 = async () => {
    const countInverter = newOrder.addedItems.filter(product =>
      isInverter(product.produto[0])
    );

    let obj = {
      inverter: data.produto[0].nome,
      count: countInverter?.length || 0,
      qtd: Number(data.quantidade),
      withoutKit: true,
    };
    let dataProduct = {};
    let arrayProduct = [];

    const resp = await apiClient.create(`/api/person-kits/changeInverter/02/01`, obj);

    if (resp.erro) {
      toast.info(resp.msg, {
        theme: "colored",
      });
    } else {
      toast.success(`${data.produto[0].nome} foi adicionado ao carrinho`, {
        theme: "colored",
      });
    }
    var subTotal = valueByQTD(data.produto[0].precoCliente, data.quantidade);
    var resultAcrescimo = getAcrescimoCount(acrescimo, subTotal);
    var precoClienteAcrescimo = resultAcrescimo / data.quantidade;

    var subTotalMC4 = valueByQTD(resp.mc4.precoCliente, resp.mc4.qtd);
    var resultAcrescimoMC4 = getAcrescimoCount(acrescimo, subTotalMC4);
    var precoClienteAcrescimoMC4 = resultAcrescimoMC4 / resp.mc4.qtd;

    arrayProduct.push(
      {
        ...data.produto[0],
        pecasVinculadas: resp.pecasVinculadas,
        qtd: Number(data.quantidade),
        subTotal: resultAcrescimo,
        PRCRT: resultAcrescimo,
        precoClienteAcrescimo: precoClienteAcrescimo,
        id: countInverter?.length + 1,
        qtdEstoque: resp?.qtdEstoque,
        previsao: resp?.previsao,
      },
      {
        ...resp.mc4,
        qtd: resp.mc4.qtd * Number(data.quantidade),
        subTotal: resultAcrescimoMC4 * Number(data.quantidade),
        PRCRT: resultAcrescimoMC4 * Number(data.quantidade),
        precoClienteAcrescimo: precoClienteAcrescimoMC4,
        id: countInverter?.length + 1,
        initialQtd: resp.mc4.qtd,
        previsao: resp.mc4.previsao
      }
    );

    arrayProduct.map((item, k) => {
      dataProduct = {
        produto: [item],
      };

      dispatch(addToCartWithoutKit(dataProduct));
    });
    setAttListProducts(state => !state);
    addValTotalStringBoxAndMC4(arrayProduct);
  };

  const addValTotalStringBoxAndMC4 = arrayProduct => {
    let valTotal = {
      ...valores,
      [arrayProduct[0].nome]: arrayProduct[0].subTotal,
      [arrayProduct[1].nome]: arrayProduct[1].subTotal,
    };

    addValTot(valTotal);

    addStatusProduct();
  };

  const addToCartProduct = stock => {
    var subTotal = valueByQTD(data.produto[0].precoCliente, data.quantidade);
    var resultAcrescimo = getAcrescimoCount(acrescimo, subTotal);
    var precoClienteAcrescimo = parseFloat(
      arredondar(resultAcrescimo / Number(data.quantidade))
    );
    var total = precoClienteAcrescimo * Number(data.quantidade);
    let addQtd = {
      ...data.produto[0],
      qtd: Number(data.quantidade),
      PRCRT: total,
      subTotal: total,
      precoClienteAcrescimo: precoClienteAcrescimo,
      qtdEstoque: stock
    };
    let dataProduct = {
      produto: [addQtd],
    };
    dispatch(addToCartWithoutKit(dataProduct));

    toast.success(`${data.produto[0].nome} foi adicionado ao carrinho`, {
      theme: "colored",
    });
    setAttListProducts(state => !state);
    addValTotal();
  };

  const addValTotal = () => {
    let valTotal = {
      ...valores,
      [data.produto[0].nome]: valueByQTD(
        data.produto[0].precoCliente,
        data.quantidade
      ),
    };
    addValTot(valTotal);
    addStatusProduct();
  };

  const addStatusProduct = () => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();
    let status = {
      statusName: "add",
      name: data.produto[0].nome,
      date: date,
      hours: hours,
      userCode: userCode,
      displayName: displayName,
    };

    dispatch(addStatus(status));
  };

  const getAcrescimo = async () => {
    var obj = { condpagamento: pagamento[0].codigo };
    setLoadingAcrescimo(true);
    try {
      setWaitingToChangeCartItems(true);

      const resp = await apiClient.create(`/api/acrescimoCondPag`, obj);
      const acrescimo = resp;
      const editProductQtd = newOrder.addedItems;

      let valTotal;

      if (editProductQtd.length > 0) {
        for await (const editProduct of editProductQtd) {
          var qtd = editProduct.produto[0].qtd ? editProduct.produto[0].qtd : 1;
          var resultAcrescimoPrecoCliente = getAcrescimoCount(
            acrescimo,
            editProduct.produto[0].precoCliente
          );
          dispatch(editPrecoClienteWithoutKit(editProduct.produto[0].nome, acrescimo));
          valTotal = {
            ...valTotal,
            [editProduct?.produto[0].nome]: resultAcrescimoPrecoCliente * qtd,
          };
        }

        addValTot(valTotal);
      }
      setAcrescimo(resp);
      setWaitingToChangeCartItems(false);
    } catch (error) {
      toast.error(error, { theme: "colored" });
      setWaitingToChangeCartItems(false);
    }
    setLoadingAcrescimo(false);
  };

  const handleSelectFormOfPayment = e => {
    setPagamento([e]);
    setChangeFormOfPayment(true);
    setTimeout(() => {
      setChangeFormOfPayment(false);
    }, 8000);
    toast.info(
      `Ao atualizar a condição de pagamento, os valores dos produtos são atualizados e podem sofrer alterações.`,
      { theme: "colored" }
    );
    addStatusFormOfPayment(e);
  };

  const addStatusFormOfPayment = currentFormOfPayment => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();
    let status = {
      statusName: "change",
      name: "FORMA DE PAGAMENTO",
      date: date,
      hours: hours,
      qtdAdias: pagamento[0].label,
      qtdChange: currentFormOfPayment?.label,
      userCode: userCode,
      displayName: displayName,
    };

    dispatch(addStatus(status));
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={3}>
            <Label>Categoria:</Label>
            <Typeahead
              id="category"
              labelKey={option => `${option.nome}`}
              onFocus={() => {
                setData(state => {
                  return { ...state, categoria: [] };
                });
              }}
              onChange={e => {
                setData(state => {
                  return { ...state, categoria: e };
                });
              }}
              selected={data.categoria}
              options={categoriaSelect}
              disabled={loading}
              emptyLabel="Nenhum item encontrado"
            />
          </Col>

          <Col md={4}>
            <Label>Produto:</Label>
            <Typeahead
              id="product"
              labelKey={option => `${option.nome}`}
              onFocus={() => {
                setData(state => {
                  return { ...state, produto: [] };
                });
              }}
              onChange={e => {
                setData(state => {
                  return { ...state, produto: e };
                });
              }}
              selected={data.produto}
              options={produtoSelect}
              disabled={loading}
              emptyLabel="Nenhum item encontrado"
            />
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Formas de Pagamento</Label>
              <SelectSearch
                options={
                  filterPagamento
                }
                disable={loading}
                onChange={e => {
                  if (confirm) {
                    if (!e.isGroup) {
                      handleSelectFormOfPayment(e);
                      setPagtoSelected(e)
                    }
                  }
                }}
                selected={pagamento}
                renderMenuItem={(item) => (
                  <div
                    style={{
                      fontWeight: item.isGroup ? "bold" : "normal",
                      backgroundColor: item.isGroup ? colorLight : "transparent",
                      padding: item.isGroup ? "5px 10px" : "3px 10px",
                      cursor: item.isGroup ? "default" : "pointer",
                      pointerEvents: item.isGroup ? "none" : "auto",
                    }}
                  >
                    {item.label}
                    {!item.isGroup && <div><small>{item.type}</small></div>}
                  </div>
                )}
              />
            </FormGroup>
          </Col>
          <Col md={1}>
            <Label>Quantidade:</Label>
            <Input
              type="number"
              value={data.quantidade}
              onChange={e =>
                setData(state => {
                  return { ...state, quantidade: e.target.value };
                })
              }
              disabled={loading}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "end",
              gap: 20,
            }}
          >
            <button
              onClick={saveNewProduct}
              style={{
                background: "#FF601A",
                color: "#fff",
                width: "fit-content",
              }}
              className="btn button-module"
              disabled={loading || loadingAcrescimo}
            >
              {loadingAcrescimo
                ? "Alterando forma de pagamento..."
                : "Inserir Produto"}
            </button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default InsertProduct;
