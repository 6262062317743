import React, { useState, useEffect } from "react";
import {
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip,
} from "reactstrap";
import { formato } from "../../utils";
import { getTermos, saveProspectClient } from "../../api/index";
import { useDispatch, useSelector } from "react-redux";
import { validaCpfCnpj } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import { useSolar } from "../../hooks";
import { toast } from "react-toastify";
import { Coin } from "phosphor-react";
import { emitirPedido, editarPedido, removePromotionsAndCoupons } from "../../store/actions";
import { useColors } from "../../hooks/useColors";

const Resumo = ({
  valTot,
  frete,
  saldo,
  pedido,
  changePedidoEmitido,
  valRt,
  changeType,
  formVisible,
  token,
  setLoadingProspect,
  percent,
  fullDiscount = 0,
  arrayShippingFilialSelect,
  newAddItemsSeparadoPorFilial,
  subTotalWithoutDiscount
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  
  const { emissaoPedido, order, vendedor, loadingPromotions } = useSelector(state => ({
    order: state.Order.orderActive.order,
    emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
    loadingPromotions: state.Order.newOrder.loadingPromotions,
    vendedor: state.Order.newOrder.newOrder.vendedor,
  }));

  const [aceiteTerm, setAceiteTerm] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [modalTerm, setModalTerm] = useState(false);
  const [modalPedido, setModalPedido] = useState({ open: false, name: "" });
  const [dataSaveProspectClient, setDataSaveProspectClient] = useState(null);

  var shipping = frete?.priceSomado ? frete?.priceSomado : frete.price || 0;

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isSolar = useSolar();
  const { colorDefault } = useColors();

  const [termos, setTermos] = useState(<></>);

  const vendedorComRt = vendedor?.VENDEDOR?.TEMRT == '1';

  const [amountToPayForShipping, setAmountToPayForShipping] = useState(frete ? (Number(shipping).toFixed(2)) - (frete.promotionDiscount ? Number(frete.promotionDiscount).toFixed(2) : 0) : 0);

  const getTermosApi = async company => {
    const response = await getTermos(company);
    setTermos(response);
  };

  useEffect(() => {
    setAmountToPayForShipping(shipping ? (Number(shipping.toFixed(2)) - (frete.promotionDiscount ? Number(frete.promotionDiscount.toFixed(2)) : 0)) : 0);
  }, [frete.price, frete.priceSomado, frete.promotionDiscount]);

  useEffect(() => {
    getTermosApi(isSolar ? "02" : "01");
  }, [isSolar]);

  const validarErros = typeFormIsClient => {
    let erros = [];
    if (typeFormIsClient) {
      if (!validaCpfCnpj(pedido.client.cpfcnpj))
        erros.push("CPF ou CNPJ inválido. Revise e tente novamente!");
    }
    if (pedido.items.length < 1)
      erros.push("Não é possível emitir pedido com carrinho vazio.");
    if (!pedido.filters.condpagto)
      erros.push(
        "Condição de pagamento não selecionada! Caso apareça selecionada, tente selecionar novamente"
      );
    if (!pedido.filters.tabela_preco)
      erros.push(
        "Tabela não selecionada! Caso apareça selecionada, tente selecionar novamente"
      );
    if (!pedido.shipping.id) erros.push("Frete não selecionado.");
    if (!pedido.client.email) erros.push("Email não preenchido.");

    if (typeFormIsClient) {
      if (pedido.client.cpfcnpj.length <= 11) {
        if (
          !pedido.client.dt_nasc ||
          pedido.client.dt_nasc === "Data inválida"
        ) {
          erros.push("Data de nascimento inválida");
        }
      }
      if (!pedido.client.customer_phones[1].phone)
        erros.push("Celular não preenchido.");
      if (!pedido.client.customer_address[0].number)
        erros.push("Numero de faturamento não preenchido.");
      if (!pedido.client.customer_address[1].number)
        erros.push("Numero de entrega não preenchido. ");
      if (!pedido.client.customer_phones[0].phone)
        erros.push("Telefone não preenchido.");
    } else {
      if (!pedido.client.customer_phones[0].phone)
        erros.push("Celular não preenchido.");
    }
    if (typeFormIsClient) {
      if (!(pedido.client.fullname && pedido.client.name_reduced))
        erros.push("Nome e nome reduzido não preenchido.");
    } else {
      if (!pedido.client.fullname) erros.push("Nome  não preenchido.");
    }
    if (typeFormIsClient) {
      if (pedido.client.pessoa == "F" && !pedido.client.dt_nasc)
        erros.push("Data de Nascimento não preenchida.");
    }
    if (
      !(
        pedido.client.customer_address[0].zipcode &&
        pedido.client.customer_address[0].address &&
        pedido.client.customer_address[0].number
      )
    )
      erros.push("Endereço de cobrança não preenchido.");
    if (typeFormIsClient) {
      if (
        !(
          pedido.client.customer_address[1].zipcode &&
          pedido.client.customer_address[1].address &&
          pedido.client.customer_address[1].number
        )
      )
        erros.push("Endereço de entrega não preenchido.");
    }

    const thereAreProductsFromDifferentBranches = newAddItemsSeparadoPorFilial.length > 1;

    if (thereAreProductsFromDifferentBranches && fullDiscount > 0) {
      erros.push("Para usar as promoções não é permitido ter produtos de diferentes CDs");
    }

    return erros;
  };

  const openModal = type => {
    let erros = validarErros(formVisible.client);

    if (erros.length > 0) {
      erros.map(value => {
        toast.warning(value, { theme: "colored" });
      });
      return false;
    }
    if (type === "order") {
      token
        ? history.push("/solar/ordenar-por-consumo")
        : setModalPedido({ open: true, name: "order" });
    } else {
      setModalPedido({ open: true, name: "budget" });
    }
  };

  function generateRandomNumber() {
    const characters = "0123456789abcdefghijklmnopqrstuvwxyz";
    let result = "";
    let charSet = new Set();

    while (result.length < 10) {
      const randomChar =
        characters[Math.floor(Math.random() * characters.length)];

      if (!charSet.has(randomChar)) {
        result += randomChar;
        charSet.add(randomChar);
      }
    }

    return result;
  }

  var hash = generateRandomNumber();

  //pega o payload para enviar para as requisições que seram executadas com as diferentes filiais dos produtos do carrinho
  var NewProduto = [];
  const getRequestDuplicate = (obj, obj2) => {
    NewProduto.push(newAddItemsSeparadoPorFilial[obj].data[obj2]);

    return (pedido = {
      ...pedido,
      CODFILIAL: newAddItemsSeparadoPorFilial[obj].CODFILIAL,
      items: NewProduto,
      client: {
        ...pedido.client,
        pedcli: hash,
      },
      shipping: {
        id: arrayShippingFilialSelect[obj].id,
        price: arrayShippingFilialSelect[obj].price,
        shipping_selected: arrayShippingFilialSelect[obj],
      },
    });
  };
  //função para emitir o orcamento/pedido varias vezes de acordo com as diferentes filiais dos produtos do carrinho
  const emitirPedidoDuplicate = pedidoRequest => {
    for (var obj in newAddItemsSeparadoPorFilial) {
      for (var obj2 in newAddItemsSeparadoPorFilial[obj].data) {
        var pedidoDuplicate = getRequestDuplicate(obj, obj2);
      }

      var obj = pedidoRequest
        ? {
          ...pedidoDuplicate,
          ...pedidoRequest,
          countRequest: newAddItemsSeparadoPorFilial?.length || 0,
        }
        : {
          ...pedidoDuplicate,
          countRequest: newAddItemsSeparadoPorFilial?.length || 0,
        };
      dispatch(emitirPedido(obj));
      NewProduto = [];
    }
  };
  //emite o orcamento/pedido somente uma vez
  const emitirPedidoNormal = pedidoRequest => {
    dispatch(emitirPedido(pedidoRequest));
  };

  const emitirNovoPedido = async type => {
    var objDataProspect = {};
    var dataSave = null;
    setLoadingProspect(true);

    if (!formVisible?.client) {
      dispatch(removePromotionsAndCoupons());
    }

    if (aceiteTerm) {
      if (id) {
        dispatch(
          editarPedido({
            id: order.NumPedido,
            pedido,
          })
        );
      } else {
        if (type == "order") {
          changeType("order");
          //valida se existem mais de uma filial diferente dos produtos que estão do carrinho
          if (newAddItemsSeparadoPorFilial.length > 1) {
            emitirPedidoDuplicate();
          } else {
            const obj = { ...pedido, CODFILIAL: newAddItemsSeparadoPorFilial[0].CODFILIAL };
            emitirPedidoNormal(obj);
          }
        }
        if (type == "budget") {
          if (formVisible?.prospect || formVisible?.partner) {
            objDataProspect = {
              CODEMPRESA: "01",
              CODFILIAL: "02",
              nome: pedido?.client?.fullname,
              email: pedido?.client?.email,
              ddd: pedido?.client?.customer_phones[0]?.ddd,
              telefone: pedido?.client?.customer_phones[0]?.phone,
              cep: pedido?.client?.customer_address[0]?.zipcode,
              endereco: pedido?.client?.customer_address[0]?.address,
              endereco_numero: pedido?.client?.customer_address[0]?.number,
              bairro: pedido?.client?.customer_address[0]?.district,
              cidade: pedido?.client?.customer_address[0]?.city,
              uf: pedido?.client?.customer_address[0]?.state,
              cpfcnpj: formVisible?.partner ? pedido?.client?.cpfcnpj : '',
              complemento: pedido?.client?.customer_address[0]?.complement,
            };
            try {
              dataSave = await saveProspectClient(objDataProspect);
              if (!dataSave?.prospect) {
                setLoadingProspect(false);
                return toast.error(
                  `Ocorreu um erro ao cadastrar prospect. ${JSON.stringify(
                    dataSave
                  )}`
                );
              }
            } catch (err) {
              setLoadingProspect(false);
              return toast.error(
                `Algo deu errado ao cadastrar prospect. ${err}`
              );
            }

            setDataSaveProspectClient(dataSave);
            setLoadingProspect(false);
          } 
          else {
            changeType("budget");
            //valida se existem mais de uma filial diferente dos produtos que estão do carrinho
            if (newAddItemsSeparadoPorFilial.length > 1) {
              emitirPedidoDuplicate({
                type_order: "budget",
                lead_id: token,
                budget_infos: {
                  codigo_cliente: pedido.client.id,
                  tipo_cliente: 0,
                },
              });
            } else {
              emitirPedidoNormal({
                ...pedido,
                type_order: "budget",
                // CODFILIAL: pedido?.items[0]?.CODFILIAL,
                CODFILIAL: newAddItemsSeparadoPorFilial[0].CODFILIAL,
                lead_id: token,
                budget_infos: {
                  codigo_cliente: pedido.client.id,
                  tipo_cliente: 0,
                },
              });
            }
          }
        }
      }
    } else {
      toast.warning(
        `É preciso aceitar os termos de fornecimento para emitir pedidos.`,
        { theme: "colored" }
      );
    }
    setModalPedido({ open: !modalPedido, name: "" });
    setLoadingProspect(false);
  };

  useEffect(() => {
    if (dataSaveProspectClient) {
      changeType("budget");
      //valida se existem mais de uma filial diferente dos produtos que estão do carrinho
      if (newAddItemsSeparadoPorFilial.length > 1) {
        emitirPedidoDuplicate({
          type_order: "budget",
          lead_id: token,
          budget_infos: {
            codigo_cliente:
              dataSaveProspectClient?.message ==
                "Já existe um prospect com o email informado"
                ? dataSaveProspectClient.data.prospect_id
                : dataSaveProspectClient.prospect,

            tipo_cliente: "prospect",
          },
        });
      } else {
        emitirPedidoNormal({
          ...pedido,
          type_order: "budget",
          CODFILIAL: newAddItemsSeparadoPorFilial[0].CODFILIAL,
          lead_id: token,
          budget_infos: {
            codigo_cliente:
              dataSaveProspectClient?.message ==
                "Já existe um prospect com o email informado"
                ? dataSaveProspectClient.data.prospect_id
                : dataSaveProspectClient.prospect,

            tipo_cliente: "prospect",
          },
        });
      }
    }
  }, [dataSaveProspectClient]);

  useEffect(() => {
    if (!emissaoPedido.loading && emissaoPedido.data && fetch) {
      if (emissaoPedido.data?.success) {
        changePedidoEmitido();
      }
      setFetch(false);
    }
  }, [emissaoPedido, fetch]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {loadingPromotions ? (
            <div className="text-center">
              <Spinner color="primary" />
              <p className="m-0">Buscando promoções e descontos...</p>
            </div>
          ) : (
            <div>
              <h4
                style={{
                  fontSize: 23,
                  fontWeight: "bold",
                  color: "#202833",
                  marginBottom: 25,
                }}
              >
                Resumo
              </h4>
              <Table className="mt-0 tabela-resumo mb-0" borderless>
                <tbody>
                  <tr>
                    <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                      Valor dos Produtos
                    </td>
                    <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                      {Number(subTotalWithoutDiscount).toLocaleString("pt-br", formato)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                      Frete
                    </td>
                    <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                      {frete?.price ? (
                        amountToPayForShipping > 0 ? (
                          Number(amountToPayForShipping).toLocaleString("pt-br", formato)
                        ) : (
                          <span style={{color: colorDefault}}>
                            FRETE GRÁTIS
                          </span>
                        )
                      ) : (
                        Number(0).toLocaleString("pt-br", formato)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                      Desconto Aplicado
                    </td>
                    <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                      {"- " + (fullDiscount || 0).toLocaleString("pt-br", formato)}
                    </td>
                  </tr>
                  <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                    <td style={{ padding: "0 0 17px 0" }} colSpan={2}>
                      Saldo em conta utilizado
                    </td>
                    <td style={{ padding: "0 0 17px 0", textAlign: "end" }}>
                      {(saldo || 0).toLocaleString("pt-br", formato)}
                    </td>
                  </tr>
                  {vendedorComRt && (
                    <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "17px 0 17px 0",
                        }}
                      >
                        Cashback Wattpay
                        <Coin style={{ marginLeft: 10 }} size={15} weight="regular" />
                      </td>
                      { }
                      <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                        {parseFloat(percent || 0).toFixed(2)} %
                      </td>
                      <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                        {(valRt || 0).toLocaleString("pt-br", formato)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ padding: "20px 0 35px 0" }} colSpan={2}>
                      <h4
                        className="mb-0"
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          color: "#202833",
                        }}
                      >
                        Valor Total
                      </h4>
                    </td>
                    <td style={{ padding: "20px 0 35px 0" }}>
                      <h4
                        className="mb-0"
                        style={{
                          fontSize: 23,
                          fontWeight: "bold",
                          color: "#202833",
                          marginBottom: 25,
                          textAlign: "end",
                        }}
                      >
                        {parseFloat(
                          parseFloat(valTot) +
                          parseFloat(amountToPayForShipping) -
                          parseFloat(saldo)
                        ).toLocaleString("pt-br", formato)}
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <FormGroup check className="d-flex">
                <Input
                  type="checkbox"
                  value="A"
                  className="modalidade aceite"
                  onClick={e => setAceiteTerm(e.target.checked)}
                />
                <Label
                  className={aceiteTerm ? "label-prazo-active" : ""}
                  style={{ marginLeft: 20, fontWeight: 500 }}
                >
                  Li e concordo com os{" "}
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setModalTerm(!modalTerm);
                    }}
                  >
                    Termos e condições <br />
                    de emissão de pedidos e orçamentos
                  </a>
                </Label>
              </FormGroup>
              {emissaoPedido.loading && (
                <div className="carregando-emissao">
                  <Spinner />
                </div>
              )}

              <Button
                style={{
                  backgroundColor: "#2D567B",
                  color: "#FFFFFF",
                  width: "100%",
                  marginTop: 35,
                  display: "block",
                  fontSize: 17,
                  border: "none",
                  padding: 20,
                  borderRadius: 6,
                }}
                onClick={() => {
                  openModal("budget");
                }}
              >
                {id && order ? "EDITAR ORÇAMENTO" : "EMITIR ORÇAMENTO"}
              </Button>

              {!token && (
                <>
                  <Button
                    style={{
                      backgroundColor: "#7EACC4",
                      color: "#FFF",
                      width: "100%",
                      marginTop: 30,
                      display: "block",
                      fontSize: 17,
                      border: "none",
                      padding: 20,
                      borderRadius: 6,
                      opacity: formVisible.client ? 1 : 0.7,
                      cursor: formVisible.client ? "pointer" : "not-allowed",
                    }}
                    id="emitir-pedido"
                    /*  disabled={!formVisible.client} */
                    onClick={() => {
                      if (formVisible.client) {
                        openModal("order");
                      }
                    }}
                  >
                    {token
                      ? "VOLTAR PARA CALCULADORA"
                      : id && order
                        ? "EDITAR PEDIDO"
                        : "EMITIR PEDIDO"}
                  </Button>
                  {!formVisible.client ? (
                    <Tooltip
                      target={"emitir-pedido"}
                      isOpen={isTooltipOpen}
                      placement="bottom"
                      toggle={() => setIsTooltipOpen(prev => !prev)}
                      style={{ backgroundColor: "#005696" }}
                    >
                      Para emitir pedido é necessario utilizar a opção
                      &#34;Cliente&#34;
                    </Tooltip>
                  ) : null}
                </>
              )}
            </div>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={modalPedido.open}
        size="sm"
        toggle={() => setModalPedido({ open: !modalPedido, name: "" })}
      >
        <ModalHeader
          toggle={() => setModalPedido({ open: !modalPedido, name: "" })}
          className="w-100 justify-content-center"
        >
          Deseja emitir o{" "}
          {modalPedido.name == "budget" ? "orçamento?" : "pedido?"}
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center gap-5">
          <Button
            style={{
              backgroundColor: "#004476",
              color: "white",
            }}
            onClick={() => {
              emitirNovoPedido(
                modalPedido.name == "budget" ? "budget" : "order"
              );
              setFetch(true);
            }}
          >
            Confirmar
          </Button>
          <Button
            style={{ backgroundColor: "#E0E0E0", color: "#787878" }}
            onClick={() => setModalPedido({ open: !modalPedido, name: "" })}
          >
            Cancelar
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalTerm}
        size="lg"
        toggle={() => setModalTerm(!modalTerm)}
      >
        <ModalHeader toggle={() => setModalTerm(!modalTerm)}>
          Termos e condições de emissão de pedidos e orçamentos
        </ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: termos }}></ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Resumo;
