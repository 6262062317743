import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Alert,
  Form,
  FormGroup,
  Label,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import "./register-profile.scss";
import PersonalInformation from "../personal-information/PersonalInformation";
import ClubCustomization from "../club-customization/ClubCustomization";
import ImageBgDefault from "../../../assets/images/image-bg-default.png";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import getCroppedImg from "../../../components/crop/cropImage";
import { styles } from "../../../components/crop/styles";
import Moment from "react-moment";
import { apiClient } from "../../../api/apiCore";
import { apiClient2 } from "../../../api/apiCore2";
import { dataURLtoFile } from "../../../utils";
import firebase from "firebase/compat/app";
import { getProfileDetailsList } from "../../../store/auth/profile/actions";
import Budget from "./_components/Budget";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const RegisterProfile = () => {
  const [previewRegisterProfile, setPreviewRegisterProfile] = useState();
  const [image, setImage] = useState();
  // const { userProfile } = useProfile();
  const { user, devices } = useSelector(state => state.Profile.profileDetails);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewRegisterProfile(reader.result);
      };
      reader.readAsDataURL(image);
      // saveImage()
    } else setPreviewRegisterProfile(null);
  }, [image]);

  // useEffect(() => {
  //   if(user?.user_info?.img_profile) {
  //     const reader = new FileReader()
  //
  //     setPreviewRegisterProfile(user?.user_info?.img_profile)
  //   }
  // }, [user?.user_info?.img_profile])

  const handleValidSubmit = () => {
    savePassWord();
  };

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState(null);
  const [seePass, setSeePass] = useState(true);
  const [seeConfirmPass, setSeeConfirmPass] = useState(true);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [webChat] = useState();
  const dispatch = useDispatch();

  const [showLoading, setShowLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (previewRegisterProfile) {
      try {
        const croppedImage = await getCroppedImg(
          previewRegisterProfile,
          croppedAreaPixels,
          rotation
        );
        setCroppedImage(croppedImage);
        var file = dataURLtoFile(croppedImage, "croppedImage.jpeg");
        saveImage("userImgProfile", file);
      } catch (e) {
        console.error(e);
      }
    } else
      toast.error(`Faça o upload de uma imagem para prosseguir`, {
        theme: "colored",
      });
  }, [croppedAreaPixels, rotation]);

  const updatePhotoUrlInFirebase = () => {
    const user = firebase.auth().currentUser;
    user
      .updateProfile({
        photoURL: user?.user_info?.img_profile,
      })
      .then(() => {
        toast.success(`Imagem de perfil alterada com sucesso.`, {
          theme: "colored",
        });
      })
      .catch(error => {
        toast.error(`Um erro ocorreu ao tentar salvar imagem ${error}`, {
          theme: "colored",
        });
      });
  };

  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1");
  const toggleVerticalIcon = tab => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };

  const saveImage = (imageEndPoint, croppedImage) => {
    var formData = new FormData();
    formData.append("file", croppedImage);
    setShowLoading(true);
    apiClient2
      .create(`/users/${imageEndPoint}`, formData)
      .then(() => {
        setShowLoading(false);
        updatePhotoUrlInFirebase();
        dispatch(getProfileDetailsList());
      })
      .catch(() => {
        setShowLoading(false);
      });
  };

  const savePassWord = async () => {
    setShowLoading(true);

    const baseUrl = `/api/user/changePassword`;
    const params = {
      'password': password,
      'password_confirmation': confirmPassword
    };

    await apiClient.create(`${baseUrl}`, params).then(() => {
      toast.success("Senha alterada com sucesso", { theme: "colored" });
    }).catch(err => {
      toast.error(err, { theme: "colored" });
    });

    setShowLoading(false);
  };

  const deleteDevice = async id => {
    await axios.get(
      `${process.env.REACT_APP_CURRENT_PRODUCTION}/users/devices/${id}`
    );
  };

  return (
    <React.Fragment>
      <Col lg={12}>
        <Row id="row-crop">
          <Col md="3">
            <Nav
              className="flex-column vertical-icon position-sticky"
              style={{ top: 0 }}
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTabWithIcon === "1",
                  })}
                  onClick={() => {
                    toggleVerticalIcon("1");
                  }}
                >
                  <i className="fas fa-cog"></i> Informações Pessoais
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTabWithIcon === "2",
                  })}
                  onClick={() => {
                    toggleVerticalIcon("2");
                  }}
                >
                  <i className="ph-check"></i> Meu clube
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTabWithIcon === "3",
                  })}
                  onClick={() => {
                    toggleVerticalIcon("3");
                  }}
                >
                  <i className="ph-image"></i>Alterar Avatar
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTabWithIcon === "4",
                  })}
                  onClick={() => {
                    toggleVerticalIcon("4");
                  }}
                >
                  <i className="ph-lock-simple"></i> Alterar Senha
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTabWithIcon === "5",
                  })}
                  onClick={() => {
                    toggleVerticalIcon("5");
                  }}>
                  <i className="ph-check"></i> Web chat
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalActiveTabWithIcon === "5",
                  })}
                  onClick={() => {
                    toggleVerticalIcon("5");
                  }}
                >
                  <i className="ph-device-mobile"></i> Orçamento
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: verticalActiveTabWithIcon === "6",
                  })}
                  onClick={() => {
                    toggleVerticalIcon("6");
                  }}
                >
                  <i className="ph-device-mobile"></i> Meus Dispositivos
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: verticalActiveTabWithIcon === "7",
                  })}>
                  <Link to="/gcloud/print" className="menu-meu-perfil">
                    <i className="ph-printer"></i> Minha Impressora
                  </Link>
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: verticalActiveTabWithIcon === "8",
                  })}
                  onClick={() => {
                    toggleVerticalIcon("6");
                  }}>
                  <Link to="/2fa/enable" className="menu-meu-perfil">
                    <i className="ph-key"></i> Autenticação em 2 Passos
                  </Link>
                </NavLink>
              </NavItem> */}
            </Nav>
          </Col>
          <Col id="col-crop" className="position-relative" md="9">
            <TabContent
              activeTab={verticalActiveTabWithIcon}
              className="text-muted mt-4 mt-md-0"
            >
              <TabPane tabId="1">
                <PersonalInformation />
              </TabPane>
              <TabPane tabId="2">
                <ClubCustomization />
              </TabPane>
              <TabPane tabId="3">
                <Row style={{ height: "250px" }}>
                  <Col md={6}>
                    <div>
                      <div
                        className={styles.cropContainer}
                        style={{ minHeight: "250px" }}
                      >
                        <Cropper
                          image={previewRegisterProfile || ImageBgDefault}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={4 / 3}
                          onCropChange={setCrop}
                          onRotationChange={setRotation}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>

                      <div
                        id="cropSliders"
                        className="d-flex justify-content-between"
                        style={{
                          width: `${
                            document.getElementById("col-crop")?.offsetWidth
                          }px`,
                          maxWidth: "390px",
                        }}
                      >
                        <div
                          className={styles.sliderContainer}
                          style={{ width: "100px" }}
                        >
                          <Typography
                            variant="overline"
                            classes={{ root: styles.sliderLabel }}
                          >
                            Zoom
                          </Typography>
                          <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            classes={{ root: styles.slider }}
                            onChange={(e, zoom) => setZoom(zoom)}
                          />
                        </div>
                        <div
                          className={styles.sliderContainer}
                          style={{ width: "100px" }}
                        >
                          <Typography
                            variant="overline"
                            classes={{ root: styles.sliderLabel }}
                          >
                            Rotacionar
                          </Typography>
                          <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="Rotation"
                            classes={{ root: styles.slider }}
                            onChange={(e, rotation) => setRotation(rotation)}
                          />
                        </div>
                        <label
                          htmlFor="input-file-register-profile"
                          className="btn-default d-flex j-c-center light-btn cursor-pointer hover-img-upload btn btn-success mt-2"
                        >
                          Imagem
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className="h-100 position-relative">
                    <Form>
                      {/* <i className="fas fa-eye"></i> */}
                      <FormGroup>
                        <input
                          type="file"
                          id="input-file-avatar"
                          name="input-file-avatar"
                          hidden=""
                          className="d-none"
                          accept="image/*"
                          onChange={e => {
                            const file = e.target.files[0];
                            if (file && file.type.substr(0, 5) === "image")
                              setImage(file);
                          }}
                        ></input>
                        <input
                          type="file"
                          id="input-file-register-profile"
                          name="input-file-register-profile"
                          hidden=""
                          className="d-none"
                          accept="image/*"
                          onChange={e => {
                            const file = e.target.files[0];
                            if (file && file.type.substr(0, 5) === "image")
                              setImage(file);
                          }}
                        ></input>
                        <div>
                          <h3>Personalização Perfil</h3>
                          <p>
                            Clique ao lado para alterar sua foto de perfil.
                            Lorem Ipsim Dolor.....
                          </p>
                        </div>
                        <div
                          style={{ right: 0, bottom: 0 }}
                          className="d-flex justify-content-end position-absolute"
                        >
                          <Button
                            onClick={showCroppedImage}
                            variant="contained"
                            disabled={showLoading}
                            color="success"
                            className="btn-default d-flex j-c-center cursor-pointer hover-img-upload"
                            style={{ minWidth: 135 }}
                          >
                            {showLoading ? <Spinner /> : "Salvar"}
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col md={6} className="mx-auto">
                    <AvForm
                      id="form-senhas"
                      className="form-horizontal"
                      onValidSubmit={() => {
                        handleValidSubmit();
                      }}
                    >
                      <FormGroup>
                        <Label for="Password">Nova senha</Label>
                        <div className="d-flex w-100 position-relative i-father mb-3">
                          <AvField
                            id="Password"
                            name="Password"
                            placeholder="Senha"
                            type={seePass ? "password" : "text"}
                            value={password}
                            onChange={e => {
                              setPassword(e.target.value);
                            }}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Este campo é obrigatório",
                              },
                              minLength: {
                                value: 8,
                                errorMessage:
                                  "Sua senha deve possuir no mínimo 8 digitos",
                              },
                              maxLength: {
                                value: 16,
                                errorMessage:
                                  "Sua senha deve possuir no mâximo 16 digitos",
                              },
                            }}
                          />
                          <i
                            onClick={() => {
                              setSeePass(!seePass);
                            }}
                            className={`position-absolute cursor-pointer 
                              ${seePass ? "fa fa-eye" : "fa fa-eye-slash"}`}
                          ></i>
                        </div>
                        <Label for="newPassword" className="mt-3">
                          Repetir nova senha
                        </Label>
                        <div className="d-flex w-100 position-relative i-father">
                          <AvField
                            id="newPassword"
                            name="newPassword"
                            placeholder="Repetir Senha"
                            type={seeConfirmPass ? "password" : "text"}
                            value={confirmPassword}
                            onChange={e => {
                              setConfirmPassword(e.target.value);
                            }}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Este campo é obrigatório",
                              },
                              match: {
                                value: "Password",
                                errorMessage: "As senhas devem coincidir",
                              },
                            }}
                          />
                          <i
                            onClick={() => {
                              setSeeConfirmPass(!seeConfirmPass);
                            }}
                            className={`position-absolute cursor-pointer 
                                ${
                                  seeConfirmPass
                                    ? "fa fa-eye"
                                    : "fa fa-eye-slash"
                                }`}
                          ></i>
                        </div>
                      </FormGroup>
                      <Button
                        type="submit"
                        disabled={showLoading}
                        className="btn-default mt-3 d-flex j-c-center"
                        style={{ minWidth: 135 }}
                      >
                        {showLoading ? (
                          <Spinner />
                        ) : (
                          <p className="mb-0">Alterar senha</p>
                        )}
                      </Button>
                    </AvForm>
                  </Col>
                </Row>
              </TabPane>
              {/* <TabPane tabId="5">
                <p className="web-chat-texto">Deixar o Web chat ativo?
                  <Input type="checkbox" className="input-checkbox" value={webChat} 
                    onChange={(e) => {setWebChat(e?.target?.checked)}}
                    />
                </p>
                <Button onClick={salvarWebChatStatus}className="salvar-checkbox">Salvar</Button>
              </TabPane> */}

              <TabPane tabId="5">
                <Budget />
              </TabPane>
              <TabPane tabId="6">
                {devices?.length != 0 ? (
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Último uso </th>
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devices &&
                        devices.map((data, key) => (
                          <tr key={key} className="position-relative">
                            <td className="d-flex justify-content-center align-items-center">
                              <span>{key}</span>
                            </td>
                            <td>{data.user_agent}</td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {data.last_activity.split(" ")[0]}
                              </Moment>
                              <br />
                              {data.last_activity.split(" ")[1]}
                            </td>
                            <td>
                              <Button
                                className="btn btn-delete"
                                onClick={deleteDevice(data.id)}
                              >
                                <i className="ph-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    <Alert color="danger">Nenhum dispositivo cadastrado</Alert>
                  </div>
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};
export default RegisterProfile;

// https://clube.adias.com.br/users/userImgProfile
// POST
