import React from "react";
import { formato } from "../../utils";
import { Col, Row, Card, CardBody } from "reactstrap";

import { Link } from "react-router-dom";

import "./orders.css";
import { useSolar } from "../../hooks";
import { getOrdersList } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import iconenf from "../../assets/images/vendedores/NF.png";
import { FilePdf } from "phosphor-react";
import { getNotaFiscalDownload } from "../../api/order";
import { toast } from "react-toastify";
import { downloadBlob } from "../../helpers/download_helper";

const OrderItem = ({
  order,
  setIdType,
  setClienteId,
  parceiros,
  setParceiros,
  setNumPedido,
}) => {
  const isSolar = useSolar();
  const dispatch = useDispatch();
  const { filters } = useSelector(state => ({
    filters: state.OrderList.filters,
  }));
  const filter = filters.filters;
  const colors = {
    A: "#EBEBEB",
    B: "#BEA68E",
    C: "#F8EE72",
    D: "#717171",
    E: "#ADADAD",
    F: "#97E655",
    G: "#FC63F1",
    H: "#71A3D6",
    I: "#DB0000",
    J: "#DB0000",
    K: "#ff7f00",
    L: "#ff7f00",
    S: "#000",
  };

  const getTypeSaleByCtpoper = ctpoper => {
    const directSaleCtpoper = ['01', '51'];
    const futureShippingCtpoper = ['03', '53'];

    return futureShippingCtpoper.includes(ctpoper) ? "R" : directSaleCtpoper.includes(ctpoper) ? "V" : "F";
  }

  const setClientFilter = name => {
    setIdType(1);
    setClienteId(name.toUpperCase());
    (filter.cli_cpfcnpj = ""), (filter.nome_cliente = "");
    filter.nota_fisca = "";
    filter.nome_cliente = name.toUpperCase();
    setNumPedido("");
    filter.num_pedido = "";
    filters.filters = filter;
    dispatch(getOrdersList(filters));
  };

  const setVendOrParceiroFilter = ({ vend, name, cod }) => {
    console.log(vend, name, cod);
    setParceiros([{ codigo: cod, label: name }]);
    filter.vend_codigo = cod;
    setNumPedido("");
    filter.num_pedido = "";
    dispatch(getOrdersList(filters));
  };

  const getLine = () => {
    if (order?.Situacao == "S" && isSolar) {
      return 'Em produção';
    }

    return order.queue.trim().length != 0 ? order.queue : "-";
  };

  const handleClickNfDownload = async (orderId, cpfCnpj, downloadType) => {
    try {
      const response = await getNotaFiscalDownload({ orderId, cpfCnpj, downloadType });
      downloadBlob(response, `NotaFiscal-${orderId}.${downloadType}`, downloadType);
    } catch (error) {
      toast.error("Erro ao baixar a Nota Fiscal.");
    }
  };

  return (
    <React.Fragment>
      {" "}
      <Row className="mt-2">
        <Col>
          <Card
            style={{
              borderLeftColor: colors[order.Situacao],
              borderLeftWidth: 10,
            }}
          >
            <CardBody
              style={{
                display: "grid",
                gridTemplateColumns: isSolar
                  ? "80px 80px 200px 120px 150px 70px 70px 70px 130px 0.3fr"
                  : "80px 80px 200px 120px 150px 70px 70px 130px 0.3fr",
              }} /* className="d-flex flex-column flex-md-row justify-content-between card-list" */
            >
              <p
                id="dataCad"
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {order.Data.trim().length != 0 ? order.Data : "-"}
              </p>
              <p
                id="numPedido"
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  to={
                    isSolar
                      ? `/solar/pedidos/${("000000" + order.NumPedido).slice(
                          -6
                        )}/${order.CodFilial}/order`
                      : `/pedidos/${("000000" + order.NumPedido).slice(-6)}/${
                          order.CodFilial
                        }/order`
                  }
                >
                  {String(order.NumPedido).trim().length != 0
                    ? order.CodFilial +
                      "-" +
                      ("000000" + `${order.NumPedido}`).slice(-6)
                    : "-"}
                </Link>
              </p>
              <p
                id="nomCli"
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 200,
                }}
                className="order-cliente"
                onClick={() => setClientFilter(order.NomeCliente)}
              >
                <Link>
                  {order.NomeCliente.trim().length != 0
                    ? order.NomeCliente
                    : "-"}
                </Link>
              </p>
              <p
                id="valTot"
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {order.total.trim().length != 0
                  ? Number(order.total).toLocaleString("pt-BR", formato)
                  : "-"}
              </p>
              <p
                id="filaSit"
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {getLine()}
              </p>

              <p
                id="vend1"
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() =>
                  setVendOrParceiroFilter({
                    vend: "vend1",
                    name: order.Vend1,
                    cod: order.Vendedor1,
                  })
                }
              >
                <Link>
                  {" "}
                  {order.Vendedor1.trim().length != 0 ? order.Vendedor1 : "-"}
                </Link>
              </p>
              <p
                id="vend2"
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() =>
                  setVendOrParceiroFilter({
                    vend: "vend2",
                    name: order.Vend2,
                    cod: order.Vendedor2,
                  })
                }
              >
                <Link>
                  {order.Vendedor2.trim().length != 0 ? order.Vendedor2 : "-"}
                </Link>
              </p>
              {isSolar && (
                <p
                  id="vend2"
                  style={{
                    textAlign: "center",
                    marginBottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link>{getTypeSaleByCtpoper(order.CTPOER)}</Link>
                </p>
              )}
              <p
                id="notf"
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {order.Nota.trim().length != 0 ? order.Nota : "-"}
                {order.Nota.trim().length != 0 && (
                  <>
                    {" "}
                    <a
                      rel="noreferrer"
                      className="img-dados"
                      onClick={() => handleClickNfDownload(order?.NumPedido, order?.CpfCnpjCliente?.trim(), 'pdf')}
                      style={{ marginLeft: 5 }} 
                    >
                      <FilePdf size={20} color="#30a3ec" />
                    </a>
                    <a
                      onClick={() => handleClickNfDownload(order?.NumPedido, order?.CpfCnpjCliente?.trim(), 'xml')}
                      rel="noreferrer"
                      className="d-block"
                    >
                      <img src={iconenf} className="img-pedidos" />
                    </a>
                  </>
                )}
              </p>
              <Link
                to={
                  isSolar
                    ? `/solar/pedidos/${("000000" + order.NumPedido).slice(
                        -6
                      )}/${order.CodFilial}/order`
                    : `/pedidos/${("000000" + order.NumPedido).slice(-6)}/${
                        order.CodFilial
                      }/order`
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa fa-search fa-md"></i>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OrderItem;
