import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Input,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { useSolar } from "../../../hooks";
import { getDeliveryTerms } from "../../../api";


const RuralOrRiverShipping = ({
  setRuralShippingValue,
  ruralShippingValue,
  riverShippingValue,
  setRiverShippingValue,
  setRuralOrRiverShippingValue,
  ruralOrRiverShippingValue,
}) => {
  const [modalTerm, setModalTerm] = useState(false);
  const [deliveryTerms, setTermos] = useState("");

  const isSolar = useSolar();

  useEffect(() => {
    const getTermosApi = async company => {
      const response = await getDeliveryTerms(company);
      setTermos(response);
    };

    getTermosApi(isSolar ? "02" : "01");
  }, []);

  return (
    <Col>
      <Row className="mt-3">
        <h4
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#202833",
            marginBottom: 15,
          }}
        >
          O transporte será realizado em área rural e/ou fluvial?
        </h4>
        <FormGroup check>
          <Row className="justify-content-start ms-1">
            <Col md={3}>
              <Input
                type={"radio"}
                name={"ruralOrRiverShipping"}
                id={"ruralOrRiverShippingConfirm"}
                className={"modalidade"}
                checked={ruralOrRiverShippingValue}
                onChange={() => setRuralOrRiverShippingValue(true)} 
              />
              <Label
                htmlFor="ruralOrRiverShippingConfirm"
                className={
                  ruralOrRiverShippingValue ? "label-prazo-active" : ""
                }
                style={{ marginLeft: 10, fontWeight: 400 }}
              >
                SIM
              </Label>
            </Col>
            <Col md={3}>
              <Input
                type={"radio"}
                name={"ruralOrRiverShipping"}
                id={"ruralOrRiverShippingDeny"}
                className={"modalidade"}
                checked={!ruralOrRiverShippingValue} 
                onChange={() => setRuralOrRiverShippingValue(false)}
              />
              <Label
                htmlFor="ruralOrRiverShippingDeny"
                className={
                  !ruralOrRiverShippingValue ? "label-prazo-active" : ""
                }
                style={{ marginLeft: 10, fontWeight: 400 }}
              >
                NÃO
              </Label>
            </Col>

            {ruralOrRiverShippingValue && (
              <>
                <Row
                  className="rounded-3"
                  style={{ backgroundColor: "#7979791a" }}
                >
                  <p
                    className="pt-2"
                    style={{ fontWeight: "bold", marginBottom: 5 }}
                  >
                    Selecione as áreas de transporte aplicáveis
                  </p>
                  <Col md={6} className="ms-3" style={{ marginTop: 10 }}>
                    <Input
                      type={"checkbox"}
                      id={"option1"}
                      className={"additional-checkbox"}
                      checked={ruralShippingValue} 
                      onChange={() =>
                        setRuralShippingValue(!ruralShippingValue)
                      } 
                    />
                    <Label htmlFor="option1" style={{ marginLeft: 10 }}>
                      Área Rural
                    </Label>
                    <br />
                    <Input
                      type={"checkbox"}
                      id={"option2"}
                      className={"additional-checkbox"}
                      checked={riverShippingValue} 
                      onChange={() =>
                        setRiverShippingValue(!riverShippingValue)
                      } 
                    />
                    <Label htmlFor="option2" style={{ marginLeft: 10 }}>
                      Fluvial
                    </Label>
                  </Col>
                </Row>
                <FormGroup className="d-flex ps-0 mt-2">
                  <span style={{ fontWeight: 500 }}>
                    Leia as{" "}
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setModalTerm(!modalTerm);
                      }}
                    >
                      Condições de Entrega
                    </a>
                  </span>
                </FormGroup>
              </>
            )}
          </Row>
        </FormGroup>
      </Row>
      <Modal
        isOpen={modalTerm}
        size="lg"
        toggle={() => setModalTerm(!modalTerm)}
      >
        <ModalHeader toggle={() => setModalTerm(!modalTerm)}>Cobrança de Frete Adicional – Áreas Fluviais e Rurais</ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: deliveryTerms }}></ModalBody>
      </Modal>
    </Col>
  );
};

export default RuralOrRiverShipping;
