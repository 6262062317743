import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { formatInvertDate } from "../../utils/dateUtils";

import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { useSolar } from "../../hooks";

import { getRulesList } from "../../store/actions";
import { useCurrentCompany } from "../../hooks";

const RulesFilter = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const company = useCurrentCompany();

  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [ruleId, setRuleId] = useState("");
  const [cupomName, setCupomName] = useState("");
  const [active, setActive] = useState("");
  const [applyToShipping, setApplyToShippng] = useState("");
  const [needCupom, setNeedCupom] = useState("");
  const [filterUsed, setFilterUsed] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const isSolar = useSolar();

  let filterObj = {
    created_at_init: dataInicio,
    created_at_end: dataFim,
    active: active,
    applytoshipping: applyToShipping,
    need_coupom: needCupom,
    id: ruleId,
    company: company,
    rule_name: cupomName,
  };

  function clearFilters() {
    filterObj.id = "";
    filterObj.created_at_init = "";
    filterObj.created_at_end = "";
    filterObj.rule_name = "";
    filterObj.active = "";
    filterObj.applytoshipping = "";
    filterObj.need_coupom = "";
    setRuleId("");
    setDataInicio("");
    setCupomName("");
    setDataFim("");
    setActive("");
    setApplyToShippng("");
    setNeedCupom("");
    setFilterUsed(false);
    dispatch(getRulesList(filterObj));
  }

  function formatDate(date) {
    let data = date;
    let dataFormatada = `${data.getFullYear()}-${(data.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${data.getDate().toString().padStart(2, "0")}`;
    return dataFormatada;
  }

  const getOrders = e => {
    e.preventDefault();

    filterObj.id = ruleId;
    filterObj.created_at_init = dataInicio;
    filterObj.created_at_end = dataFim;
    filterObj.rule_name = cupomName;
    filterObj.active = active;
    filterObj.applytoshipping = applyToShipping;
    filterObj.need_coupom = needCupom;

    if (isOpen) setIsOpen(false);
    dispatch(getRulesList(filterObj));
  };

  const setRemoveFilters = () => {
    if (Object.values(filterObj).every(v => v === "")) {
      setFilterUsed(false);
    } else {
      setFilterUsed(true);
    }
  };

  const handleOnChange = (e, fn, n) => {
    e.preventDefault();
    fn(e.target.value);
    filterObj[n] = e.target.value;
    dispatch(getRulesList(filterObj));
    setRemoveFilters();
  };

  const handleDateOnChange = (data, fn, n) => {
    fn(formatDate(new Date(data)));
    filterObj[n] = formatDate(new Date(data));
    dispatch(getRulesList(filterObj));
    setRemoveFilters();
  };

  return (
    <React.Fragment>
      <Row className="mt-3 d-flex align-items-end">
        <Col md={5}>
          <FormGroup>
            <Label for="select-partner">Nome do Cupom</Label>
            <Input
              placeholder="Digite aqui..."
              value={cupomName}
              onChange={e => setCupomName(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="select-partner">Id</Label>
            <Input
              placeholder="Digite aqui..."
              value={ruleId}
              onChange={e => setRuleId(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <Button
            style={{ backgroundColor: isSolar ? "#FF601A" : "#2D567B", color: "#fff" }}

            onClick={e => getOrders(e)}
            className="px-3 btn"
          >
            PESQUISAR
            <i className="fa fa-search" style={{ marginLeft: 10 }}></i>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col md={2}>
                  <CardTitle tag="h4">Filtrar por:</CardTitle>
                </Col>
                <Col md={7}>
                  <p className="d-flex align-content-start flex-wrap">
                    {dataInicio ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        de {formatInvertDate(dataInicio.split("-").join(""))}{" "}
                        <i
                          className="far fa-times-circle"
                          onClick={() => {
                            setDataInicio("");
                            filterObj.created_at_init = "";
                            setRemoveFilters();
                            dispatch(getRulesList(filterObj));
                          }}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : (
                      <span />
                    )}
                    {dataFim ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        até {formatInvertDate(dataFim.split("-").join(""))}{" "}
                        <i
                          className="far fa-times-circle"
                          onClick={() => {
                            setDataFim("");
                            filterObj.created_at_end = "";
                            setRemoveFilters();
                            dispatch(getRulesList(filterObj));
                          }}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : (
                      <span />
                    )}

                    {needCupom === "1" ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        Necessário Cupom
                        <i
                          className="far fa-times-circle"
                          onClick={() => {
                            setNeedCupom(false);
                            filterObj.need_coupom = "";
                            setRemoveFilters();
                            dispatch(getRulesList(filterObj));
                          }}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : needCupom === "0" ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        Não é necessário Cupom
                        <i
                          className="far fa-times-circle"
                          onClick={() => {
                            setNeedCupom(false);
                            filterObj.need_coupom = "";
                            setRemoveFilters();
                            dispatch(getRulesList(filterObj));
                          }}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : null}
                    {applyToShipping === "1" ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        Aplicavel ao frete
                        <i
                          className="far fa-times-circle"
                          onClick={() => {
                            setApplyToShippng(false);
                            filterObj.applytoshipping = "";
                            setRemoveFilters();
                            dispatch(getRulesList(filterObj));
                          }}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : applyToShipping === "0" ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        Não aplicavel ao frete
                        <i
                          className="far fa-times-circle"
                          onClick={() => {
                            setApplyToShippng(false);
                            filterObj.applytoshipping = "";
                            setRemoveFilters();
                            dispatch(getRulesList(filterObj));
                          }}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : null}
                    {active === "1" ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        Cupom Ativo
                        <i
                          className="far fa-times-circle"
                          onClick={() => {
                            setActive(false);
                            filterObj.active = "";
                            setRemoveFilters();
                            dispatch(getRulesList(filterObj));
                          }}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : active === "0" ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        Cupom Inativo
                        <i
                          className="far fa-times-circle"
                          onClick={() => {
                            setActive(false);
                            filterObj.active = "";
                            setRemoveFilters();
                            dispatch(getRulesList(filterObj));
                          }}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : null}
                  </p>
                </Col>

                <Col md={2}>
                  {filterUsed ? (
                    <div>
                      <Button
                        color="light"
                        onClick={clearFilters}
                        style={{
                          color: "#0067B4",
                          backgroundColor: "#FFF",
                          borderColor: "#F5F5F5",
                          borderWidth: "2px",
                        }}
                      >
                        Limpar Filtros{" "}
                        <i
                          className="fa fa-times fa-md"
                          style={{ marginLeft: 5 }}
                        ></i>
                      </Button>
                    </div>
                  ) : null}
                </Col>
                <Col md={1}>
                  <Button color="transparent" onClick={toggle}>
                    {isOpen ? (
                      <i className="fa fa-chevron-up fa-lg"></i>
                    ) : (
                      <i className="fa fa-chevron-down fa-lg"></i>
                    )}
                  </Button>
                </Col>
              </Row>
              <Collapse isOpen={isOpen}>
                <hr />
                <Row className="d-flex justify-content-center">
                  <Col md={8} style={{ width: "100%" }}>
                    <Row className="d-flex justify-content-center align-items-end mb-3">
                      <Col md={4}>
                        <FormGroup>
                          <Label>Data de Inclusão:</Label>
                          <Flatpickr
                            className="form-control selectData"
                            placeholder="&#xf133; dd/mm/aaaa"
                            options={{
                              altInput: true,
                              locale: Portuguese,
                              altFormat: "d/m/Y",
                              dateFormat: "Ymd",
                            }}
                            value={dataInicio.split("-").join("")}
                            onChange={([date]) =>
                              handleDateOnChange([date], setDataInicio, "created_at_init")
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col md="auto" className="text-center">
                        <Label style={{ marginTop: "30px", color: "#BDBDBD" }}>Até</Label>
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          <Label>Data Fim:</Label>
                          <Flatpickr
                            className="form-control selectData"
                            placeholder="&#xf133; dd/mm/aaaa"
                            options={{
                              altInput: true,
                              locale: Portuguese,
                              altFormat: "d/m/Y",
                              dateFormat: "Ymd",
                            }}
                            value={dataFim.split("-").join("")}
                            onChange={([date]) =>
                              handleDateOnChange([date], setDataFim, "created_at_end")
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="d-flex justify-content-center">
                      <Col md={4}>
                        <FormGroup>
                          <Label>Necessita Cupom</Label>
                          <select
                            className="form-select"
                            value={needCupom}
                            onChange={e => handleOnChange(e, setNeedCupom, "need_coupom")}
                          >
                            <option value="">-</option>
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          <Label>Aplicar ao Frete</Label>
                          <select
                            className="form-select"
                            value={applyToShipping}
                            onChange={e =>
                              handleOnChange(e, setApplyToShippng, "applytoshipping")
                            }
                          >
                            <option value="">-</option>
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          <Label>Ativo</Label>
                          <select
                            className="form-select"
                            value={active}
                            onChange={e => handleOnChange(e, setActive, "active")}
                          >
                            <option value="">-</option>
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RulesFilter;
